import * as yup from 'yup'
import { isWhiteSpace } from 'utils'

export const schema = yup.object().shape({
  upholdOTP: yup
    .string()
    .ensure()
    .when('$otpRequired', (otpRequired, schema) => {
      if (otpRequired) {
        return schema
          .required('Please enter a valid code.')
          .test(
            'WhiteSpace',
            'Please enter a valid code.',
            (val) => !isWhiteSpace(val)
          )
          .test(
            'len',
            'The code should be 6-8 digits.',
            (val) => val?.length >= 6 && val?.length <= 8
          )
      }
    })
})

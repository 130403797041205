import Button from 'components/Global/Button'
import { images } from 'assets'

const ScrollToTopBtn = ({ stickyHeader }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (!stickyHeader) return null
  else {
    return (
      <div className='sticky-button'>
        <Button
          customClass='scroll-to-top'
          onClick={scrollToTop}
          ariaLabel='Scroll to top'
        > <img alt='Scroll to top' src={images.scroll_to_top} /></Button>
      </div>)
  }
}

export default ScrollToTopBtn

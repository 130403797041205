
/**
 * ButtonSelect component for selecting an option from a list of options
 * @function ButtonSelect
 * @returns 
 */
const ButtonSelect = ({ selected, selectItem, children, testID, ariaLabel }) => {
  return (
    <div onClick={selectItem} data-testid={testID} aria-label={ariaLabel} className={`cursor-pointer flex h-12 pl-4 border rounded-lg col ${selected ? 'border-gray5' : 'border-gray2'}`}>
      <div className={`self-center text-start`}>{children}</div>
    </div>
  )
}
export default ButtonSelect
import ErrorModal from 'components/Global/ErrorModal'

const FBOWithdrawCommitErrorModal = ({
  hideModal
}) => {
  const commitError = {
    title: 'Error',
    message: 'An unknown error as occured while trying to transfer to the selected account. If you continue to encounters this error, please select a different account and try again.',
    type: 'COMMIT_ERROR'
  }
  return (
    <ErrorModal error={commitError} crossToClose={true} hideModal={hideModal}/>
  )
}

export default FBOWithdrawCommitErrorModal

import { useSelector } from 'react-redux'
import Button from '../../../Global/Button'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { useFormContext } from 'react-hook-form'

const ConfirmResetForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    formState: { isDirty, isValid }
  } = useFormContext()
  const { submitLoading } = useSelector((state) => state.userSlice)

  return (
    <div className='page-container'>
      <div className='inner-container'>
        <h1>Reset password</h1>
        <form className='grid' onSubmit={handleSubmit(onSubmit)}>
          <div className='column sixteen'>
            <PasswordInput
              name='password'
              label='New Password'
              ariaLabel='new-password-input'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen'>
            <PasswordInput
              name='confirmPassword'
              label='Confirm Password'
              ariaLabel='confirm-password-input'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen'>
            <div className='btn-group start'>
              <Button
                disabled={!isDirty || submitLoading || !isValid}
                loading={submitLoading}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ConfirmResetForm

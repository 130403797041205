import { Fullstory, Mixpanel, formatDecimal } from 'utils'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'components'

const AddFundsLink = ({ selectedAccount }) => {
  const { order: { total } } = useSelector((state) => state.placeOrderSlice)

  const history = useHistory()
  const location = useLocation()
  const cashBalance = selectedAccount?.amountAvailable

  const goToAddFunds = () => {
    Mixpanel.track('Click Add Funds on Buy Order Page')
    Fullstory.track('Link Click', { link: 'Add Funds' })
    // Send the required amount to the add funds page and set buy page path in local storage
    const amount = formatDecimal(total - cashBalance, true, 2, true)
    localStorage?.setItem('from-buy-order-page', location.pathname + location.search)
    history.push(`/cash-account/add-funds?amountRequired=${amount}${selectedAccount?.entityId ? `&entityId=${selectedAccount?.entityId}` : ''}`)
  }
  // Only show error if total exists and greater than cash balance
  if (!total || (total <= cashBalance)) return null
  return (
    <div className='buy-order-section wih-margin'>
      <div className='buy-order-section-detail buy-order-end'>
        <div className='buy-order-row'>
          <span className='red width-70'>{formatDecimal(total - cashBalance)} more required for purchase</span>
          <Button className='link primary' onClick={goToAddFunds} ariaLabel='Add funds to your account'>Add Funds</Button>
        </div>
      </div>
    </div>
  )
}

export default AddFundsLink

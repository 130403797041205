import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice.js'
import api from '../apis/private.js'
const initialState = {
  pageLoading: false,
  totalPurchaseCredits: 0,
  creditPurchaseEntries: [],
  currentCreditEntries: []
}

export const getPurchaseCredits = createAsyncThunk(
  'getPurchaseCredits',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/linqtoWallet/purchaseCredits/')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const purchaseCreditsSlice = createSlice({
  name: 'purchaseCreditsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPurchaseCredits.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getPurchaseCredits.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getPurchaseCredits.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default purchaseCreditsSlice.reducer

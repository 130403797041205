import TextInput from 'components/Global/Inputs/TextInput'
import { useFormContext } from 'react-hook-form'
import ButtonGroup from '../ButtonGroup'

const SecurityLicenseInput = ({
  loading,
  onSubmit,
  accreditedStatus
}) => {
  const {
    handleSubmit
  } = useFormContext()
  return (
    <form className='b_18_regular info-container' onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        name='crdNumber'
        label='Provide your CRD License number below.'
        disabled={loading || accreditedStatus === 'UNDER_REVIEW'}
        className='crdNumber-input'
      />
      <ButtonGroup loading={loading} disabled={loading || accreditedStatus === 'UNDER_REVIEW'} />
    </form>
  )
}

export default SecurityLicenseInput

import { useState, useCallback, useMemo, useEffect } from 'react'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationHeader from './partials/OriginationHeader'
import OriginationCopy from './partials/OriginationCopy'
import OriginationFooter from './partials/OriginationFooter'
import OriginationSearch from './partials/OriginationSearch'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { searchOrigination } from 'slices/originationSlice'

const Origination = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const pageTitle = seoTitleTemplate('Sell')

  const dispatch = useDispatch()
  const history = useHistory()

  const fetchData = useCallback(
    async (e) => {
      if (e.length > 0) {
        return await dispatch(searchOrigination(e))
      }
    },
    []
  )

  useEffect(() => {
    Mixpanel.track('View Originations Page')
  }, [])

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchData(inputValue).then(
          ({ meta, payload }) => {
            if (meta.requestStatus === 'fulfilled') {
              Mixpanel.track('Search Originations Company', { 'Search Term': inputValue })
              callback(payload)
            }
          })
      }, 500),
    [fetchData]
  )

  const onSelectionChange = (item) => {
    if (item) {
      history.push(`/sell/${item.value}`)
    }
  }

  const onInputChange = (val) => {
    if (val.length === 0) {
      setOpenMenu(false)
    } else {
      setOpenMenu(true)
    }
  }

  return (
    <>
      <SeoMeta title={pageTitle} description='' />
      <div className='origination-container'>
        <OriginationHeader />
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationCopy />
            <div className='origination-body'>
              <OriginationSearch
                loadSuggestedOptions={loadSuggestedOptions}
                onSelectionChange={onSelectionChange}
                onInputChange={onInputChange}
                openMenu={openMenu}
              />
            </div>
            <OriginationFooter />
          </div>
        </div>
      </div>
    </>
  )
}

export default Origination

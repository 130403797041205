import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE, Mixpanel } from 'utils'
import { Button } from 'components'

const InvestDetailTabs = ({ activeIndex, setActiveIndex, stickyHeader }) => {
  const { width } = useWindowSize()
  const { details: { name } } = useSelector(state => state.investSlice)

  const updateActiveIndexAndTrack = (num, tab, id) => {
    setActiveIndex(num)
    Mixpanel.track('Click Product Tab', { 'Tab Name': tab, 'Company Name': name })
    if (width <= MOBILE_WIDTH_SIZE) {
      const element = document?.querySelector(id)
      const y = element.getBoundingClientRect().top + window.scrollY - 90
      window.scrollTo({ top: y, behavior: 'smooth' })
    } else {
      document?.querySelector(id).scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className='invest-tabs-container'>
      <div className='invest-tabs-container__page-tabs'>
        <Button ariaLabel='About tab' data-testid='aboutTab' className={`b_16_semibold tab ${activeIndex === 0 ? 'active' : ''}`} onClick={() => updateActiveIndexAndTrack(0, 'About', '#about')}>
          <span>About</span>
        </Button>
        <Button ariaLabel='Summary tab' data-testid='summaryTab' className={`b_16_semibold tab ${activeIndex === 1 ? 'active' : ''}`} onClick={() => updateActiveIndexAndTrack(1, 'Summary', '#summary')}>
          <span>Summary</span>
        </Button>
        <Button ariaLabel='Valuation tab' data-testid='valuationTab' className={`b_16_semibold tab ${activeIndex === 2 ? 'active' : ''}`} onClick={() => updateActiveIndexAndTrack(2, 'Valuation', '#valuation')}>
          <span>Valuation</span>
        </Button>
      </div>
    </div>
  )
}

export default InvestDetailTabs

import Modal from '../../../Global/Modal'
import Loading from '../../pending'
import { useSelector } from 'react-redux'

const QRCodeModal = ({ hideModal }) => {
  const { loading, qrCodeImage } = useSelector((state) => state.kyc)

  return (
    <Modal
      modalHeader='Scan to proceed to mobile experience'
      crossToClose
      clickToClose
      pressToClose
      hideModal={hideModal}
    >
      {loading ? (
        <div className='flex-center'>
          <Loading />
        </div>
      ) : (
        <div className='flex-center'>
          <img
            alt='QRcode for identity'
            src={`data:image/png;base64,${qrCodeImage}`}
          />
        </div>
      )}
    </Modal>
  )
}

export default QRCodeModal

import React from 'react'
import { formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import useWindowSize from 'hooks/useWindowSize'
import { RecurringInvestmentFrequency } from '../../../data'

const RecurringItem = ({ event: { startDate, maxAmount, lastTransactionFailed, frequency, recurringEventId } }) => {
  const history = useHistory()
  const { width } = useWindowSize()

  let rowComponent = null

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div data-testid={`tile-${recurringEventId}`} className='history-grid-container__row with_event' onClick={() => history.push(`/recurring-investment-confirmed/${recurringEventId}`)} role='button' tabIndex={0} aria-label='Recurring Investment'>
        <span className='b_18_regular align-center display-flex'>
          <img src={images.restart_alt} className='img-right-gab' alt='Recurring Investment'/>
          <span className='flex-col align-left'>
            <span>{RecurringInvestmentFrequency[frequency]} on {frequency === 'MONTHLY' ? moment(startDate).format('Do') : `${moment(startDate).format('dddd')}s` }</span>
            {lastTransactionFailed && <span className='action-errorRed'>Transaction Failed</span>}
          </span>
        </span>
        <span className='b_18_semibold align-right'>{formatDecimal(maxAmount)}</span>
        <div className='flex-end'>
          <img src={images['right-arrow']} alt='Recurring Investment'/>
        </div>
      </div>)
  } else {
    rowComponent = (
      <div data-testid={`tile-${recurringEventId}`} className='history-grid-container__row' onClick={() => history.push(`/recurring-investment-confirmed/${recurringEventId}`) } role='button' tabIndex={0} aria-label='Recurring Investment'>
        <div className='history-grid-container__row__flex'>
          <img src={images.restart_alt} className='img-right-gab' alt='Recurring Investment'/>
          <div className='history-grid-container__row__flex__date-info'>
            <span className='b_18_regular'><span>{RecurringInvestmentFrequency[frequency]} on {frequency === 'MONTHLY' ? moment(startDate).format('Do') : `${moment(startDate).format('dddd')}s` }</span></span>
            {lastTransactionFailed && <span className='action-errorRed'>Transaction Failed</span>}
          </div>
        </div>
        <div className='history-grid-container__row__flex'>
          <div className='history-grid-container__row__flex__company-info'>
            <span className='b_18_semibold'>{formatDecimal(maxAmount)}</span>
          </div>
          <img src={images['right-arrow']} alt='Recurring Investment'/>
        </div>
      </div>
    )
  }

  return rowComponent
}

export default RecurringItem

import { ErrorModal, PageLoading } from 'components'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { seoTitleTemplate, Mixpanel, Fullstory, removeBuyOrderRedirect } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import Breadcrumb from 'components/Global/Breadcrumb'
import { FormProvider, useForm } from 'react-hook-form'
import FBOAddFundsForm from './partials/FBOAddFundsForm'
import { getAddFundsPage, getPlaidLinkToken, getPlaidAccounts, resetFundingError } from 'slices/walletSlice'
import { schema } from 'schemas/addFundsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import WireTransferContent from './partials/WireTransferContent'
import { useLocation, useHistory } from 'react-router-dom'
import TopBanner from 'components/Global/TopBanner'
import { usePlaidLink } from 'react-plaid-link'
import BankAccountModal from 'components/FBOAccount/partials/BankAccountModal'
import ReactHtmlParser from 'react-html-parser'
import qs from 'qs'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'

const FBOAddFunds = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showTopBanner, setShowTopBanner] = useState(null)
  const [insufficientError, setInsufficientError] = useState(location?.state?.error === 'UPHOLD_INSUFFICIENT_FUNDS' || null)
  const [showWireInstruction, setShowWireInstruction] = useState(false)
  const [showPlaidErrorModal, setShowPlaidErrorModal] = useState(false)
  const [showUnderReviewModal, setShowUnderReviewModal] = useState(false)
  const [upholdAPIError, setUpholdAPIError] = useState('')

  const query = location.search
  const entityId =
    qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Cash Account', route: `/cash-account${entityId ? `?entityId=${entityId}` : ''}` }
  ]

  const { pageLoading, bank, plaidLinkToken, loading, fundingError, achLimit } = useSelector((state) => state.walletSlice)

  const methods = useForm({ mode: 'onChange', resolver: yupResolver(schema), context: { achLimit } })

  useEffect(() => {
    dispatch(getAddFundsPage(entityId)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload.plaidLinkingAllowed) dispatch(getPlaidLinkToken(entityId))
        const isBankLinked = payload?.cashExternalAccounts?.filter((a) => a.amlStatus === 'APPROVED').length > 0 ? true
          : payload.cashExternalAccounts?.filter((a) => a.amlStatus === 'NEEDS_REVIEW').length > 0 ? 'Under Review' : false

        Mixpanel.track('View Add Funds Page', {
          'Uphold Linked': payload.hasUphold ? payload.upholdError ? 'Email Verification' : true : false,
          'Bank Account Linked': isBankLinked
        })
        if (
          payload &&
          payload.hasUphold &&
          sessionStorage.getItem('connectUpholdAddFunds')
        ) {
          if (!payload.upholdError && !payload.upholdUnderReview) {
            setShowTopBanner({ title: 'Uphold Successfully Linked', body: 'Your Uphold Account can now be used as a funding method.' })
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Uphold' })
          }
          if (payload.upholdUnderReview) {
            setShowUnderReviewModal(true)
          }
          if (payload.upholdError) {
            setShowTopBanner({ title: 'Almost there!', body: `Please check your email. ${payload.upholdError}` })
          }
          sessionStorage.removeItem('connectUpholdAddFunds')
        }
        if (insufficientError) {
          const upholdCards = payload.upholdCards
          setSelectedAccount({ ...upholdCards.filter((a) => a.id === location?.state?.cardId)[0], method: 'Uphold' })
          methods.reset({ amount: `$${location?.state?.amount}` })
          methods.setError('amount', {
            type: 'UPHOLD_INSUFFICIENT_FUNDS',
            message: 'Insufficient funds. Change the amount to transfer or select a different funding source.'
          })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (payload) {
          setUpholdAPIError(payload)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (fundingError === 'ADD_FUNDS_HIGH_RISK_DETECTED') {
      setShowTopBanner({ title: 'Unable to process deposit', body: (() => <div>We are having an issue connecting with the bank account you selected to process the deposit. Please try using a different bank account to add funds or <a href='/contact' className='inline-text-link'>Contact Us</a> for help.</div>)(), type: 'error' })
      // if plaid fails, remove buy order redirect, if exists
      removeBuyOrderRedirect()
    }
    return () => {
      dispatch(resetFundingError())
    }
  }, [fundingError])

  const onSubmit = async (formValues) => {
    if (selectedAccount) {
      const formAmount = formValues.amount.charAt(0) === '$' ? formValues.amount.substring(1) : formValues.amount
      Mixpanel.track('Click Next on Add Funds Page', { 'Funding Method': selectedAccount.method, Currency: selectedAccount.currency, Amount: formAmount })
      Fullstory.track('Click Next on Add Funds Page', { funding_method: selectedAccount.method, currency: selectedAccount.currency, amount: formAmount })
      history.push({
        pathname: `/cash-account/add-funds-summary/${selectedAccount.id}`,
        search: `?amount=${formAmount}&cardCurrency=${selectedAccount.currency}&cardLabel=${selectedAccount.label}&type=${selectedAccount.method}${entityId ? `&entityId=${entityId}` : ''}`
      })
    }
  }

  const cancelAddFunds = () => {
    Mixpanel.track('Click Cancel on Add Funds Page')
    // If from buy order page, go back to buy order page and remove redirect
    if (localStorage?.getItem('from-buy-order-page')) {
      const path = localStorage?.getItem('from-buy-order-page')
      removeBuyOrderRedirect()
      history.push(path)
    } else {
      history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
    }
  }

  const handleUpholdAPIError = () => {
    setUpholdAPIError('')
    history.goBack()
  }

  const onSuccess = useCallback(async (publicToken, metadata) => {
    window.scrollTo(0, 0)
    dispatch(getPlaidAccounts({ publicToken: metadata.public_token, plaidAccountId: metadata.account_id, entityId })).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload === 500) {
          setShowPlaidErrorModal(true)
        } else {
          if (payload?.amlStatus === 'NEEDS_REVIEW') {
            setShowUnderReviewModal(true)
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Bank Account', 'Name Match': false })
          } else {
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Bank Account', 'Name Match': true })
            setShowTopBanner({ title: 'Account(s) Successfully Connected', body: 'New account is now available as a funding method.' })
          }
          dispatch(getAddFundsPage(entityId))
        }
      }
    })
  }, [])

  const onExit = useCallback(() => {
    setShowPlaidErrorModal(true)
    // if plaid fails, remove buy order redirect, if exists
    removeBuyOrderRedirect()
    Mixpanel.track('Cancel Plaid Linking')
  }, [])

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onExit
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    Mixpanel.track('Click Link Account on Select Funding Method', { 'Account Type': 'Bank Account' })
    open()
  }

  if (pageLoading || loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Add Funds')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      {upholdAPIError && <ErrorModal
        error={parseErrorMessages({ error: upholdAPIError })?.placeOrderError}
        hideModal={handleUpholdAPIError}
        message='OK'
      />}
      <SeoMeta title={seoTitleTemplate('Add Funds')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          {showTopBanner && (
            <TopBanner
              title={showTopBanner.title}
              body={showTopBanner.body}
              hideBanner={() => setShowTopBanner(null)}
              type={showTopBanner.type}
            />
          )}
          <Breadcrumb items={items} />
          <h1>Add Funds</h1>
          <div className='add-funds-container'>
            <FormProvider {...methods}>
              <FBOAddFundsForm
                onSubmit={onSubmit}
                cancelAddFunds={cancelAddFunds}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                insufficientError={insufficientError}
                setInsufficientError={setInsufficientError}
                setShowWireInstruction={setShowWireInstruction}
                connectPlaidAccount = {connectPlaid}
                entityId={entityId}
              />
            </FormProvider>
            {showWireInstruction && (
              <WireTransferContent bank={bank}/>
            )}

            {showPlaidErrorModal && <BankAccountModal
              title='Trouble Connecting to Bank'
              copy={ReactHtmlParser(`We are having trouble connecting to your bank and are unable to add your bank account at this time.<br /><br />
              Please come back and  try again later.`)}
              buttonCopy='Okay'
              handleClick={() => setShowPlaidErrorModal(false)}
              hideModal={() => setShowPlaidErrorModal(false)}
            />}
            {showUnderReviewModal && <BankAccountModal
              title='Review Required'
              copy={ReactHtmlParser(`It looks like the name on this account is not an exact match to the name on your Linqto account.
              <br /><br />
              This can happen if you are attempting to connect to a bank account in the name of a spouse, or a retirement account, for example. 
              <br/><br/>
              We will contact you once it has been reviewed. If you feel an error has been made, you can upload a bank statement to expedite the review. `)}
              twoButtons
              buttonCopy='Upload  Statement'
              handleClick={() => history.push('/documents')}
              hideModal={() => setShowUnderReviewModal(false)}
              secondaryButtonCopy='Wait for Review'
              handleSecondaryClick={() => setShowUnderReviewModal(false)}
            />}
          </div>
        </div>
      </div>
    </>
  )
}
export default FBOAddFunds

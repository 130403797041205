import React from 'react'
import { useSelector } from 'react-redux'
import InvestmentItem from '../InvestmentItem'
import { ConditionalRender } from 'components'
import HistoryTitle from './HistoryTitle'

const PastHoldings = ({ groupBy }) => {
  const { exitedCompanies, soldCompanies, entities } = useSelector(
    (state) => state.portfolioSlice
  )
  const { firstName, lastName } = useSelector((state) => state.userSlice)
  const hasEntities = entities?.length > 0

  const companiesByAccount = (filteredCompanies, account) => {
    if (filteredCompanies.length > 0) {
      return (
        <div key={`pastHoliding_${account}`}>
          <HistoryTitle
            account={account}
            type='pastHolding'
            showCompany={true}
          />
          {filteredCompanies.map((company, i) => (
            <InvestmentItem
              key={`exited_${i}${company.companyId}`}
              company={company}
              type='history'
            />
          ))}
        </div>
      )
    } else {
      return (
        <div key={`pastHoliding_${account}`}>
          <HistoryTitle
            account={account}
            type='pastHolding'
            showCompany={true}
          />
          <div className='current-investment-item current-investment-empty'>
            No past holdings in this account.
          </div>
        </div>
      )
    }
  }

  const groupedCompanies = exitedCompanies?.reduce((acc, current) => {
    const x = acc.find((item) => item.companyId === current.companyId)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  return (
    <div className='past-holdings-container'>
      <ConditionalRender
        isVisible={!hasEntities || (hasEntities && groupBy === 'investment')}
      >
        <h3>Past Holdings</h3>
        {groupedCompanies.concat(soldCompanies).map((company) => (
          <InvestmentItem
            key={company.companyId}
            company={company}
            type='history'
          />
        ))}
      </ConditionalRender>
      <ConditionalRender isVisible={hasEntities && groupBy === 'account'}>
        {companiesByAccount(
          exitedCompanies.concat(soldCompanies).filter((item) => !('entityId' in item)),
          `${firstName} ${lastName}`
        )}
        {entities.map((entity, i) =>
          companiesByAccount(
            exitedCompanies.concat(soldCompanies).filter((a) => a.entityId === entity.entityId),
            entity.name
          )
        )}
      </ConditionalRender>
    </div>
  )
}

export default PastHoldings

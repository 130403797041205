import AddFundsModalItem from './AddFundsModalItem'
import { images } from 'assets'
import { useSelector } from 'react-redux'

const PlaidFundingMethod = ({
  hideModal,
  connectPlaidAccount,
  setShowWireInstruction,
  setSelectedAccount
}) => {
  const connectPlaid = () => {
    connectPlaidAccount()
    setShowWireInstruction(false)
    hideModal()
    setSelectedAccount(null)
  }
  const { featureFlags: { InstantAchFundingEnabled } } = useSelector(state => state.userSlice)
  const description = InstantAchFundingEnabled ? 'Link an Account. Funds are available immediately' : 'Funds are available within 1-3 business days'

  return (
    <AddFundsModalItem
      onSelect={connectPlaid}
      account={{
        label: 'Bank Account',
        iconUrl: images['plaid-account-active'],
        description
      }}
    />
  )
}

export default PlaidFundingMethod

import React from 'react'
import OrderHistory from './OrderHistory'
import PastHoldings from './PastHoldings'
import { useSelector } from 'react-redux'
import InvestmentNoData from '../InvestmentNoData'
const HistoryTab = ({ tab, groupBy }) => {
  const { exitedCompanies, recentOrders, soldCompanies } = useSelector(state => state.portfolioSlice)
  return (
    <>
      {recentOrders?.length > 0 ? <OrderHistory groupBy={groupBy} /> : <InvestmentNoData tab={tab} />}
      {(exitedCompanies?.length > 0 || soldCompanies?.length > 0) && <PastHoldings groupBy={groupBy} />}
    </>
  )
}

export default HistoryTab

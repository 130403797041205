import { images } from 'assets'
import { formatDate, formatDecimal } from 'utils'

const SellOrderItem = ({ order, handleClick }) => {
  const { doneAt, type, amount, createdAt, sellOfferId } = order || {}
  return (
    <div className='sell-order-summary-container completed-sales pointer' onClick={handleClick} data-testid={`offerId-${sellOfferId}`} role='button' tabIndex={0} aria-label='Navigate to order details'>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>{doneAt ? formatDate(doneAt) : formatDate(createdAt || '')}</span>
        <span className='sell-order-summary-container__row__copy'>{type || 'SELL'}</span>
        <div className='sell-order-summary-container__row__right-arrow'>
          <span className='sell-order-summary-container__row__copy bold'>{formatDecimal(amount)}</span>
          <img className='' src={images['right-arrow']} alt={type}/>
        </div>
      </div>
    </div>
  )
}

export default SellOrderItem

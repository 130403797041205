const SummaryDetailsPlaidError = ({ error }) => {
  return (
    <div className='add-funds-summary'>
      <div className='add-funds-summary-row error' >
        {error}
      </div>
    </div>
  )
}

export default SummaryDetailsPlaidError

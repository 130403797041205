import { images } from 'assets'
import { Mixpanel } from 'utils'

const EntityDropDown = ({ groupBy, setGroupBy }) => {
  const handleChange = (type) => {
    setGroupBy(type)
    Mixpanel.track('Click Change Portfolio View', { Type: type === 'account' ? 'Show by account' : 'Show by investment' })
  }

  return (
    <div className='dropdown-container'>
      <div className='dropdown-wapper item dropdown dropdown-6'>
        <span className='dropdown-icon'>
          <img alt='Show By' src={images['show-by-account']} />
        </span>
        <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6'>
          <a className='item' onClick={() => handleChange('account')} role='button' tabIndex={0} aria-label='Show by account'>
            {groupBy === 'account' && (
              <img alt='Show by account Selected' src={images['show-by_selected']} />
            )}
            Show by account
          </a>
          <a className='item' onClick={() => handleChange('investment')} role='button' tabIndex={1} aria-label='Show by investment'>
            {groupBy === 'investment' && (
              <img alt='Show by investment Selected' src={images['show-by_selected']} />
            )}
            Show by investment
          </a>
        </div>
      </div>
    </div>
  )
}

export default EntityDropDown

import { useEffect, useState } from 'react'
import {
  PageLoading,
  Wrapper,
  ErrorModal,
  SeoMeta,
  MessagingCarousel,
  TopBanner,
  ConditionalRender
} from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/orderConfirmationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import qs from 'qs'
import { errorTypes } from 'types/errorTypes'
import { Mixpanel, seoTitleTemplate, Fullstory } from 'utils'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { confirmOrder, commitOrder } from 'slices/placeOrderSlice'
import ReactGA from 'react-ga4'
import OrderConfirmationForm from './partials/OrderConfirmationForm'
import OrderConfirmationHeader from './partials/OrderConfirmationHeader'
import InvestmentDetails from './partials/InvestmentDetails'
import Agreements from './partials/Agreements'
import Payment from './partials/Payment'
import AddFundsLink from './partials/AddFundsLink'
import SimplifiedPurchase from './partials/SimplifiedPurchase'
import { useLDFlags } from 'utils/LaunchDarkly'

const OrderConfirmation = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { order, placeOrderError, company, accounts } = useSelector(
    (state) => state.placeOrderSlice
  )

  const { featureFlags } = useSelector((state) => state.userSlice)
  const query = location.search
  const {
    amount,
    shares,
    type,
    companyId,
    companyName,
    minimumInvestmentAmount,
    urlName,
    discountedAmount,
    discountNote,
    promo,
    fromTradeShares
  } = qs.parse(query, { ignoreQueryPrefix: true })
  const { buyOrderReviewContentCards } = useSelector(
    (state) => state.commonSlice
  )
  const { purchaseSimplification } = useLDFlags(['purchaseSimplification'])
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [tradeButtonClicked, setTradeButtonClicked] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({})
  const [showTopBanner, setShowTopBanner] = useState(null)
  const [linqtoBucksEnabled, setLinqtoBucksEnabled] = useState(false)
  const [purchaseWithShares, setPurchaseWithShares] = useState(false)
  const [purchaseWithCash, setPurchaseWithCash] = useState(true)
  const [promoCode, setPromoCode] = useState(promo || '')
  const [purchaseCreditsEnabled, setPurchaseCreditsEnabled] = useState(true)
  const [promoCodeError, setPromoCodeError] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])
  const changedOrderParameters = {}

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    context: {
      purchaseWithShares
    }
  })
  const { getValues, setValue, clearErrors } = methods
  useEffect(() => {
    refreshOrder(true, linqtoBucksEnabled, promoCode, purchaseCreditsEnabled, fromTradeShares)
  }, [])

  useEffect(() => {
    if (fromTradeShares && purchaseSimplification) {
      setTradeButtonClicked(true)
    }
  }, [fromTradeShares, purchaseSimplification])

  useEffect(() => {
    setPurchaseCreditsEnabled(featureFlags?.PurchaseCreditEnabled)
  }, [featureFlags?.PurchaseCreditEnabled])

  useEffect(() => {
    // If purchasing with shares and user disables purchaseWithCash then we show insufficent shares error
    if (
      purchaseWithShares &&
      !purchaseWithCash &&
      order?.total > 0 &&
      getValues('sellCompanyId')
    ) {
      methods?.setError('sharesInsufficent', {
        type: 'PLACE_ORDER_INSUFFICIENT_FUNDS_FOR_TRADE',
        message:
          'Shares alone are insufficient to pay for this order. Please toggle on cash or select different shares.'
      })
    } else {
      clearErrors('sharesInsufficent')
    }
  }, [purchaseWithShares, purchaseWithCash, order, getValues('sellCompanyId')])

  useEffect(() => {
    let timerId
    // Create a timeout to refresh the page data when the Order's expiration has been reached
    const { msToLive } = order
    if (msToLive) {
      timerId = setTimeout(() => {
        refreshOrder(true, linqtoBucksEnabled, null, purchaseCreditsEnabled)
      }, msToLive)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [order])

  const updateOrderValues = ({ amount, shares }) => {
    changedOrderParameters.amount = amount
    changedOrderParameters.shares = shares
    const newurl = `/orderconfirmation/${
      location.pathname.split('/orderconfirmation/')[1]
    }?companyId=${companyId}&companyName=${companyName}&amount=${amount}&type=${type}&linqtoBucksEnabled=${linqtoBucksEnabled}&shares=${shares}&minimumInvestmentAmount=${minimumInvestmentAmount}&urlName=${urlName}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
  const refreshOrder = async (
    showPageLoader = true,
    linqtoBucks = false,
    promoCode = null,
    purchaseCredits = false,
    fromTradeButton = false
  ) => {
    const sellCompanyString = getValues('sellCompanyId')
    const simpleCompanyString = getValues('simpleCompanyId')
    const sellCompanyId = sellCompanyString
      ? JSON.parse(sellCompanyString)?.value
      : null
    const simpleCompanyId = simpleCompanyString
      ? JSON.parse(simpleCompanyString)?.value
      : null
    const upholdId = location.pathname.split('/orderconfirmation/')[1]
    if (showPageLoader) {
      setPageLoading(true)
    }

    const { meta, payload } = await dispatch(
      confirmOrder({
        upholdId,
        companyId,
        type,
        amount: changedOrderParameters?.amount || amount,
        entityId: selectedAccount?.entityId,
        useLinqtoBucks: linqtoBucks,
        shares: changedOrderParameters?.shares || shares,
        promoCode,
        useCredits: purchaseCredits,
        sellCompanyId: fromTradeButton
          ? -1
          : simpleCompanyId || sellCompanyId
      })
    )
    if (showPageLoader) {
      setPageLoading(false)
    }
    if (meta.requestStatus === 'fulfilled') {
      if (showPageLoader) {
        Mixpanel.track('View Order Review Confirmation', {
          'Company Name': companyName,
          'Minimum investment amount': minimumInvestmentAmount,
          'Amount selected': amount,
          'Estimated shares': shares,
          'Promotion Discount amount': discountedAmount,
          'Promotion ID': discountNote,
          'Purchase Credits': payload?.order?.creditsUsed,
          'Share Price': payload?.order?.sharePrice,
          'purchase-simplification': purchaseSimplification
        })
        Fullstory.track('Page View', {
          page_name: 'Order Review',
          company_name: companyName,
          minimum_investment_amount: minimumInvestmentAmount,
          amount_selected: amount,
          estimated_shares: shares,
          promotion_discount_amount: discountedAmount,
          promotion_id: discountNote,
          purchase_credits: payload?.order?.creditsUsed,
          share_price: payload?.order?.sharePrice
        })
        // If user is redirected back after adding funds
        if (localStorage?.getItem('from-buy-order-page')) {
          setShowTopBanner({
            title: 'Deposit Complete',
            body: 'Your funds are now available.'
          })
          localStorage?.removeItem('from-buy-order-page')
        }
      }
      if (Object.keys(selectedAccount).length === 0) {
        setSelectedAccount(payload?.accounts?.[0])
      }
      if (payload.order?.isPriceChange) {
        updateOrderValues({
          amount: payload?.order?.amount,
          shares: payload?.order?.shares
        })
        setShowErrorModal(true)
      }
      if (payload?.order?.promoCode) {
        Mixpanel.track('Click Apply Promo Code', {
          'Promo Code': promoCode,
          'Promo Code Results': payload?.order?.isPromoCodeValid
            ? 'Pass'
            : 'Fail',
          'purchase-simplification': purchaseSimplification
        })
        setPromoCodeError(!payload?.order?.isPromoCodeValid)
      } else {
        setPromoCode('')
      }
    } else if (meta.requestStatus === 'rejected') {
      if (
        payload?.placeOrderError?.type ===
        'PLACE_ORDER_USER_PROFILE_NOT_COMPLETE'
      ) {
        Mixpanel.track('View Incomplete Profile Purchase Modal', {
          'purchase-simplification': purchaseSimplification
        })
      } else if (payload?.placeOrderError?.type) {
        Mixpanel.track('Purchase Error', {
          'Error Type': payload?.placeOrderError?.type,
          'purchase-simplification': purchaseSimplification
        })
        setShowErrorModal(true)
      }
    }
  }
  const handleCommitOrder = (formValues) => {
    const body = {
      companyId,
      type: order.paymentType,
      amount: order.amount,
      entityId: selectedAccount.entityId || 'individual',
      useLinqtoBucks: linqtoBucksEnabled,
      shares,
      useCredits: purchaseCreditsEnabled,
      useCashBalance: purchaseWithCash
    }
    if (order?.promoCode && order?.isPromoCodeValid) {
      body.promoCode = order?.promoCode
    }

    if (
      featureFlags?.PurchaseWithSharesAllowed &&
      purchaseWithShares &&
      getValues('sellCompanyId')
    ) {
      body.sellCompanyId = JSON.parse(getValues('sellCompanyId'))?.value
      body.sellAmount = order?.sellAmount
      body.sellShares = order?.sellShares
    }

    dispatch(commitOrder(body)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        const { orderId, tradeId } = payload
        ReactGA.event('purchase', {
          action: 'PURCHASE',
          label: `User purchase ${company.name} `,
          value: order.amount,
          transaction_id: orderId,
          currency: 'USD',
          linqto_bucks_used: order.linqtoBucksUsed || 0,
          items: [
            {
              item_id: companyId,
              item_name: company.name,
              currency: 'USD',
              item_category: order.paymentType,
              quantity: shares
            }
          ]
        })
        history.push(
          `/orderconfirmed/${orderId}?amount=${amount}&shares=${shares}&sharePrice=${
            order.sharePrice
          }&minimumInvestmentAmount=${minimumInvestmentAmount}&companyName=${
            company.name
          }&type=${order.paymentType}${tradeId ? `&tradeId=${tradeId}` : ''}${
            order?.creditsUsed ? `&purchaseCredits=${order?.creditsUsed}` : ''
          }`
        )
      } else if (meta.requestStatus === 'rejected') {
        if (
          payload?.placeOrderError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED'
        ) {
          refreshOrder(false)
          updateOrderValues({
            amount: payload?.order?.amount,
            shares: payload?.order?.shares
          })
          setShowErrorModal(true)
        } else if (
          payload?.placeOrderError?.type ===
          'PLACE_ORDER_INSUFFICIENT_FUNDS_FOR_TRADE'
        ) {
          methods?.setError('sharesInsufficent', {
            type: payload?.placeOrderError?.type,
            message: payload?.placeOrderError?.message
          })
        } else if (payload?.placeOrderError?.type) {
          // make sure only when identified error is present then handle it
          Mixpanel.track('Purchase Error', {
            'Error Type': payload?.placeOrderError?.type
          })
        }
        setShowErrorModal(true)
      }
    })
  }

  const closeModal = () => {
    let path
    switch (placeOrderError.type) {
    case errorTypes.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES:
      history.goBack()
      break
    case errorTypes.PLACE_ORDER_SANCTIONED_COUNTRY:
      path = '/contact'
      break
    case 'PLACE_ORDER_PARAMETERS_CHANGED':
      path = null
      break
    case 'Internal Server Error':
      path = `/products/${urlName}`
      break
    default:
      path = null
    }
    setShowErrorModal(false)
    if (path) {
      history.push(path)
    }
  }

  const handlePurchaseAsChange = (e) => {
    setSelectedAccount(accounts[e.target.value])
    setSelectedOptions([])
    setValue('sellCompanyId', null)
    clearErrors('sellCompanyId')
    Mixpanel.track('Change Purchase As Account on Buy Order Review Page', {
      'purchase-simplification': purchaseSimplification
    })
    Fullstory.track('Dropdown', {
      dropdown: 'Purchase As',
      id: accounts[e.target.value]?.entityId,
      cash_balance: accounts[e.target.value]?.amountAvailable
    })
  }

  const toggleShares = () => {
    const selection = !purchaseWithShares ? 'On' : 'Off'
    setPurchaseWithShares(!purchaseWithShares)
    setPurchaseCreditsEnabled(false)
    if (purchaseWithShares) {
      setValue('sellCompanyId', null)
      clearErrors('sellCompanyId')
      setSelectedOptions([])
      refreshOrder(false, linqtoBucksEnabled, promoCode, false)
    }
    Mixpanel.track('Share Toggle Click', {
      Status: selection,
      'purchase-simplification': purchaseSimplification
    })
    Fullstory.track('Toggle Click', { toggle: 'Shares', status: selection })
  }

  const onDropDownChange = (option) => {
    setValue('sellCompanyId', JSON.stringify(option))
    setSelectedOptions([option])
    refreshOrder(false, linqtoBucksEnabled, promoCode, false)
    Mixpanel.track('Trade Shares Drop Down Click', {
      'Company Selected': option?.label,
      'Shares Amount': option?.numberOfShares,
      'Share Price': option?.pricePerShare,
      'purchase-simplification': purchaseSimplification
    })
    Fullstory.track('Dropdown', {
      dropdown: 'Shares',
      company_selected: option?.label,
      shares_amount: option?.numberOfShares,
      share_price: option?.pricePerShare
    })
  }

  const updateOrder = () => {
    refreshOrder(false, linqtoBucksEnabled, promoCode, purchaseCreditsEnabled)
  }

  const togglePurchaseCredits = () => {
    const selection = !purchaseCreditsEnabled ? 'On' : 'Off'
    setPurchaseCreditsEnabled(!purchaseCreditsEnabled)
    if (!purchaseSimplification) {
      refreshOrder(false, linqtoBucksEnabled, '', !purchaseCreditsEnabled)
    }
    Mixpanel.track('Purchase Credits Toggle Click', { Status: selection })
    Fullstory.track('Toggle Click', {
      toggle: 'Purchase Credits',
      status: selection
    })
  }

  const toggleLinqtoBucks = () => {
    const selection = !linqtoBucksEnabled ? 'On' : 'Off'
    Mixpanel.track('Change Linqto Bucks Selection on Buy Order Review Page', {
      'Linqto Bucks Selection': selection,
      'purchase-simplification': purchaseSimplification
    })
    setLinqtoBucksEnabled(!linqtoBucksEnabled)
    setPromoCode('')
    setPromoCodeError(false)
    if (!purchaseSimplification) {
      refreshOrder(false, !linqtoBucksEnabled, '', purchaseCreditsEnabled)
    }
    Fullstory.track('Toggle Click', {
      toggle: 'Linqto Bucks',
      status: selection
    })
  }

  const applyPromoCode = () => {
    Fullstory.track('Button', { name: 'Apply Discount' })
    if (promoCode?.length > 0) {
      if (order?.linqtoBucksUsed === 0) {
        refreshOrder(false, false, promoCode, purchaseCreditsEnabled)
      }
    } else {
      setPromoCodeError(true)
    }
  }

  const removePromoCode = () => {
    Mixpanel.track('Remove Promo Code', {
      'Promo Code': promoCode,
      'purchase-simplification': purchaseSimplification
    })
    refreshOrder(false, false, '', purchaseCreditsEnabled)
  }

  const onPromoCodeChange = (e) => {
    setPromoCode(e.target.value)
    setPromoCodeError(false)
  }

  const toggleCash = () => {
    const selection = !purchaseWithCash ? 'On' : 'Off'
    setPurchaseWithCash(!purchaseWithCash)
    Mixpanel.track('Cash Balance Toggle Click', {
      Status: selection,
      'purchase-simplification': purchaseSimplification
    })
    Fullstory.track('Toggle Click', { toggle: 'Cash', status: selection })
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Confirm Order')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm Order')} />
      <Wrapper>
        <div className='page-container buy-order-container'>
          <div className='inner-container'>
            {showTopBanner && (
              <TopBanner
                title={showTopBanner.title}
                body={showTopBanner.body}
                hideBanner={() => setShowTopBanner(null)}
                type={showTopBanner.type}
              />
            )}
            {buyOrderReviewContentCards?.length ? (
              <div className='messaging-carousel-container -mt-5 md:-mt-9 mb-4 md:mb-6'>
                <MessagingCarousel
                  data={buyOrderReviewContentCards}
                  page='Buy Order Review'
                />
              </div>
            ) : null}
            <FormProvider {...methods}>
              <ConditionalRender isVisible={!!purchaseSimplification}>
                <SimplifiedPurchase
                  purchaseWithShares={purchaseWithShares}
                  selectedAccount={selectedAccount}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  toggleShares={toggleShares}
                  onDropDownChange={onDropDownChange}
                  toggleCash={toggleCash}
                  purchaseWithCash={purchaseWithCash}
                  promoCode={promoCode}
                  promoCodeError={promoCodeError}
                  applyPromoCode={applyPromoCode}
                  removePromoCode={removePromoCode}
                  onPromoCodeChange={onPromoCodeChange}
                  toggleLinqtoBucks={toggleLinqtoBucks}
                  linqtoBucksEnabled={linqtoBucksEnabled}
                  togglePurchaseCredits={togglePurchaseCredits}
                  purchaseCreditsEnabled={purchaseCreditsEnabled}
                  refreshOrder={refreshOrder}
                  handlePurchaseAsChange={handlePurchaseAsChange}
                  handleCommitOrder={handleCommitOrder}
                  setPromoCodeError={setPromoCodeError}
                  updateOrder={updateOrder}
                  setPurchaseWithShares={setPurchaseWithShares}
                  tradeButtonClicked={tradeButtonClicked}
                  setTradeButtonClicked={setTradeButtonClicked}
                />
              </ConditionalRender>
              <ConditionalRender isVisible={!purchaseSimplification}>
                <OrderConfirmationHeader />
                <InvestmentDetails />
                <Payment
                  selectedAccount={selectedAccount}
                  linqtoBucksEnabled={linqtoBucksEnabled}
                  promoCode={promoCode}
                  promoCodeError={promoCodeError}
                  purchaseCreditsEnabled={purchaseCreditsEnabled}
                  purchaseWithShares={purchaseWithShares}
                  purchaseWithCash={purchaseWithCash}
                  toggleCash={toggleCash}
                  handlePurchaseAsChange={handlePurchaseAsChange}
                  toggleShares={toggleShares}
                  onDropDownChange={onDropDownChange}
                  togglePurchaseCredits={togglePurchaseCredits}
                  toggleLinqtoBucks={toggleLinqtoBucks}
                  applyPromoCode={applyPromoCode}
                  removePromoCode={removePromoCode}
                  onPromoCodeChange={onPromoCodeChange}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
                <AddFundsLink selectedAccount={selectedAccount} />
                <Agreements selectedAccount={selectedAccount} />
                <OrderConfirmationForm
                  purchaseWithShares={purchaseWithShares}
                  purchaseWithCash={purchaseWithCash}
                  handleCommitOrder={handleCommitOrder}
                  selectedAccount={selectedAccount}
                />
              </ConditionalRender>
            </FormProvider>
          </div>
        </div>
        {showErrorModal && (
          <ErrorModal
            error={placeOrderError}
            hideModal={closeModal}
            crossToClose={true}
            message='OK'
            centered={
              placeOrderError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED'
            }
          />
        )}
      </Wrapper>
    </>
  )
}

export default OrderConfirmation

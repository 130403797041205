import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { saveAccreditedInvestorStatusChoice, getInvestorStatus } from 'slices/investorStatusSlice'
import { Mixpanel } from 'utils'
import RadioButton from 'components/Global/Inputs/RadioButton'
import ButtonGroup from '../ButtonGroup'
import { useEffect, useState } from 'react'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { EUResidentModalData, EUResidentsInputs } from 'components/data'
import EUResidentsModal from './EUResidentsModal'

const EUResidentsSection = () => {
  const dispatch = useDispatch()
  const [showTerms, setShowTerms] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const methods = useForm({ defaultValues: { choice: '' } })
  const { unregister, watch, register, handleSubmit, getValues, setValue } = methods
  const { accreditedCountry, userFullName } = useSelector((state) => state.investorStatusSlice)

  const choice = watch('choice')
  const checkbox1 = watch('checkbox1')
  const checkbox2 = watch('checkbox2')
  const checkbox3 = watch('checkbox3')
  const confirmCheckbox = watch('confirmCheckbox')
  const experiencedInvestorChoice = watch('experiencedInvestorChoice')

  // useEffect(() => {
  //   if (selfAccreditation === 'NOT_ACCREDITED') {
  //     setValue('choice', selfAccreditation)
  //   }
  // }, [selfAccreditation])

  useEffect(() => {
    unregister('checkbox1')
    unregister('checkbox2')
    unregister('checkbox3')
    unregister('experiencedInvestorChoice')
    setValue('confirmCheckbox', false)
    setDisabled(choice !== 'NOT_ACCREDITED')
  }, [choice])

  useEffect(() => {
    const values = getValues()
    if (values?.choice === 'PROFESSIONAL_CLIENT') {
      const twoOptionsSelected = [values?.checkbox1, values?.checkbox2, values?.checkbox3]?.filter(i => !!i)?.length || 0
      setDisabled(!(twoOptionsSelected > 1 && confirmCheckbox))
    }
  }, [checkbox1, checkbox2, checkbox3, confirmCheckbox])

  useEffect(() => {
    if (getValues()?.choice === 'EXPERIENCED_INVESTOR') setDisabled(!experiencedInvestorChoice || !confirmCheckbox)
  }, [experiencedInvestorChoice, confirmCheckbox])

  const onSubmit = async data => {
    let obj = {}
    if (choice === 'EXPERIENCED_INVESTOR') {
      const { choice, experiencedInvestorChoice: subChoice } = data
      obj = { choice, subChoice }
    } else if (choice === 'PROFESSIONAL_CLIENT') {
      const { checkbox1, checkbox2, checkbox3, choice } = data
      const subChoice = `${checkbox1 ? checkbox1 + '\n' : ''}${checkbox2 ? checkbox2 + '\n' : ''}${checkbox3 ? checkbox3 + '\n' : ''}`
      obj = { choice: choice, subChoice }
    }
    // else {
    //   await dispatch(saveSelfAccreditation({ selfAccreditation: choice }))
    //   await dispatch(getInvestorStatus())
    //   return
    // }
    Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Auto', 'Accredited Country': accreditedCountry, 'Accreditation Status': choice })
    await dispatch(saveAccreditedInvestorStatusChoice(obj))
    await dispatch(getInvestorStatus())
  }

  const renderTerms = () => <>I confirm I have read the <span onClick={() => setShowTerms(true)} role='button' tabIndex={0} aria-label='Confirm verification statement'>verification statement</span> and I am qualified to invest.</>

  return (
    <FormProvider {...methods}>
      {showTerms && <EUResidentsModal hideModal={() => setShowTerms(false)} data={EUResidentModalData(choice, userFullName, accreditedCountry)} choiceData={{ checkbox1, checkbox2, checkbox3, experiencedInvestorChoice }}/>}
      <form className='eu-residents-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='header'>Choose your accreditation status</div>
        <select {...register('choice')} role='eu-dropdown'>
          <option value='' disabled>Please select an option</option>
          <option value='EXPERIENCED_INVESTOR'>Experienced Investor (most common)</option>
          <option value='PROFESSIONAL_CLIENT'>Professional Client</option>
          {/* <option value='NOT_ACCREDITED'>Not accredited (limited experience)</option> */}
        </select>
        {choice === 'PROFESSIONAL_CLIENT' &&
            <div className='eu-residents-container__experienced-investor-options'>
              <span className='b_22_semibold eu-residents-container__experienced-investor-options'>I meet at least two of the following criteria <span className='eu-residents-container__experienced-investor-options b_18_regular'>(please select two options):</span></span>
              <div className='eu-residents-container_radio-group'>
                <Checkbox label='I have carried out transactions of significant size in private market assets at an average frequency of 10 per quarter
                over the previous four quarters.' name='checkbox1' value='I have carried out transactions of significant size in private market assets at an average frequency of 10 per quarter
                over the previous four quarters.' />
                <Checkbox label='I have a financial portfolio exceeding €500,000.' name='checkbox2' value='I have a financial portfolio exceeding €500,000.' />
                <Checkbox label='I work or have worked in the professional sector for at least one year.' name='checkbox3' value='I work or have worked in the professional sector for at least one year.' />
              </div>
            </div>}
        {choice === 'EXPERIENCED_INVESTOR' &&
            <div className='eu-residents-container__experienced-investor-options'>
              <span className='b_22_semibold eu-residents-container__experienced-investor-options'>I meet at least one of the following criteria <span className='eu-residents-container__experienced-investor-options b_18_regular'>(please choose one option):</span></span>
              <div className='radio-group radio-button-container'>
                <RadioButton name='experiencedInvestorChoice' value={EUResidentsInputs.radio1} label={EUResidentsInputs.radio1} />
                <RadioButton name='experiencedInvestorChoice' value={EUResidentsInputs.radio2} label={EUResidentsInputs.radio2} />
                <RadioButton name='experiencedInvestorChoice' value={EUResidentsInputs.radio3} label={EUResidentsInputs.radio3} />
                <RadioButton name='experiencedInvestorChoice' value={EUResidentsInputs.radio4} label={EUResidentsInputs.radio4} />
              </div>
            </div>}
        {choice !== '' && <><div className='content-separator'></div><Checkbox label={renderTerms()} name='confirmCheckbox' /></>}
        {/* {choice === 'NOT_ACCREDITED' && <div className='content accreditation-terms margin'>
          In order to comply with regulations, some or all investment opportunities may not be available to non-accredited investors. If you meet the qualifications in the future, you can update your accreditation status in your investor profile.
        </div>} */}
        <div className='content accreditation-terms'>
          <ButtonGroup disabled={disabled} loading={false} />
        </div>
      </form>
    </FormProvider>
  )
}

export default EUResidentsSection

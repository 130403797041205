import { useEffect, useState } from 'react'
import { useQuery } from 'hooks/useQuery'
import Upload from 'components/Global/Upload/Upload'
import { PageLoading } from 'components'
import Button from 'components/Global/Button'
import Modal from 'components/Global/Modal'
import { seoTitleTemplate, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { Link } from 'react-router-dom'
import ConditionalRender from 'components/Global/ConditionalRender'
import { useDispatch, useSelector } from 'react-redux'
import { schema } from 'schemas/financialAdvisorSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import FinancialAdvisorForm from './partials/FinancialAdvisorForm'
import { FormProvider, useForm } from 'react-hook-form'
import { getFinancialAdvisor, saveFinancialAdvisorReqest } from 'slices/financialAdvisorSlice'
import { uploadDocument, resetUploadErr } from 'slices/commonSlice'

const FinancialAdvisor = () => {
  const query = useQuery()
  const [fileUploaded, setFileUploaded] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const dispatch = useDispatch()
  const { firstName, lastName, isAccredited, showVerificationForm, country } = useSelector(state => state.financialAdvisor)
  const { submitButtonLoading, uploadErr } = useSelector(state => state.commonSlice)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), defaultValues: { country } })
  const { watch, setValue } = methods
  const watchAllFields = watch()
  const financialAdvisorRequestId = query.get('financialAdvisorRequestId')
  const code = query.get('code')
  const urlQueryObj = Object.fromEntries(query.entries())

  const renderVerificationPDF = (e) => {
    e.preventDefault()
    const queryStr = new URLSearchParams({ ...watchAllFields, financialAdvisorRequestId, code }).toString()
    window.open(`${import.meta.env.VITE_APP_API_URL}/page/financialAdvisorPdf?${queryStr}`)
  }

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getFinancialAdvisor({ financialAdvisorRequestId, code }))
      setPageLoading(false)
      if (payload) {
        setValue('country', payload.country)
      }
    })()
  }, [])

  const onSubmit = (formValues) => {
    setPageLoading(true)
    dispatch(saveFinancialAdvisorReqest({ ...formValues, ...urlQueryObj }))
      .then(({ meta }) => {
        setPageLoading(false)
        if (meta.requestStatus === 'fulfilled') {
          setSubmitSuccess(true)
        }
      })
  }

  const submitLetter = () => {
    dispatch(uploadDocument({ acceptedFile: fileUploaded[0], fileName: 'Verification Letter', type: 'FA', urlQueryObj, func: () => {} }))
      .then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          setSubmitSuccess(true)
        }
      })
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta
          title={seoTitleTemplate('Financial Professional Attestation Letter')}
        />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta
        title={seoTitleTemplate('Financial Professional Attestation Letter')}
      />
      <div className='page-container fa-container'>
        <div className='inner-container'>
          <h1>Financial Professional Verification</h1>
          <ConditionalRender isVisible={isAccredited}>
            <div className='b_18_regular'>
              Thank you for assisting{' '}
              <b>
                {firstName} {lastName}
              </b>{' '}
              in verifying accredited investor status in order to make an
              investment on the Linqto Platform.
            </div>
            <div className='b_18_regular space-above-sm space-below-sm'>
              We have since received the requested documentation and no further
              action is needed from you.{' '}
            </div>
            <Button
              onClick={() => window.open(getDomain('/about', true))}
            >
              About Linqto
            </Button>
          </ConditionalRender>
          <ConditionalRender isVisible={!isAccredited}>
            <div className='b_18_regular space-below-sm'>
              <b>
                {firstName} {lastName}
              </b>{' '}
              has requested your help in verifying accredited investor status in
              order to make an investment on the Linqto Platform. First, please
              enter your information below.
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={showVerificationForm && !isAccredited}>
            <FormProvider {...methods}>
              <FinancialAdvisorForm loading={pageLoading} renderVerificationPDF={renderVerificationPDF} onSubmit={onSubmit}/>
            </FormProvider>
            <hr />
          </ConditionalRender>
          <ConditionalRender isVisible={!isAccredited}>
            <h3 className='header'>Custom Letter Verification</h3>
            <br />
            <div className='b_18_regular space-below-sm'>
              A custom letter, on company letterhead, may be submitted to verify
              the accreditation status of {firstName} {lastName}.
            </div>
            <div className='b_18_regular space-below-sm'>
              Provide your: name, address, professional credentials, and your
              relationship to {firstName} {lastName} (e.g. accountant, banker,
              broker, or attorney)
            </div>
            <div className='b_18_regular space-below-sm'> -- and -- </div>
            <div className='b_18_regular'>Confirm you: </div>
            <ol type='a' className='b_18_regular space-below-sm'>
              <li className='b_18_regular'>
                have access to{' '}
                <b>
                  {firstName} {lastName}
                </b>
                &apos;s financial records and know their financial position
              </li>
              <li className='b_18_regular'>
                that on the basis of the above, you can attest that{' '}
                <b>
                  {firstName} {lastName}
                </b>{' '}
                has either the Gross Income or Net Worth to meet the
                requirements for Accredited Investor status.
              </li>
            </ol>
            <Upload
              type='FA'
              name='Verification Letter'
              setFileUploaded={setFileUploaded}
              fileUploaded={fileUploaded}
              allowDelete={true}
              uploadErr={uploadErr}
            />
            <Button
              mode='secondary'
              onClick={submitLetter}
              loading={submitButtonLoading}
              disabled={submitButtonLoading || !fileUploaded}
            >
              Submit
            </Button>
            <div className='b_18_regular space-above-sm'>
              Need help?{' '}
              <Link className='inline-text-link' to='/contact'>
                Contact Us
              </Link>
            </div>
          </ConditionalRender>
          {uploadErr && (
            <Modal
              clickToClose={false}
              pressToClose={false}
              crossToClose={false}
              modalHeader='Failed to Upload'
            >
              <div className='b_18_regular content'>
                An error occurred while uploading the file.
              </div>
              <div className='btn-group centered'>
                <Button onClick={() => dispatch(resetUploadErr())}>
                  Close
                </Button>
              </div>
            </Modal>
          )}
          {submitSuccess && (
            <Modal
              clickToClose={false}
              pressToClose={false}
              crossToClose={true}
              modalHeader='Your verification letter has been successfully submitted!'
              hideModal={() => {
                location.reload()
              }}
              innerStyle='text-left'
            >
              <div className='b_18_regular space-below-sm FA-submit-success'>
                <b>What happens now?</b>
                <br /> We’ll review your letter and be in contact with your
                client shortly.{' '}
              </div>
              <div className='b_18_regular space-above-sm FA-submit-success'>
                In the mean time, we invite you to learn more about Linqto and
                investment opportunities that may be of benefit to you and your
                clients.
              </div>
              <div className='small-space'></div>
              <div className='btn-group centered'>
                <Button
                  onClick={() => window.open(getDomain('/about', true))}
                >
                  Learn More
                </Button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}

export default FinancialAdvisor

import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

const AccountErrorModal = ({ hideModal }) => {
  return (
    <>
      <Modal
        crossToClose
        modalHeader='Incomplete Investor Profile'
        hideModal={() => hideModal('closeBtn')}
      >
        <div className='b_18_regular content'>
          Error retrieving FBO account information
        </div>
        <div className='btn-group centered'>
          <Button onClick={() => hideModal()}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AccountErrorModal

import React from 'react'
import { useSelector } from 'react-redux'
import HistoryColumns from './HistoryColumns'
import HistoryItem from './HistoryItem'
import HistoryTitle from './HistoryTitle'
import { ConditionalRender } from 'components'

const OrderHistory = ({ showCompany = true, groupBy }) => {
  const { recentOrders, entities } = useSelector(
    (state) => state.portfolioSlice
  )
  const { firstName, lastName } = useSelector((state) => state.userSlice)
  const hasEntities = entities?.length > 0

  if (!recentOrders || !recentOrders.length) {
    return null
  }

  const ordersByAccount = (filteredOrders) => {
    if (filteredOrders.length > 0) {
      return filteredOrders.map((order) => (
        <HistoryItem
          key={order.orderId || order.sellOrderId}
          order={order}
          showCompany={showCompany}
          type={order.orderType}
        />
      ))
    } else {
      return (
        <div className='current-investment-item current-investment-empty'>
          You haven&apos;t made any investments in this account yet.
        </div>
      )
    }
  }

  return (
    <div className='history-grid-container'>
      <ConditionalRender
        isVisible={!hasEntities || (hasEntities && groupBy === 'investment')}
      >
        <h3 className='heading_7'>Order History</h3>
        <HistoryColumns showCompany={showCompany} />
        {recentOrders && recentOrders.length
          ? recentOrders.map((order) => (
            <HistoryItem
              key={order.orderId || order.sellOrderId}
              order={order}
              showCompany={showCompany}
              type={order.orderType}
            />
          ))
          : null}
      </ConditionalRender>
      <ConditionalRender isVisible={hasEntities && groupBy === 'account'}>
        {!showCompany && <h3 className='heading_7'>Order History</h3>}
        <HistoryTitle account={`${firstName} ${lastName}`} type='history' showCompany={showCompany} />
        {ordersByAccount(
          recentOrders.filter((item) => !('entityId' in item))
        )}
        {entities.map((entity, i) => (
          <div key={`entity_${i}`}>
            <HistoryTitle account={entity.name} type='history' showCompany={showCompany}/>
            {ordersByAccount(
              recentOrders.filter((a) => a.entityId === entity.entityId)
            )}
          </div>
        ))}
      </ConditionalRender>
    </div>
  )
}

export default OrderHistory

import { useEffect, useRef } from 'react'

const useScrollEndHeight = () => {
  const heightRef = useRef(0)
  useEffect(() => {
    window.addEventListener('scrollend', onScrollEnd)
    return () => {
      window.removeEventListener('scrollend', onScrollEnd)
    }
  }, [])

  const onScrollEnd = () => {
    const documentHeight = document.body.scrollHeight - document.documentElement.clientHeight
    const currentPosition = Math.abs(Math.round(documentHeight - window.scrollY))
    const percent = +(100 - (currentPosition / documentHeight) * 100).toFixed(2)
    if (percent >= heightRef.current) {
      heightRef.current = percent
    }
  }
  return heightRef
}

export default useScrollEndHeight

const parseRewardHistory = (transactions) => {
  return transactions.reduce((result, t) => {
    if (t.createdAt && t.reason && t.amount) {
      result.push({
        ...t,
        createdAt: t.createdAt,
        reason: t.reason,
        amount: t.amount,
        note: t.note ? t.note : ''
      })
    }
    return result
  }, [])
}

export default parseRewardHistory

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationDetailHeader from 'components/OriginationDetail/partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from 'components/OriginationDetail/partials/OriginationDetailCompanyLogo'
import OriginationOfferCompanyCTA from './partials/OriginationOfferCompanyCTA'
import OriginationOfferCompanyOffer from './partials/OriginationOfferCompanyOffer'
import OriginationOfferDocuments from './partials/OriginationOfferDocuments'
import OriginationFooter from 'components/Origination/partials/OriginationFooter'
import OriginationOfferUploadProof from './partials/OriginationOfferUploadProof'
import OriginationNotAvailableModal from 'components/OriginationDetail/partials/OriginationNotAvailableModal'
import { Mixpanel, seoTitleTemplate } from 'utils'
import {
  getOriginationOffer,
  submitOriginationOffer
} from 'slices/originationSlice'

const OriginationOffer = () => {
  const { pageLoading, offer, interestedInBuying } = useSelector((state) => state.origination)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = location.search
  const { companyUrlName, shareClass, shares, optionsStrikePrice } =
      qs.parse(query, { ignoreQueryPrefix: true })
  const [showDocuments, setShowDocuments] = useState(false)
  const [fileUploaded, setFileUploaded] = useState('')

  useEffect(() => {
    fetchOriginationOffer()
  }, [])

  useEffect(() => {
    if (fileUploaded.length > 0) {
      setShowDocuments(true)
    }
  }, [fileUploaded])

  const onDelete = () => {
    setShowDocuments(false)
  }

  const fetchOriginationOffer = async () => {
    const res = await dispatch(
      getOriginationOffer({
        companyUrlName,
        shareClass,
        shares,
        optionsStrikePrice
      })
    )
    if (res && res?.payload && res.payload?.offer?.name) {
      Mixpanel.track('View Originations Offer Confirmation Page', { 'Company Name': res.payload?.offer?.name })
    }
  }

  const submitOrder = async () => {
    const { urlName, shareClass, shares, optionsStrikePrice } = offer || {}
    const response = await dispatch(submitOriginationOffer({ urlName, shareClass, shares, optionsStrikePrice, fileUploaded }))
    history.push(`/sell/offerconfirmed/${response?.payload?.originationId}`)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Confirm order | Sell')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm order | Sell')} />
      {interestedInBuying && (
        <>
          <div className='origination-container origination-details-container unicorns-details-container'>
            <OriginationDetailHeader />
            <div className='page-container'>
              <div className='inner-container'>
                <div className='sections-container'>
                  <OriginationDetailCompanyLogo company={offer} />
                  <OriginationOfferCompanyOffer company={offer} />
                  <OriginationOfferUploadProof
                    fileUploaded={fileUploaded}
                    setFileUploaded={setFileUploaded}
                    onDelete={onDelete}
                  />
                  {showDocuments && (
                    <>
                      <OriginationOfferDocuments
                        documents={offer && offer.documents}
                      />
                      <OriginationOfferCompanyCTA submitOrder={submitOrder} />
                    </>
                  )}
                </div>
                <div className='content-separator' />
                <OriginationFooter />
              </div>
            </div>
          </div>
        </>
      )}
      {!interestedInBuying && (
        <div className='page-container'>
          <div className='inner-container'>
            <OriginationNotAvailableModal />
          </div>
        </div>
      )}
    </>
  )
}

export default OriginationOffer

import * as yup from 'yup'
import { isWhiteSpace, isURL } from 'utils'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Please enter a first name.')
    .test(
      'WhiteSpace',
      'Please enter a first name.',
      (val) => !isWhiteSpace(val)
    ).test(
      'URL',
      'Please enter a valid first name.',
      (val) => !isURL(val)
    ),
  lastName: yup
    .string()
    .required('Please enter a last name.')
    .test(
      'WhiteSpace',
      'Please enter a last name.',
      (val) => !isWhiteSpace(val)
    ).test(
      'URL',
      'Please enter a valid last name.',
      (val) => !isURL(val)
    ),
  email: yup
    .string()
    .required('Please enter an email.')
    .test(
      'WhiteSpace',
      'Please enter an email.',
      (val) => !isWhiteSpace(val)
    ),
  phone: yup
    .string()
    .required('Please enter a phone number.')
    .test(
      'validPhone',
      'Please enter a valid phone number.',
      (val) => val && isValidPhoneNumber(val)
    ),
  topic: yup
    .string()
    .required('Please select a topic.')
    .test(
      'WhiteSpace',
      'Please select a topic.',
      (val) => !isWhiteSpace(val)
    ),
  message: yup
    .string()
    .required('Please enter a message.')
    .test(
      'Please enter a message.',
      (val) => !isWhiteSpace(val)
    )
})

import * as yup from 'yup'
import { checkIfNotNumber } from 'utils'

export const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Please add amount.')
    .test(
      'ChechNumber',
      'Please add amount.',
      (val) => !checkIfNotNumber(val?.charAt(0) === '$' ? val.substring(1) : val)
    ).when('$availableWithdrawBalance', (availableWithdrawBalance, schema) => {
      if (availableWithdrawBalance) {
        return schema.test(
          'min',
          'Amount unavailable.',
          (val) => {
            const updatedValue = val.charAt(0) === '$' ? val.substring(1) : val
            return updatedValue <= availableWithdrawBalance
          })
      }
    }),
  account: yup
    .string()
    .ensure()
    .when('$selectedAccount', (selectedAccount, schema) => {
      if (!selectedAccount) {
        return schema.required('Please select an account.')
      }
    })
})

import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Wrapper, Content, PageLoading, ConditionalRender } from 'components'
import InvestDetailHeader from './partials/InvestDetailHeader'
import InvestDetailTabs from './partials/InvestDetailTabs'
import PlaceOrder from './partials/PlaceOrder'
import InvestDetailAbout from './partials/InvestDetailAbout'
import InvestDetailSummary from './partials/InvestDetailSummary'
import InvestDetailValuation from './partials/InvestDetailValuation'
import UnicornMosaicModal from '../UnicornDetails/partials/UnicornMosaicModal'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getSliderValue, Mixpanel, MOBILE_WIDTH_SIZE, formatNumberWithLetter, Fullstory } from 'utils'
import { getInvestDetail, setLinqtoImpliedValuation, resetProductDetail } from 'slices/investSlice'
import { useLocation } from 'react-router-dom'
import InvestDetailSellButton from './partials/InvestDetailSellButton'
import useWindowSize from 'hooks/useWindowSize'
import CompleteProfileBanner from '../Global/CompleteProfileBanner'
import useScrollEndHeight from 'hooks/useScrollEndHeight'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import LinqtoCapitalPlacementCopy from 'components/Global/LinqtoCapitalPlacementCopy'
import SuitabilityQuestionsModal from 'components/ConfrimOrder/SuitabilityQuestionsModal'

const InvestDetail = ({ match }) => {
  const location = useLocation()
  const [showMosaicModal, setMosaicShowModal] = useState(false)
  const [showQuestionsModal, setShowQuestionsModal] = useState(false)
  const [sharePrice, setSharePrice] = useState(0)
  const [investAmount, setInvestAmount] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [shares, setShares] = useState(0)
  const [totalTillNextTier, setTotalTillNextTear] = useState(0)
  const [totalSavings, setTotalSavings] = useState(0)
  const [isPreferred, setIsPreferred] = useState(false)
  const [showContentCard, setShowContentCard] = useState(null)
  const [tiers, setTiers] = useState([])
  let pageTitle = ''
  const [stickyHeader, setStickyHeader] = useState(false)
  const dispatch = useDispatch()
  const { details, pageLoading, isFinraUser, isFinraNotConfirmedUser } = useSelector(state => state.investSlice)
  const { investDetailContentCards } = useSelector(state => state.commonSlice)
  const { isProfileComplete, isAccredited } = details?.user || {}
  const [discountedAmount, setDiscountedAmount] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const { width } = useWindowSize()
  const { impliedValuation, impliedValuationSharePrice, whyInvestVideoUrl, aboutVideoUrl, name } = details || {}
  const heightRef = useScrollEndHeight()

  useEffect(() => {
    // Sets the pathname to local storage to handle redirect
    Cookies.set('redirectPath', location.pathname)
    const companyId = match.params.id
    fetchProductDetails(companyId)
    return () => {
      dispatch(resetProductDetail())
    }
  }, [])

  useEffect(() => {
    if (investDetailContentCards && investDetailContentCards?.length && name) {
      const filteredContentCards = investDetailContentCards?.filter(card => card?.name === name)
      if (filteredContentCards && filteredContentCards?.length) {
        setShowContentCard(filteredContentCards.map(({ card }) => card))
      }
    }
  }, [investDetailContentCards, name])

  // handle mixpanel tracking with iframes and initial scroll
  useEffect(() => {
    window.addEventListener('blur', onWindowBlur)
    return () => {
      window.removeEventListener('blur', onWindowBlur)
      if (heightRef?.current > 0) {
        Mixpanel.track('Scroll on Product Page', { 'Company Name': name, 'Amount scrolled': `${heightRef?.current}%` })
        Fullstory.track('Scroll', { name: 'Company', company_name: name, amount_scrolled: `${heightRef?.current}%` })
      }
    }
  }, [details])

  const onWindowBlur = () => {
    if (document.activeElement.id === 'whyInvestVideoUrlIFrame') {
      Mixpanel.track('Click Why Invest? Video on Product Page', { 'Company Name': name, URL: whyInvestVideoUrl })
    } else if (document.activeElement.id === 'aboutVideoIFrame') {
      Mixpanel.track('Click About Video on Product Page', { 'Company Name': name, URL: aboutVideoUrl })
    }
  }

  // NOTE: detect share price change and update linqto implied valuation
  useEffect(() => {
    let valuationPrice
    if (impliedValuationSharePrice && sharePrice && impliedValuation) {
      valuationPrice = formatNumberWithLetter(((sharePrice / impliedValuationSharePrice) * impliedValuation), true, true, 1, true)
    } else {
      valuationPrice = impliedValuation ? formatNumberWithLetter(impliedValuation, true, true, 1, true) : 'TBD'
    }
    dispatch(setLinqtoImpliedValuation(valuationPrice))
  }, [sharePrice])

  // Set Page Title on Mount
  useEffect(() => {
    if (details) {
      pageTitle = (details.pageTitle && details.pageTitle !== '') ? details.pageTitle : `${details.name} | Invest in ${details.name} with Linqto | Linqto Investing`
    }
  }, [details])

  useEffect(() => {
    const onScroll = () => {
      const tabHeight = document?.querySelector('.invest-tabs-container')?.getBoundingClientRect()
      if (tabHeight?.y <= 104) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [details])

  const fetchProductDetails = async (companyId) => {
    const response = await dispatch(getInvestDetail({ companyId }))
    Mixpanel.setPeople({ 'Is Accredited': isAccredited })
    Fullstory.setUserProperty({ is_accredited: isAccredited })
    if (response && response.payload?.details?.promoFlag === 'COMING_SOON' && response.payload?.details?.sharePrice !== '') {
      setSharePrice(response.payload.details.sharePrice)
    }
    if (response && response.company) {
      if (response.company.offers) {
        const sliderVal = getSliderValue(response.company, 0)
        setShares(sliderVal.shares)
        setInvestAmount(sliderVal.amount)
        setSharePrice(sliderVal.sharePrice)
        setTotalTillNextTear(sliderVal.totalTillNextTier)
        setTotalSavings(sliderVal.totalSavings)
        setIsPreferred(sliderVal.isPreferred)
        setTiers(sliderVal.tiers)
      }
    }
  }

  if (pageLoading) {
    return <PageLoading />
  }
  return (
    <>
      <SeoMeta title={pageTitle} description={details.pageMetaDescription} keywords={details.pageMetaKeywords} />
      <Wrapper className='invest-detail-container'>
        <div className='page-container invest-header-container'>
          <div className='inner-container'>
            {showContentCard ? (
              <div className='mt-4 messaging-carousel-container'>
                <MessagingCarousel data={showContentCard} page='Invest Detail' />
              </div>) : null}
            {(!isProfileComplete && (!isFinraUser && !isFinraNotConfirmedUser)) && <CompleteProfileBanner setShowQuestionsModal={setShowQuestionsModal} />}
            <Content className='order-group invest-details-info'>
              <InvestDetailHeader />
              <PlaceOrder
                setAmount={setInvestAmount}
                investAmount={investAmount}
                stickyHeader = {stickyHeader}
                setShares={setShares}
                shares={shares}
                setSharePrice={setSharePrice}
                sharePrice={sharePrice}
                totalTillNextTier={totalTillNextTier}
                setTotalTillNextTear={setTotalTillNextTear}
                totalSavings={totalSavings}
                setTotalSavings={setTotalSavings}
                isPreferred={isPreferred}
                setIsPreferred={setIsPreferred}
                tiers={tiers}
                setTiers={setTiers}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                discountedAmount={discountedAmount}
                setDiscountedAmount={setDiscountedAmount}
                setShowQuestionsModal={setShowQuestionsModal}
              />
            </Content>
            <InvestDetailSellButton />
            {width <= MOBILE_WIDTH_SIZE && <div className='invest-details-info__bottom-info'>
              <span className='b_16_semibold company-details__vertical gray3'>{details?.vertical}</span>
              <div><span className='b_16_semibold gray3'>Founded:</span> <span className='b_16_regular gray3'>{details?.founded}</span></div>
              <div><span className='b_16_semibold gray3'>Headquarters:</span> <span className='b_16_regular gray3'>{details?.headquarters?.city}, {details?.headquarters?.state}</span></div>
              <a target='_blank' rel='noopener noreferrer' href={`https://${details?.website}`} className='b_16_semibold gray3'>{details?.website}</a>
            </div>}
          </div>
        </div>
        <div className={`page-container invest-tabs-container ${details?.isSoldOut ? 'fully-subscribed-tabs-container' : ''} ${stickyHeader ? 'sticky-padding' : ''}`}>
          <div className='inner-container'>
            <InvestDetailTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} stickyHeader={stickyHeader} setStickyHeader={setStickyHeader}/>
            <InvestDetailAbout />
            <InvestDetailSummary setMosaicShowModal={setMosaicShowModal}/>
            <InvestDetailValuation sharePrice={sharePrice}/>
          </div>
        </div>
        <ConditionalRender isVisible={showMosaicModal}>
          <UnicornMosaicModal setShowModal={setMosaicShowModal} />
        </ConditionalRender>
        {showQuestionsModal && 
          <SuitabilityQuestionsModal hideModal={(close) => {
            setShowQuestionsModal(false)
            // If questions complete, refresh the details page
            if (close === 'complete') {
              fetchProductDetails(match.params.id)
            }
          }} />}
      </Wrapper>
      <LinqtoCapitalPlacementCopy />
    </>
  )
}

export default InvestDetail

import USDocumentUpload from './USDocumentUpload'
import { USDoceumentTypes } from '../../data'

const USIndividualIncomeSection = () => {
  const intialDocsToUpload = [
    { id: 1, name: 'My Income Last Year' },
    { id: 2, name: 'My Income Previous Year' },
    { id: 3, name: 'Spouse Income Last Year' },
    { id: 4, name: 'Spouse Income Previous Year' }
  ]

  return (
    <USDocumentUpload
      dropDownList={USDoceumentTypes.JOINT_INCOME}
      intialDocsToUpload={intialDocsToUpload}
    />
  )
}

export default USIndividualIncomeSection

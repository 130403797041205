import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { Mixpanel } from 'utils'
import { Button } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPlaidIncomeToken,
  getPlaidIncomeAccreditaiton,
  getInvestorStatus
} from 'slices/investorStatusSlice'
import { useLDFlags } from 'utils/LaunchDarkly'

const PlaidIncomeLinkButton = ({ disabled }) => {
  const { plaidAccreditation } = useLDFlags(['plaidAccreditation'])
  const dispatch = useDispatch()
  const { loading, plaidIncomeToken } = useSelector((state) => state.investorStatusSlice)
  useEffect(() => {
    if (plaidAccreditation) {
      dispatch(getPlaidIncomeToken())
    }
  }, [])

  const onLinkSuccess = async () => {
    const { payload } = await dispatch(getPlaidIncomeAccreditaiton())
    if (!payload) {
      Mixpanel.track('Onboarding Failed Accreditation', { 'Accredited Status': '$200k Individual Income for 2 Years', 'Accreditaiton Method': 'Plaid' })
    }
    dispatch(getInvestorStatus('noRefresh'))
  }

  const onExit = async (error) => {
    console.log('Plaid error', error)
  }
  const onEvent = async (eventName, metadata) => {
    if (eventName === 'ERROR') {
      Mixpanel.track('Accreditation Plaid Income Event Error', { Error: metadata.error_code })
    } else {
      Mixpanel.track(`Accreditation Plaid Income Event: ${eventName}`, { 'Link Step': metadata.view_name })
    }
  }
  // Plaid configurations
  const config = {
    onSuccess: onLinkSuccess,
    onExit,
    onEvent,
    token: plaidIncomeToken
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('triggerPlaidIncome')
    } else {
      open()
    }
  }
  if (!plaidAccreditation) {
    return null
  }
  return (
    <Button loading={loading} disabled={disabled || loading || !plaidIncomeToken} onClick={connectPlaid}>
        Submit
    </Button>
  )
}

export default PlaidIncomeLinkButton

import Button from 'components/Global/Button'
import TextInput from 'components/Global/Inputs/TextInput'
import { useFormContext } from 'react-hook-form'

const tooltip = {
  brokerAccountName:
    'The name on your broker account must match the name on your Linqto account or the name of the entity you invest with on Linqto.',
  brokerDTC:
    'Usually the 4 digit DTC# is unique to your broker and can be found on your broker’s website, or they can provide for you upon request.'
}

const BrokerInfoForm = ({ onSubmit, loading }) => {
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful }
  } = useFormContext()

  const handleKeyPress = e => {
    if ((e.charCode < 48 || e.charCode > 57) && e.key !== 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid'>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='accountName'
            label='Name on Brokerage Account'
            content={tooltip.brokerAccountName}
            disabled={loading}
            ariaLabel='accountName'
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='firmName'
            label='Equity Brokerage Firm Name'
            disabled={loading}
            ariaLabel='firmName'
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='accountNumber'
            label='Brokerage Account #'
            disabled={loading}
            ariaLabel='accountNumber'
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='confirmAccountNumber'
            label='Confirm Account #'
            disabled={loading}
            ariaLabel='confirmAccountNumber'
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='dtc'
            label='Equity Brokerage 4-Digit DTC#'
            content={tooltip.brokerDTC}
            handleKeyPress={handleKeyPress}
            disabled={loading}
            maxLength={4}
            ariaLabel='dtc'
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <TextInput
            name='specialInstructions'
            label='Special Instructions: (optional)'
            disabled={loading}
          />
        </div>
        <div className='column eight sixteen-tablet sixteen-mobile'>
          <div className='btn-group'>
            <Button
              type='submit'
              loading={loading}
              customClass='full-width'
              disabled={loading || !isDirty || isSubmitSuccessful}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default BrokerInfoForm

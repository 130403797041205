import Modal from 'components/Global/Modal'
import ReactHtmlParser from 'react-html-parser'

const AccreditationModal = ({ hideModal, accreditationTerms }) => {
  return (
    <Modal innerStyle='mfa-modal' modalHeader='' hideModal={() => hideModal()} crossToClose>
      {accreditationTerms && ReactHtmlParser(accreditationTerms)}
    </Modal>
  )
}

export default AccreditationModal

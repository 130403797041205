import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE, Mixpanel } from 'utils'

const InvestDetailSummaryFacts = ({ facts, title, icon }) => {
  const [style, setStyle] = useState('')
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMoreLink, setReadMoreLink] = useState('Read More')
  const { width } = useWindowSize()
  const { details } = useSelector(state => state.investSlice)
  const { name } = details || {}

  const containerRef = useRef()
  const maxHeight = width <= MOBILE_WIDTH_SIZE ? 195 : 215
  useEffect(() => {
    if (containerRef.current) {
      if (containerRef.current.offsetHeight > maxHeight) {
        setStyle('maxHeight')
        setShowReadMore(true)
      }
    }
  }, [])

  const setDescription = () => {
    Mixpanel.track('Click Summary Read More on Product Page', { 'Company Name': name })
    if (readMoreLink === 'Read More') {
      setStyle('')
      setReadMoreLink('Read Less')
    } else {
      setStyle('maxHeight')
      setReadMoreLink('Read More')
    }
  }

  return (
    <div className='fact-container'>
      <img className='icon' alt={title} src={images[icon]} />
      <div className='b_18_semibold fact-title'>{title}</div>
      <div className={`fact-items ${style}`} ref={containerRef}>
        {facts && facts.map((p, i) =>
          <div key={`fact${i}`} className='b_16_regular gray3 fact-item'>
            {p.fact}
          </div>
        )}
      </div>
      {showReadMore && <a onClick={() => setDescription()} aria-label='Read More' role='button'>{readMoreLink}</a>}
    </div>
  )
}
export default InvestDetailSummaryFacts

import * as yup from 'yup'

const regex = /\d+/g

export const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter a new password.')
    .test(
      'len',
      'Password must be at least 8 characters long.',
      (val) => val.length > 7
    )
    .test('containsNum', 'Password must contain at least one number.', (val) =>
      val?.match(regex)
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password.')
    .oneOf(
      [yup.ref('password'), null],
      'Confirm password should match the new password above.'
    )
})

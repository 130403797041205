import * as yup from 'yup'
import { isWhiteSpace, isValidEmail, isURL } from 'utils'

const regex = /\d+/g

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .required(
      'First name missing'
    )
    .test(
      'WhiteSpace',
      'First name missing',
      (val) => !isWhiteSpace(val)
    )
    .test(
      'URL',
      'First name invalid',
      (val) => !isURL(val)
    ),
  lastName: yup
    .string()
    .required(
      'Last name missing'
    ).test(
      'WhiteSpace',
      'Last name missing',
      (val) => !isWhiteSpace(val)
    ).test(
      'URL',
      'Last name invalid',
      (val) => !isURL(val)
    ),
  email: yup
    .string()
    .required(
      'Email missing'
    ).test('validEmail', 'Email invalid', (val) =>
      isValidEmail(val)
    ),
  password: yup
    .string()
    .required(
      'Password missing'
    ).test(
      'len',
      'Password must be at least 8 characters long.',
      (val) => val?.length > 7
    ).test('containsNum', 'Password must contain at least one number.', (val) =>
      val?.match(regex)
    )
})

import { images } from 'assets'
import { seoTitleTemplate, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const NotFound = () => {
  return (
    <>
      <SeoMeta title={seoTitleTemplate('404 Not Found')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='flex-col items-center'>
            <h1>We&apos;re sorry, this page does not exist.</h1>
            <div className='flex items-center w-[100vw]'>
              <div className='border-b-2 w-full'></div>
              <img className='my-3 mx-2' alt='404 page not found' src={images['404']} height={45} width={40} />
              <div className='border-b-2 w-full'></div>
            </div>
            <div className='w-full max-w-[900px]'>
              <p className='text-gray-400 my-2 block'>Let&apos;s get you back to investing.</p>
              <a className='btn secondary lg' href={getDomain('/', true)}>Home</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound

import { useHistory } from 'react-router-dom'
import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

const SuccessModal = () => {
  const history = useHistory()

  return (
    <Modal
      crossToClose
      modalHeader=''
      innerStyle='success-modal'
      hideModal={() => history.push('/settings')}
    >
      <div className='b_18_regular'>
        Your request to delete has been sent to our team and they will respond
        within 2 business days.
      </div>
      <div className='btn-group centered'>
        <Button onClick={() => history.push('/settings')}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

export default SuccessModal

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { images } from 'assets'
import { Button } from 'components'

const Modal = ({ innerStyle, children, modalHeader, hideModal, clickToClose, pressToClose, crossToClose, outerStyle = '' }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27 && pressToClose) {
        hideModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [hideModal, pressToClose])

  useEffect(() => {
    // make sure document is not undefined
    if (typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = 'hidden'
      const scroll = document.querySelector('body').style.overflow = 'scroll'
      return () => scroll
    }
  }, [children])

  return (
    <div
      className={`dimmer-2 ${outerStyle}`}
      onClick={clickToClose && hideModal}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        className={`modal ${innerStyle}`}
      >
        {modalHeader && <div className='title-group'>
          <div className='heading_3'>{modalHeader}</div>
        </div>}
        {crossToClose && <Button className='close-icon pointer' onClick={hideModal} aria-label='Close Modal'><img alt='Close Modal' data-testid='close-icon' src={images['modal-close']} /></Button>}
        <div className='modal-inner-content'>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  clickToClose: PropTypes.bool,
  crossToClose: PropTypes.bool,
  pressToClose: PropTypes.bool
}

export default Modal

import { useEffect } from 'react'
import { PageLoading } from 'components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Home = () => {
  const history = useHistory()
  const {
    isSignedIn,
    isUserReturned
  } = useSelector((state) => state.userSlice)

  useEffect(() => {
    if (isUserReturned) {
      if (isSignedIn) {
        history.push('/products')
      } else {
        history.push('/signin')
      }
    }
  }, [isUserReturned])

  return <PageLoading />
}

export default Home

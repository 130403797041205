import { useSelector } from 'react-redux'
import { formatDecimal, formatWholeNumber, getDomain, renderTotalsOrDash } from 'utils'
import Toggle from 'components/Global/Toggle'
import { images } from 'assets'
import TooltipLabel from 'components/Global/TooltipLabel'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CustomSelect from 'components/Global/Inputs/CustomSelect'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { availableSharesToSellTooltip, transactionFeeCopy } from 'components/data'
import { Tooltip, ConditionalRender, Button } from 'components'

const Payment = ({
  selectedAccount,
  linqtoBucksEnabled,
  promoCode,
  promoCodeError,
  purchaseCreditsEnabled,
  purchaseWithShares,
  purchaseWithCash,
  toggleCash,
  handlePurchaseAsChange,
  toggleShares,
  onDropDownChange,
  togglePurchaseCredits,
  toggleLinqtoBucks,
  applyPromoCode,
  removePromoCode,
  onPromoCodeChange,
  selectedOptions
}) => {
  const { accounts, totalPurchaseCredits, maxOrderLinqtoBucksPercent, linqtoBucks, order, confirmOrderLoading } = useSelector((state) => state.placeOrderSlice)
  const {
    amount, linqtoFee, linqtoBucksUsed, promotionalCredit, total,
    discountAmount, discountPercent, commitLoading, creditsUsed, isPromoCodeValid, sellAmount
  } = order || {}
  const { featureFlags: { PurchaseCreditEnabled, PurchaseWithSharesAllowed } } = useSelector(state => state.userSlice)
  const { control, getValues } = useFormContext()
  const [showHover, setShowHover] = useState(false)
  const [showHoverFee, setShowHoverFee] = useState(false)
  const promoCodeDisabled = linqtoBucksUsed > 0

  const accountLabel = getValues('sellCompanyId') ? JSON.parse(getValues('sellCompanyId'))?.label : null
  const sellCompanies = selectedAccount?.sellCompanies?.map(s => ({
    value: s.companyId,
    label: s.name,
    icon: s.iconUrl,
    numberOfShares: s.numberOfshares,
    pricePerShare: s.pricePerShare,
    customLabel: `${formatDecimal(s.totalSharePrice)} (${formatWholeNumber(s.numberOfshares)} Shares)`
  })) || []

  return (
    <div className='buy-order-section wih-margin top-aligned'>
      <div className='heading_7 buy-order-section-title'>
        <span>2</span> Payment
      </div>
      <div className='buy-order-section-detail'>
        <span className='b_18_semibold'>Apply Discounts</span>
        <div className='box invest-order-box payment-invest-order-box'>
          <ConditionalRender isVisible={PurchaseCreditEnabled && totalPurchaseCredits > 0}>
            <div className='linqto-bucks-row'>
              <div className='buy-order-row'>
                <div className='linqto-bucks-wrapper'>
                  <div className='linqto-img'>
                    <img src={images['wallet-purchase-credit-account']} alt='Purchase Credits' />
                  </div>
                  <div className={`linqto-copy ${purchaseWithShares ? 'linqto-copy-disabled' : ''}`}>
                    <TooltipLabel
                      content='Purchase Credits are valid for 30 days and can be combined with Linqto Bucks and other offers.'
                      label='Purchase Credits'
                      customClass='b_18_semibold' />
                    <span className='no-padding'>{formatDecimal(totalPurchaseCredits)}</span>
                    {purchaseWithShares && <span className='b_16_regular gray3 no-padding'>Cannot be combined when paying with Shares</span>}
                  </div>
                </div>
                <Toggle handleChange={togglePurchaseCredits} checked={purchaseCreditsEnabled} ariaLabel='puchaseCreditsbToggleSwitch' disabled={purchaseWithShares} />
              </div>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={linqtoBucks > 0 && maxOrderLinqtoBucksPercent > 0}>
            <div className='linqto-bucks-row'>
              <div className='buy-order-row'>
                <div className='linqto-bucks-wrapper'>
                  <div className='linqto-img'>
                    <img src={images['bucks-icon']} alt='Linqto Bucks' />
                  </div>
                  <div className='linqto-copy'>
                    <span className='b_18_semibold no-padding'>Linqto Bucks</span>
                    <span className='no-padding'>{formatDecimal(linqtoBucks)}</span>
                  </div>
                </div>
                <Toggle handleChange={toggleLinqtoBucks} checked={linqtoBucksEnabled} ariaLabel='lqbToggleSwitch' />
              </div>
              {linqtoBucksEnabled && <div className='linqto-bucks-terms b_16_regular'>
                {linqtoBucks < (maxOrderLinqtoBucksPercent * amount) && 'Linqto Bucks cannot be combined with other promotions. '}
                {linqtoBucks >= (maxOrderLinqtoBucksPercent * amount) && `Linqto Bucks can be used for up to ${maxOrderLinqtoBucksPercent * 100}% of the order and cannot be combined with other promotions. `}
                By using Linqto Bucks, I have read and agree to be bound by the <a className='inline-text-link b_16_regular' href={getDomain('/reward-terms-of-use', true)} target='_blank' rel='noreferrer'>Linqto Bucks Terms and Conditions.</a>
              </div>}
              {discountAmount > 0 && <div className='linqto-bucks-terms b_16_regular'>Linqto Bucks cannot be combined with other promotions.</div>}
            </div>
          </ConditionalRender>
          <div className='linqto-bucks-row'>
            {(() => {
              if (!isPromoCodeValid) {
                return (
                  <div className={`input-group promo-input-wrapper ${promoCodeDisabled || confirmOrderLoading ? 'disabled' : ''} ${promoCodeError ? 'error' : ''}`}>
                    <label>Promo Code</label>
                    <input
                      disabled={promoCodeDisabled || confirmOrderLoading}
                      type='text'
                      aria-label='promoCodeInput'
                      className='promo-input'
                      value={promoCode}
                      onChange={onPromoCodeChange}
                    />
                    <Button className='apply-btn b_18_semibold' onClick={applyPromoCode} ariaLabel='Apply Promo Code'>Apply</Button>
                    {(promoCodeError) && <ErrorMessage message='Promo code not found' />}
                    {promoCodeDisabled && <span>To enter a promo code, please turn off Linqto Bucks.</span>}
                  </div>
                )
              } else {
                return (
                  <div className='input-group promo-input-wrapper promo-input-success'>
                    <label>Promo Code</label>
                    <input
                      disabled={true}
                      type='text'
                      aria-label=''
                      className={`promo-input ${confirmOrderLoading ? 'promo-input-disabled' : ''}`}
                    />
                    <span className='promo-name-wrapper b_16_semibold'>{promoCode} <Button onClick={removePromoCode} ariaLabel='Remove Promo Code'><img src={images['promo-close']} alt='Remove Promo Code' /></Button></span>
                    <span className='promo-applied-wrapper b_18_semibold'>Applied <img src={images['promo-check']} alt='Apply Promo Code'/></span>
                  </div>
                )
              }
            })()}
          </div>
        </div>
        <span className='b_18_semibold'>Pay With</span>
        <div className='box invest-order-box payment-invest-order-box__with-share-dropdown'>
          {accounts?.length > 1 &&
            <div className='buy-order-row'>
              <span className='select-input'>
                <span className='b_16_regular'>Transact as</span>
                <select aria-label='purchase-as' onChange={handlePurchaseAsChange} disabled={commitLoading}>
                  {accounts?.length > 0 && accounts.map((e, i) => <option value={i} key={i}>{e.accountName}</option>)}
                </select>
              </span>
            </div>}
          <ConditionalRender isVisible={PurchaseWithSharesAllowed && selectedAccount?.sellCompanies?.length > 0}>
            <div className='buy-order-row'>
              <div className='linqto-bucks-wrapper'>
                <div className='linqto-img'>
                  <img src={images['shares-icon']} alt='Pay with Shares' />
                </div>
                <span className='b_18_semibold'>Shares</span>
              </div>
              <Toggle handleChange={toggleShares} checked={purchaseWithShares} ariaLabel='payWithSharesToggleSwitch' />
            </div>
            {purchaseWithShares &&
              <Controller
                control={control}
                name='sellCompanyIdControl'
                render={({ field }) => (
                  <CustomSelect
                    name='sellCompanyId'
                    ariaLabel='sellCompanyId-input'
                    field={field}
                    label='Shares available to sell'
                    placeholder='No Company Holdings Selected'
                    extraLabel=' '
                    selectedOptions={selectedOptions}
                    optionsList={sellCompanies}
                    onChange={onDropDownChange}
                    className='shares-drop-down'
                    content={availableSharesToSellTooltip}
                  />
                )}
              />}
            <ConditionalRender isVisible={selectedOptions?.length === 1} className='shares-sell-data'>
              <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Estimated Shares To Be Sold:</div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.sellShares, formatWholeNumber(order?.sellShares))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Remaining Shares:</div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.remainingUnsoldShares, formatWholeNumber(order?.remainingUnsoldShares))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Estimated Share Price:</div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.sellSharePrice, formatDecimal(order?.sellSharePrice))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Cost Basis of Shares:</div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.totalOriginalHeldSharePrice, formatDecimal(order?.totalOriginalHeldSharePrice))}</div>
              </div>
              {order?.sellTransactionFee > 0 && <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Transaction Fee:<Tooltip content={transactionFeeCopy(order.sellTransactionFeeRate)} showHover={showHoverFee} setShowHover={setShowHoverFee} /></div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.sellTransactionFee, formatDecimal(order?.sellTransactionFee * -1))}</div>
              </div>}
              <div className='buy-order-row'>
                <div className='b_16_regular gray4'>Your Projected Gain:<Tooltip content='These gains/losses are estimated. Consult a tax advisor for potential tax implications.' showHover={showHover} setShowHover={setShowHover} /></div>
                <div className='b_16_regular gray4'>{renderTotalsOrDash(order?.projectedGain, formatDecimal(order?.projectedGain) || '-')}</div>
              </div>
            </ConditionalRender>
            <div className='line-separator space-above-sm' />
          </ConditionalRender>
          <div className='linqto-bucks-row'>
            <div className='buy-order-row'>
              <div className='linqto-bucks-wrapper'>
                <div className='linqto-img'>
                  <img src={images['cash-icon-dollar']} alt='Cash Balance' />
                </div>
                <div className='linqto-copy'>
                  <span className='b_18_semibold no-padding'>Cash Balance</span>
                  <span className='no-padding'>{formatDecimal(selectedAccount?.amountAvailable)}</span>
                  {PurchaseWithSharesAllowed && <span className='b_14_regular no-padding'>Covers balance after selling shares and any discounts applied. </span>}
                </div>
              </div>
              {PurchaseWithSharesAllowed && <Toggle handleChange={toggleCash} checked={purchaseWithCash} ariaLabel='cashBalanceToggleSwitch' />}
            </div>
          </div>
        </div>
        <div className='buy-order-row'>
          <span>Investment Amount</span>
          <span>{formatDecimal(amount)}</span>
        </div>
        {sellAmount > 0 && accountLabel && <div className='buy-order-row'>
          <span>Shares to Sell: {accountLabel}</span>
          <span>-{formatDecimal(sellAmount)}</span>
        </div>
        }
        {creditsUsed > 0 && <div className='buy-order-row'>
          <span>Purchase Credits</span>
          <span>-{formatDecimal(creditsUsed)}</span>
        </div>
        }
        {discountAmount > 0 && <div className='buy-order-row'>
          <span>Promo Discount</span>
          {discountPercent > 0 && <div className='promo'>-{formatDecimal(discountPercent * 100, false, 0)}% ({formatDecimal(discountAmount)})</div>}
          {(!discountPercent && discountAmount > 0) && <div className='promo'>-{formatDecimal(discountAmount)}</div>}
        </div>
        }
        <div className='buy-order-row'>
          <span>Linqto Fee</span>
          <span>{formatDecimal(linqtoFee)}</span>
        </div>
        {linqtoBucksUsed > 0 && <div className='buy-order-row'>
          <span>Linqto Bucks</span>
          <span> -{formatDecimal(linqtoBucksUsed)}</span>
        </div>
        }
        {promotionalCredit > 0 && <div className='buy-order-row'>
          <span>Promotional Credit</span>
          <span> -{formatDecimal(promotionalCredit)}</span>
        </div>
        }
        <div className='buy-order-row b_18_semibold'>
          <span>Total Amount Due</span>
          <span>{formatDecimal(total < 0 ? 0 : total)}</span>
        </div>
        {total < 0 &&
          <div className='buy-order-row'>
            <span>Credit to Cash Account</span>
            <span>{formatDecimal(total * -1)}</span>
          </div>
        }
      </div>
    </div>
  )
}

export default Payment

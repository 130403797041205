import { Modal } from 'components'
import Button from '../../Global/Button'

const OriginationMultipleOffersModal = ({ hideModal }) => {
  return (
    <Modal
      innerStyle='origination-modal'
      modalHeader=''
      clickToClose
      pressToClose
      crossToClose
      hideModal={hideModal}
    >
      <div className='b_18_regular content'>
        You&apos;ve already submitted an offer to sell your shares. If you have any
        questions, please reach out to  <a className='link primary' href='mailto:investments@linqto.com'>investments@linqto.com</a>
      </div>
      <div className='btn-group centered'>
        <Button onClick={hideModal}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

export default OriginationMultipleOffersModal

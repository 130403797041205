import React, { useState } from 'react'
import Tooltip from './Tooltip'

const TooltipLabel = ({ content, label, extraLabel = '', customClass = '' }) => {
  const [showHover, setShowHover] = useState(false)
  return (
    <label>
      {content !== '' ? (
        <div style={{ cursor: 'pointer' }}>
          <span
            className={customClass || 'b_16_regular'}
            onMouseEnter={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
          >
            {label}
          </span>
          <Tooltip
            content={content}
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </div>
      ) : (
        <div className='b_16_regular'>
          {label}{extraLabel && <span className='b_16_regular gray3 optional'>{extraLabel}</span>}
        </div>
      )}

    </label>
  )
}

export default TooltipLabel

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  unsubscribe
} from 'slices/commonSlice'
import { PageLoading } from 'components'
import { useHistory } from 'react-router-dom'
const Unsubscribe = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pageLoading } = useSelector(state => state.commonSlice)
  useEffect(() => {
    if (window?.location?.search?.length > 0) {
      dispatch(unsubscribe({ query: window.location.search }))
    } else {
      history.push('not-found')
    }
  }, [])

  if (pageLoading) {
    return <PageLoading />
  }
  return (
    <div className='page-container'>
      <div className='inner-container'>
        <h3>Successfully Unsubscribed.</h3>
      </div>
    </div>
  )
}

export default Unsubscribe

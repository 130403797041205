import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetail } from 'slices/orderSlice'
import { OrderSection, PageLoading } from 'components'
import { Fullstory, Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import Breadcrumb from 'components/Global/Breadcrumb'
import { Link } from 'react-router-dom'

const OrderDetail = ({ match }) => {
  const [items, setItems] = useState([])

  const dispatch = useDispatch()
  const { pageLoading, company, order, documents } = useSelector(
    (state) => state.order
  )

  useEffect(() => {
    (async () => {
      const res = await dispatch(getOrderDetail(match.params.id))
      const { meta, payload } = res
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Order Detail Page')
        Fullstory.track('Page View', {
          page_name: 'Order Detail Page',
          company_name: payload?.company?.name
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (company?.name) {
      setItems([
        { name: 'My Portfolio', route: '/portfolio' },
        { name: company.name, route: `/portfolio/${company.urlName}` }
      ])
    }
  }, [company])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Detail')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Detail')} />
      <div className='page-container order-detail-container page-with-breadcrumb'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          {company && (
            <OrderSection
              company={company}
              documents={documents}
              order={order}
              orderId={order?.orderId}
              status={order?.status}
            />
          )}
          <div className='b_18_regular space-above-sm'>
          Have a question about your order?{' '}
            <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Buy Order Details' })} to={`/contact?topic=Order Support&message=Re: Buy Order ID ${order?.orderId}`} className='link primary contact-us' role='button' tabIndex={0}>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderDetail

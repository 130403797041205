import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'

const initialState = {
  allowedImpersonations: [],
  masterUser: '',
  riaReferralUrl: '',
  riaFirmName: '',
  confirmationToken: '',
  relationshipStatus: '',
  riaNameFirst: '',
  riaNameLast: '',
  userIsRia: false,
  pageLoading: false,
  submitLoading: false,
  accreditationCountry: '',
  clientFirstName: '',
  clientLastName: '',
  isCountryRegulated: false,
  clientUserId: ''
}

export const getRiaUsersList = createAsyncThunk(
  'getRiaUsersList',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/impersonation')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getUserAccreditation = createAsyncThunk(
  'getUserAccreditation',
  (clientUserId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/ria/accreditation/${clientUserId}`)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const impersonateUser = createAsyncThunk(
  'impersonateUser',
  (impersonationUserId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post(`/page/impersonation/${impersonationUserId}`)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const submitAttestation = createAsyncThunk(
  'submitAttestation',
  (body, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/page/ria/accreditation', { ...body })
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getRiaInfo = createAsyncThunk(
  'getRiaInfo',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/ria')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const connectWealthAdvisor = createAsyncThunk(
  'connectWealthAdvisor',
  (
    { confirmationToken, approve },
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    return api
      .post(`/ria?approve=${approve}&confirmationToken=${confirmationToken}`)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const riaSlice = createSlice({
  name: 'riaSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRiaUsersList.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getRiaUsersList.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getRiaUsersList.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getRiaInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(connectWealthAdvisor.fulfilled, (state, { payload }) => {
        return {
          ...state,
          submitLoading: false,
          ...payload
        }
      })
      .addCase(connectWealthAdvisor.rejected, (state) => {
        state.submitLoading = false
      })
      .addCase(connectWealthAdvisor.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(impersonateUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          submitLoading: false,
          ...payload
        }
      })
      .addCase(impersonateUser.rejected, (state) => {
        state.submitLoading = false
      })
      .addCase(impersonateUser.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(submitAttestation.fulfilled, (state, { payload }) => {
        return {
          ...state,
          submitLoading: false,
          ...payload
        }
      })
      .addCase(submitAttestation.rejected, (state) => {
        state.submitLoading = false
      })
      .addCase(submitAttestation.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(getUserAccreditation.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
  }
})

export default riaSlice.reducer

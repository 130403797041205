import PropTypes from 'prop-types'

/**
 * Renders a component or passed children based on isVisible prop
 * @function ConditionalRender
 * @returns passed component or children
 */
const ConditionalRender = ({ isVisible, children, Component, ...props }) => {
  if (!isVisible) return null
  else if (Component) return <Component { ...props}>{children}</Component>
  else return <div {...props}>{children}</div>
}

ConditionalRender.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  Component: PropTypes.func,
  children: PropTypes.node
}

export default ConditionalRender

import AddFundsModalItem from './AddFundsModalItem'
import { images } from 'assets'
import { useDispatch } from 'react-redux'
import { addFundsToLinqtoWallet } from 'slices/walletSlice'
import { Mixpanel } from 'utils'

const WireFundingMethod = ({
  setSelectedAccount,
  hideModal,
  setShowWireInstruction,
  entityId
}) => {
  const dispatch = useDispatch()

  const getWireInstructions = () => {
    dispatch(addFundsToLinqtoWallet(entityId)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        setSelectedAccount({
          label: 'Wire Transfer',
          iconUrl: images['wallet-wire'],
          method: 'Wire Transfer'
        })
        Mixpanel.track('Select Funding Method', { 'Funding Method': 'Wire Transfer' })
        hideModal()
        setShowWireInstruction(true)
      }
    })
  }

  return (
    <AddFundsModalItem
      onSelect={getWireInstructions}
      account={{
        label: 'Wire Transfer',
        iconUrl: images['wallet-wire'],
        description: 'Funds are typically available within 1 business day'
      }}
    />
  )
}

export default WireFundingMethod

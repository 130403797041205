import Button from '../../Global/Button'
import { Mixpanel } from 'utils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AccreditedUserSection = () => {
  const history = useHistory()
  const { cashPaymentEnabled } = useSelector((state) => state.userSlice)

  const redirectToInvest = () => {
    // change action if user is on web or app/webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      Mixpanel.track('Onboarding Click Start Investing on Investor Status Page')
      history.push('/products')
    }
  }

  const redirectToCashAccount = () => {
    // change action if user is on web or app/webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToCashAccount')
    } else {
      Mixpanel.track('Onboarding Click Add Funds on Investor Status Page')
      history.push('/cash-account')
    }
  }

  return (
    <div className='accredited-container'>
      {cashPaymentEnabled ? (
        <>
          <div className='b_18_regular header-content'>
            Congratulations! You’ve verified your accreditation status. You’re one step away from being able to invest!
          </div>
          <Button onClick={() => redirectToCashAccount()}>
            Add Funds
          </Button>
        </>
      ) : (
        <>
          <div className='b_18_regular header-content'>
            You’ve verified your accreditation status and you’re ready to invest!
          </div>
          <Button onClick={() => redirectToInvest()}>
            Start Investing
          </Button>
        </>
      )}
    </div>
  )
}

export default AccreditedUserSection

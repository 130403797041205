import * as yup from 'yup'

export const entityDetailsSchema = yup.object().shape({
  investorOption: yup.string().required('Please enter your investor option.'),
  entityName: yup.string().required('Please enter your entity name.'),
  title: yup.string()
    .when('investorOption', {
      is: (option) => option !== 'SELF_DIRECTED_IRA',
      then: (schema) => schema.required('Please enter your title.')
    }),
  entityDisplayType: yup.string()
    .when('investorOption', {
      is: (option) => option === 'ENTERPRISE',
      then: (schema) => schema.required('Please select a enterprise type.')
    })
    .when('investorOption', {
      is: (option) => option === 'TRUST',
      then: (schema) => schema.required('Please select a trust type.')
    })
    .when('investorOption', {
      is: (option) => option === 'SELF_DIRECTED_IRA',
      then: (schema) => schema.required('Please select a IRA type.')
    }),
  entityTaxId: yup.string()
    .when('investorOption', {
      is: (option) => option === 'SELF_DIRECTED_IRA',
      then: (schema) => schema.required('Please enter the IRA Provider’s tax ID.')
    })
    .when('investorOption', {
      is: (option) => option !== 'SELF_DIRECTED_IRA',
      then: (schema) => schema.required('Please enter your entity tax ID.')
    })
})

export const entityAddressSchema = yup.object().shape({
  street1: yup.string().required('Please enter a street address.'),
  city: yup.string().required('Please enter city.'),
  zip: yup.string().required('Please enter postal code.'),
  country: yup.string().required('Please enter country.'),
  state: yup.string().required('Please enter state or province.')
})


import useWindowSize from 'hooks/useWindowSize'
import { formatDate, MOBILE_WIDTH_SIZE } from 'utils'

const RecurringHistoryItem = ({
  transaction: { createdAt, description, status }
}) => {
  const { width } = useWindowSize()

  let rowComponent = null

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div className='nonclickable-grid-container__row cash_history'>
        <span>{status === 'PENDING' ? status : formatDate(createdAt)}</span>
        <span>{description}</span>
      </div>
    )
  } else {
    rowComponent = (
      <div className='nonclickable-grid-container__row cash_history'>
        <div className='history-grid-container__row__full-flex' >
          <span>{status === 'PENDING' ? status : formatDate(createdAt)}</span>
        </div>
        <div className='history-grid-container__row__full-flex'>
          <span>{description}</span>
        </div>
      </div>
    )
  }

  return rowComponent
}

const RecurringHistoryList = ({ recurringInvestmentHistory }) => {
  return (
    <div className='nonclickable-grid-container'>
      <div className='b_18_semibold nonclickable-grid-container__title'>History</div>
      {recurringInvestmentHistory.length === 0 && (
        <div className='nonclickable-grid-container__no-data heading_7'>No transactions posted.</div>
      )}
      {recurringInvestmentHistory.length > 0 && (
        <div className='nonclickable-grid-container__list'>
          <div className='nonclickable-grid-container__columns cash_history'>
            <span>DATE</span>
            <span>DESCRIPTION</span>
          </div>
          {recurringInvestmentHistory.map((t, i) => (
            <RecurringHistoryItem key={i} transaction={t} />
          ))}
        </div>
      )}
    </div>
  )
}

export default RecurringHistoryList

import { useSelector } from 'react-redux'
import RegionCountryDropdown from 'components/Global/Inputs/RegionCountryDropdown'
import TextInput from 'components/Global/Inputs/TextInput'
import { useFormContext } from 'react-hook-form'

const ManualVerificationAddressSection = () => {
  const { regions } = useSelector(state => state.commonSlice)
  const { formState: { isSubmitting } } = useFormContext()

  return (
    <>
      <div className='row'>
        <div className='four column eight-tablet form-header'>
          <span>Address</span>
        </div>
      </div>
      <div className='row'>
        <div className='four column eight-tablet sixteen-mobile'>
          <RegionCountryDropdown items={regions} type='regions' name='state' label='Select your region' ariaLabel='region-dropdown' disabled={isSubmitting} />
        </div>
        <div className='four column eight-tablet sixteen-mobile'>
          <TextInput name='street1' label='Street 1' placeholder='' disabled={isSubmitting} />
        </div>
      </div>
      <div className='row'>
        <div className='four column eight-tablet sixteen-mobile' >
          <TextInput name='street2' label='Street 2' placeholder='' disabled={isSubmitting} />
        </div>
        <div className='four column eight-tablet sixteen-mobile'>
          <TextInput name='city' label='City' placeholder='' disabled={isSubmitting} />
        </div>
      </div>
      <div className='row'>
        <div className='four column eight-tablet sixteen-mobile'>
          <TextInput name='zip' label='Postal Code' placeholder='' disabled={isSubmitting} />
        </div>
      </div>
    </>
  )
}

export default ManualVerificationAddressSection

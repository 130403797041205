import { useEffect } from 'react'
import { PageLoading } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import 'react-phone-input-2/lib/style.css'
import _ from 'lodash'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { getKYCStatus, submitKYCStatus } from 'slices/kycSlice'
import { getCountries } from 'slices/commonSlice'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from '../../../schemas/manualVerificationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import ManualVerificationForm from './partials/ManualVerificationForm'
import { useHistory } from 'react-router-dom'

const ManualVerification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isReviewPending } = useSelector(state => state.kyc)
  const { pageLoading } = useSelector(state => state.kyc)

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: '',
      idType: '',
      legalName: '',
      birthMonth: '',
      birthYear: '',
      birthDay: '',
      selfieFile: '',
      idFrontFile: '',
      idBackFile: ''
    }
  })

  const { watch, handleSubmit, resetField } = methods

  const idType = watch('idType')

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getKYCStatus()).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          if (payload?.isKycVerified) {
            history.push('/profile')
          } else {
            Mixpanel.track('Onboarding View Manual ID Verification Page')
          }
        }
      })
      await dispatch(getCountries())
    }
    fetchData()
  }, [])

  // // Unregister file values when document type is changed
  useEffect(() => {
    if (idType === 'PASSPORT') {
      resetField('idFrontFile')
    } else if (idType === 'DRIVERS_LICENSE' || idType === 'OTHER') {
      resetField('idFrontFile')
      resetField('idBackFile')
    }
  }, [idType])

  const onSubmit = async data => {
    Mixpanel.track('Onboarding Submit Manual ID Verification', { Country: data.country, 'ID Type': data.idType })
    const address = { ..._.pick(data, ['street1', 'street2', 'city', 'zip', 'state', 'country']) }
    const jsonData = { ..._.pick(data, ['legalName', 'birthMonth', 'birthMonth', 'birthDay', 'birthYear', 'idType']), address }
    const idFrontFile = data.idFrontFile
    const idBackFile = data.idBackFile || ''
    const selfieFile = data.selfieFile
    const response = await dispatch(submitKYCStatus({ idFrontFile, idBackFile, selfieFile, jsonData }))
    if (response && response.payload && response.payload.status === 200) {
      history.push('/identity/success-submission')
    }
  }

  if (pageLoading) {
    return (
      <>
        <PageLoading />
        <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Verify Identity')} />
      <FormProvider {...methods}>
        <div className='manual-verfication-container page-container'>
          <div className='inner-container'>
            <h1>Verify Identity</h1>
            {!isReviewPending && <div className='b_18_regular'>First select your country, then you can select your ID type.</div>}
            {isReviewPending && <div className='b_18_regular'>You have already submitted your information and it is pending review.</div>}
            <form onSubmit={handleSubmit(onSubmit)} className='manual_kyc_wrapper'>
              <ManualVerificationForm />
            </form>
          </div>
        </div>
      </FormProvider>
    </>
  )
}

export default ManualVerification

const WireTransferContent = ({ bank }) => {
  return (
    <div className='linqto-wire linqto-wire-form '>
      <p className='b_16_regular'>
        You can add funds by sending a wire transfer from your bank account.
        You’ll need the following information:
      </p>
      <div className='add-funds-info'>
        <div className='add-funds-section'>
          <p className='header'>Bank Information </p>
          <div className='add-funds-row'>
            <p>Bank Country:</p> <p>United States of America</p>
          </div>
          <div className='add-funds-row'>
            <p>Routing Number:</p> <p>021000021</p>
          </div>
          <div className='add-funds-row'>
            <p>SWIFT/BIC:</p> <p>CHASUS33</p>
          </div>
          <div className='add-funds-row'>
            <p>Bank Name:</p> <p>JPMorgan Chase Bank N.A.</p>
          </div>
          <div className='add-funds-row'>
            <p>Bank Address:</p> <p>383 Madison Ave</p>
          </div>
          <div className='add-funds-row'>
            <p></p>
            <p>New York, NY 10017</p>
          </div>
        </div>
        <div className='add-funds-section'>
          <p className='header'>Recipient Information</p>
          <div className='add-funds-row'>
            <p>Recipient Name:</p> <p>Linqto Liquidshares LLC</p>
          </div>
          <div className='add-funds-row'>
            <p>Recipient Address:</p>{' '}
            <p className='add-funds-row-address'>
              <span>101 Metro Drive, </span>
              <span>Suite 335</span>
            </p>
          </div>
          <div className='add-funds-row'>
            <p></p> <p>San Jose, CA 95110</p>
          </div>
          <div className='add-funds-row'>
            <p>Account Number:</p> <p>{bank?.accountNumber}</p>
          </div>
          <div className='add-funds-row'>
            <p>Memo:</p> <p>F.B.O. {bank?.accountName}</p>
          </div>
        </div>
        <div className='add-funds-section'>
          <p>Notes:</p>
          <ul>
            <li>
              <strong>{bank?.accountName}</strong> must be listed as an owner of
              the bank account.
            </li>
            <li>
              Your bank may charge you a fee for sending a wire transfer or
              converting currency to USD. Please keep this in mind when
              determining the transfer amount. Contact your bank for fee
              inquiries.
            </li>
            <li>
              Wire Transfers are typically received and processed within 1 business day. We&apos;ll notify you via email when your deposit is available to use.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WireTransferContent

import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { Button } from 'components'

const Breadcrumb = ({ items }) => {
  const history = useHistory()
  return (
    <div className='breadcrumb-container'>
      {items.map((item, i) => {
        return (
          <div className='flex' key={`bc-${i}`}>
            <Button
              onClick={() => history.push(item.route)}
              className='breadcrumb-link'
              ariaLabel={`Navigate to ${item.name}`}
            >
              {item.name}
            </Button>
            <img src={images['breadcrumb-arrow']} alt={item.name} />
          </div>
        )
      })}
    </div>
  )
}

export default Breadcrumb

import { useFormContext, Controller } from 'react-hook-form'
import Select from 'components/Global/Inputs/Select'
import { useSelector } from 'react-redux'
import { annualIncomeOptions, InvestmentExperienceOptions, InvestmentHorizonOptions, netWorthOptions, RiskToleranceOptions, suitabilityQuestions } from 'components/data'
import { useEffect, useState } from 'react'
import { images } from 'assets'
import { ConditionalRender } from 'components'
import { isWhiteSpace, useLDFlags } from 'utils'

const SuitabilityQuestionnaire = ({ setValidateSuitabilityQuestionnaire }) => {
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])
  const { profileLoading } = useSelector(state => state.profile)
  const [expand, setExpand] = useState(false)
  const { control, watch } = useFormContext()
  const suitabilityFields = ['annualIncome', 'networthExcludingResidence', 'investmentHorizon', 'riskTolerance', 'investmentExperience']
  const validate = suitabilityFields?.some(field => !!watch(field) && (watch(field) !== 'UNKNOWN' || isWhiteSpace(field)))

  /* NOTE - Sometimes on page reload, the LD flag is true and then becomes false, 
     so forcing validation to be false if the flag is false */
  useEffect(() => {
    if (suitabilityQuestions) {
      setValidateSuitabilityQuestionnaire(validate)
    } else {
      setValidateSuitabilityQuestionnaire(false)
    }
  }, [validate, suitabilityQuestions, setValidateSuitabilityQuestionnaire])

  const horizonOptions = Object.keys(InvestmentHorizonOptions)
  const riskOptions = Object.keys(RiskToleranceOptions)
  const expOptions = Object.keys(InvestmentExperienceOptions)

  const handleExpand = () => setExpand(!expand)
  
  if (!suitabilityQuestions) return null
  return (
    <>
      <div className='flex cursor-pointer heading_7' onClick={handleExpand}>Suitability Questionnaire {<img className='ml-1' alt='Trusted Contact' src={images[expand ? 'chevron-down-blue' : 'chevron-right-blue']} />}</div>
      <ConditionalRender isVisible={expand}>
        <div className='grid'>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='annualIncome'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Annual Income'
                  name='annualIncome'
                  options={Object.values(annualIncomeOptions)}
                  ref={null}
                  disabled={profileLoading}
                  ariaLabel='annualIncome'
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='networthExcludingResidence'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Net Worth '
                  name='networthExcludingResidence'
                  options={Object.values(netWorthOptions)}
                  ref={null}
                  disabled={profileLoading}
                  ariaLabel='networthExcludingResidence'
                  extraLabel='(Excluding primary residence)'
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='investmentHorizon'
              render={({ field }) => (
                <Select
                  field={field}
                  label='What is your investment horizon for your investing activities through Linqto?'
                  name='investmentHorizon'
                  options={horizonOptions}
                  textFormat={(val) => InvestmentHorizonOptions[val].type}
                  ref={null}
                  disabled={profileLoading}
                  ariaLabel='investmentHorizon'
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='riskTolerance'
              render={({ field }) => (
                <Select
                  field={field}
                  label='How would you best describe your risk tolerance for your investing activities through Linqto?'
                  name='riskTolerance'
                  options={riskOptions}
                  textFormat={(val) => RiskToleranceOptions[val].type}
                  ref={null}
                  disabled={profileLoading}
                  ariaLabel='riskTolerance'
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='investmentExperience'
              render={({ field }) => (
                <Select
                  field={field}
                  label='How much experience do you have with private securities or other alternative investments?'
                  name='investmentExperience'
                  options={expOptions}
                  textFormat={(val) => InvestmentExperienceOptions[val].type}
                  ref={null}
                  disabled={profileLoading}
                  ariaLabel='investmentExperience'
                />
              )}
            />
          </div>
        </div>
      </ConditionalRender>
    </>
  )
}
export default SuitabilityQuestionnaire

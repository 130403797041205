import React from 'react'
import { formatDecimal } from 'utils'

const InvestmentItemTitle = ({ entityName, total }) => {
  return (
    <div className='current-investment-item-title'>
      <div className='b_18_semibold current-investment-item-title_left'>{entityName}</div>
      <div className='b_18_semibold current-investment-item-title_right'>
        {formatDecimal(total)}
      </div>
    </div>
  )
}

export default InvestmentItemTitle

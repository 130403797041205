import * as yup from 'yup'

export const schema = yup.object().shape({
  confirmOrderCheckbox: yup.bool().oneOf([true], 'Please confirm the order.'),
  sellCompanyId: yup
    .string()
    .ensure()
    .when('$purchaseWithShares', (purchaseWithShares, schema) => {
      if (purchaseWithShares) {
        return schema.required('Please select company.')
      }
    })
})

import Checkbox from 'components/Global/Inputs/Checkbox'
import { FormProvider, useForm } from 'react-hook-form'
import Button from 'components/Global/Button'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const AddAdvisorForm = ({ acceptAdvisor }) => {
  const [disabled, setDisabled] = useState(true)
  const methods = useForm()
  const { submitLoading, riaNameFirst, riaNameLast } = useSelector((state) => state.ria)

  return (
    <div className='add-advisor-form'>
      <h1>Add Wealth Advisor</h1>
      <div className='b_18_regular'>
        Advisors have the ability to view their client’s investments and
        execute trades on behalf of their client.
      </div>
      <FormProvider {...methods}>
        <form>
          <Checkbox
            label={`I confirm ${riaNameFirst} ${riaNameLast} is my financial advisor and authorize Linqto to provide them full access to my account including executing trades, viewing tax documents, and updating personal information.`}
            name='acceptTerms'
            value='1'
            onChange={(e) => setDisabled(!e?.target?.checked)}
            ariaLabel='acceptTerms'
          />
          <div className='btn-group start side-by-side'>
            <Button
              disabled={disabled || submitLoading}
              mode='secondary'
              onClick={(e) => acceptAdvisor(true)}
              type='button'
              loading={submitLoading}
            >
              Accept & Continue to Linqto
            </Button>
            <Button
              onClick={(e) => acceptAdvisor(false)}
              type='button'
              loading={submitLoading}
            >
              Continue without Accepting
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default AddAdvisorForm

import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { useFormContext } from 'react-hook-form'
import ManualUpload from './ManualUpload'

const UploadDocument = ({ documentName, name, disabled }) => {
  const { formState: { errors } } = useFormContext()

  return (
    <div className='show-zone'>
      <ManualUpload documentName={documentName} name={name} disabled={disabled} input={{ name, onChange: () => {} }} />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default UploadDocument

import Button from 'components/Global/Button'
import Modal from 'components/Global/Modal'

const BankAccountModal = ({ twoButtons = false, title, copy, buttonCopy, secondaryButtonCopy = '', handleClick, handleSecondaryClick, hideModal }) => {
  return (
    <Modal
      crossToClose
      modalHeader={title}
      hideModal={hideModal}
      innerStyle='text-left'
    >
      <div className='b_18_regular content'>
        {copy}
      </div>
      {twoButtons ? <div className='btn-group centered two'>
        <Button mode='secondary' onClick={handleSecondaryClick}>
          {secondaryButtonCopy}
        </Button>
        <Button onClick={handleClick} data-testid={`modal-${buttonCopy}-btn`}>
          {buttonCopy}
        </Button>
      </div> : <div className='btn-group centered'>
        <Button onClick={handleClick}>
          {buttonCopy}
        </Button>
      </div>}
    </Modal>
  )
}

export default BankAccountModal

import { Button } from 'components'
import { useHistory } from 'react-router-dom'

const EditProfileDetailsButton = () => {
  const history = useHistory()

  const handleProfileDetailsClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToProfile')
    } else {
      history.push('/profile?redirect=/investor-status')
    }
  }
  return (
    <Button id='edit-profile-button' mode='tertiary' onClick={handleProfileDetailsClick} >Back</Button>
  )
}

export default EditProfileDetailsButton

import { images } from 'assets'
import { Button } from 'components'

const AccountBanner = ({ hideBanner, bannerAction }) => {
  return (
    <div className='p-4 -mt-10 bg-gray-100 border-l-8 border-royal'>
      <div className='flex flex-row items-center justify-between'>
        <div className='heading_7'>Account created!</div>
        <Button className='cursor-pointer' onClick={() => hideBanner('accountBanner')} ariaLabel='Close Banner'>
          <img alt='Close Banner' src={images['banner-close']} />
        </Button>
      </div>
      <div className='flex flex-col justify-between mt-4 lg:flex-row'>
        <div>In order to invest,  please complete your investor profile.</div>
        <Button className='cursor-pointer text-royal' onClick={() => bannerAction('accountBanner')}  ariaLabel='Complete your account'>Start now</Button>
      </div>
    </div>
  )
}

export default AccountBanner

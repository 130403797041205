
const parseOriginationSearch = companies => {
  return companies.map((a) => {
    return {
      value: a.urlName,
      label: a.name
    }
  })
}

export default parseOriginationSearch

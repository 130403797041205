import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import FormData from 'form-data'
import api from '../apis/private.js'
import axios from 'axios'

const initialState = {
  pageLoading: false,
  qrCodeImage: '',
  loading: false,
  isKycVerified: false,
  isReviewPending: false,
  documents: [],
  selfAccreditation: '',
  accreditedCountry: '',
  kycStatus: '',
  country: '',
  url: '',
  birthday: 1,
  birthMonth: 1,
  birthYear: 1,
  address: '',
  countriesWithAlternateId: [],
  plaidIdentityToken: ''
}

export const getIdentity = createAsyncThunk(
  'getIdentity',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/kycStatus')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getSignInQRCode = createAsyncThunk(
  'getSignInQRCode',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/page/kycStatus/getAutoSignInQrCode?height=200&width=200')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const logEvent = createAsyncThunk(
  'logEvent',
  ({ path, body = null }) => {
    return api.post(`/log${path}`, body || {}).catch(err => console.log(err))
  }
)

export const getKYCStatus = createAsyncThunk(
  'getKYCStatus',
  (_, { dispatch, fulfillWithValue }) => {
    return api
      .get('/page/kycStatus/manual')
      .then(res => fulfillWithValue(res.data))
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

const dataURItoBlob = (dataURI, filename) => {
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const theBlob = new Blob([ia], { type: 'image/png' })
  const file = new File([theBlob], filename, { type: 'image/png' })
  return file
}

export const submitKYCStatus = createAsyncThunk(
  'submitKYCStatus',
  (formValues, { dispatch }) => {
    const data = new FormData()
    data.append('idFrontFile', dataURItoBlob(formValues.idFrontFile, 'idFrontFile.png'))
    data.append('selfieFile', dataURItoBlob(formValues.selfieFile, 'selfieFile.png'))
    data.append('jsonData', JSON.stringify(formValues.jsonData))
    if (formValues.idBackFile !== '') data.append('idBackFile', dataURItoBlob(formValues.idBackFile, 'idBackFile.png'))

    const config = {
      method: 'post',
      url: `${import.meta.env.VITE_APP_API_URL}/page/kycStatus/manual`,
      headers: { 'content-type': 'multipart/form-data', hostname: window.location.hostname },
      data: (data)
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage?.getItem('linqto_token') }
    }
    return axios(config)
      .then((res) => {
        return { status: 200, data: res.data }
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return err.response.data.error
      })
  }
)

export const restartKycProcess = createAsyncThunk(
  'restartKycProcess',
  (_, { dispatch }) => {
    return api
      .post('/page/kycStatus/restart')
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const confirmKycStatusVerified = createAsyncThunk(
  'confirmKycStatusVerified',
  (_, { dispatch }) => {
    return api
      .post('/page/kycStatus/confirm')
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const saveIdScanData = createAsyncThunk(
  'saveIdScanData',
  (result, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/kycStatus/saveIdScanData/web', result)
      .catch((err) => {
        if (err.response && err.response.data.error === 'UNDER_AGE_INVESTOR') {
          return rejectWithValue('UNDER_AGE_INVESTOR')
        }
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response.data.error)
      })
  }
)

export const uploadSelfie = createAsyncThunk(
  'uploadSelfie',
  ({ file, func }, { dispatch, rejectWithValue }) => {
    logEvent('/identityVerification/step4/selfieClicked')
    const data = new FormData()
    data.append('file', file)
    data.append('name', 'selfie')
    const config = {
      method: 'post',
      url: `${import.meta.env.VITE_APP_API_URL}/page/kycStatus/saveAndVerifySelfie`,
      onUploadProgress: (progressEvent) => func(progressEvent),
      data: data,
      headers: { hostname: window.location.hostname }
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage?.getItem('linqto_token') }
    }
    return axios(config)
      .then(() => {
        dispatch(getIdentity())
      })
      .catch(err => {
        logEvent('/identityVerification/step4/selfieError', { error: err })
        if (err.response && err.response.data.error === 'FACES_DID_NOT_MATCH') {
          return rejectWithValue('FACES_DID_NOT_MATCH')
        } else if (err.response && err.response.data.error === 'FACE_NOT_FOUND') {
          return rejectWithValue('FACE_NOT_FOUND')
        } else {
          return rejectWithValue('error')
        }
      })
  }
)

export const getPlaidIdentityToken = createAsyncThunk(
  'getPlaidIdentityToken',
  (_, { dispatch }) => {
    return api
      .get('/page/kycStatus/plaidLinkToken')
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const validatePlaidIdentity = createAsyncThunk(
  'validatePlaidIdentity',
  ({ plaidIdentityVerificationSessionId }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    return api
      .post('/page/kycStatus/plaidValidate ', { plaidIdentityVerificationSessionId })
      .then(res => {
        return fulfillWithValue(res.data)
      })
      .catch(err => {
        if (err.response && err.response.data.error === 'UNDER_AGE_INVESTOR') {
          return rejectWithValue('UNDER_AGE_INVESTOR')
        }
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)
export const kycSlice = createSlice({
  name: 'kycSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getIdentity.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getIdentity.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getSignInQRCode.pending, (state) => {
        state.loading = true
      })
      .addCase(getSignInQRCode.fulfilled, (state, { payload }) => {
        return { ...state, loading: false, ...payload }
      })
      .addCase(getSignInQRCode.rejected, (state) => {
        state.loading = false
      })
      .addCase(getKYCStatus.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getKYCStatus.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getKYCStatus.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(submitKYCStatus.fulfilled, (state, { payload: { data } }) => {
        return { ...state, ...data }
      })
      .addCase(submitKYCStatus.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadSelfie.pending, (state) => {
        state.loading = true
      })
      .addCase(uploadSelfie.fulfilled, (state, { payload }) => {
        return { ...state, loading: false, ...payload }
      })
      .addCase(uploadSelfie.rejected, (state, { payload }) => {
        return { ...state, loading: false, payload }
      })
      .addCase(saveIdScanData.pending, (state) => {
        state.loading = true
      })
      .addCase(saveIdScanData.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveIdScanData.rejected, (state) => {
        state.loading = false
      })
      .addCase(getPlaidIdentityToken.fulfilled, (state, { payload }) => {
        state.plaidIdentityToken = payload
      })
      .addCase(validatePlaidIdentity.pending, (state) => {
        state.loading = true
      })
      .addCase(validatePlaidIdentity.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(validatePlaidIdentity.rejected, (state, { payload }) => {
        return {
          loading: false
        }
      })
  }
})

export default kycSlice.reducer

const parseBroker = (data) => {
  const u = data.brokerInfo || {}
  return {
    accountName: u.accountName ? u.accountName : '',
    accountNumber: u.accountNumber ? u.accountNumber : '',
    confirmAccountNumber: u.accountNumber ? u.accountNumber : '',
    dtc: u.dtc ? u.dtc : '',
    firmName: u.firmName ? u.firmName : '',
    specialInstructions: u.specialInstructions ? u.specialInstructions : ''
  }
}

export default parseBroker

import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'hooks/useQuery'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import ConditionalRender from '../../Global/ConditionalRender'
import SuccessContent from './partials/SuccessContent'
import FailedContent from './partials/FailedContent'
import ConfirmResetForm from './partials/ConfirmResetForm'
import { schema } from 'schemas/confirmPasswordSchema'
import { resetPassword } from 'slices/userSlice'

const ConfirmResetPassword = () => {
  const { resetPasswordStatus } = useSelector((state) => state.userSlice)

  const dispatch = useDispatch()
  const query = useQuery()
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })

  const onSubmit = (formValues) => {
    const code = query.get('code')
    dispatch(resetPassword({ code, password: formValues.password })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Submit Password Reset')
      }
    })
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm Reset Password')} />
      <div className='reset-password-container'>
        <ConditionalRender isVisible={resetPasswordStatus === 'initial'}>
          <FormProvider {...methods}>
            <ConfirmResetForm onSubmit={onSubmit} />
          </FormProvider>
        </ConditionalRender>
        <ConditionalRender isVisible={resetPasswordStatus === 'success'}>
          <SuccessContent />
        </ConditionalRender>
        <ConditionalRender isVisible={resetPasswordStatus === 'failed'}>
          <FailedContent />
        </ConditionalRender>
      </div>
    </>
  )
}

export default ConfirmResetPassword

import { useSelector } from 'react-redux'

const InvestDetailWhyInvestInUs = () => {
  const { details } = useSelector(state => state.investSlice)
  const { whyInvestDescription, name } = details || {}
  const { whyInvestVideoUrl } = details || {}

  if (!whyInvestDescription && !whyInvestVideoUrl) {
    return null
  }

  return (
    <div className='column sixteen container-margin'>
      <h6 className='accessibleIndigo margin-bottom'>Why Invest in {name}?</h6>
      <p className='b_16_regular gray3 margin-bottom'>{whyInvestDescription}</p>
      {whyInvestVideoUrl && <div className='margin-bottom__flex-center'>
        <iframe src={whyInvestVideoUrl}
          id='whyInvestVideoUrlIFrame'
          height={400}
          width={708}
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
        />
      </div>}
    </div>
  )
}

export default InvestDetailWhyInvestInUs

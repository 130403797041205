import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const UnicornDetailsFundingRounds = ({ fundingRounds }) => {
  return (
    <>
      <div className='desktop-content' >
        <div className='grid unicorn-table'>
          <div className='row unicorn-table-header'>
            <div className='column five' style={{ paddingLeft: 0 }}>Date</div>
            <div className='column five'>Round</div>
            <div className='column five column-right'>Amount</div>
            <div className='column five column-right'>Valuation</div>
            <div className='column five'>Investors</div>
          </div>
          { fundingRounds.map((f, i) =>
            <div key={`fundingRound_${i}`} className='row unicorn-table-body'>
              <div className='column five'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
              <div className='column five'>{f.round}</div>
              <div className='column five column-right'>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
              <div className='column five column-right'>{f.postMoneyValuation && formatNumberWithLetter(f.postMoneyValuation, true, true, 1, true)}</div>
              <div className='column five'>{f.investors}</div>
            </div>
          )}
        </div>
      </div>
      <div className='unicorn-card-container'>
        {fundingRounds && fundingRounds.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div>
              <div className='row unicorn-card-row'>
                <div className='column eight'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
                <div className='column eight'><strong>Round: </strong> {f.round}</div>
              </div>
              <div className='sixteen column row unicorn-card-row-line-sepretaor' />
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Amount: </strong> {f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
                <div className='column eight'><strong>Valuation: </strong>{f.postMoneyValuation && formatNumberWithLetter(f.postMoneyValuation, true, true, 1, true)}</div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column sixteen'><strong>Investors: </strong>{f.investors}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsFundingRounds

import _ from 'lodash'

const profilePayload = (formValues) => {
  const {
    pStreet1,
    pStreet2,
    pCity,
    pZip,
    pState,
    pCountry,
    mStreet2,
    mStreet1,
    mCity,
    mZip,
    mState,
    mCountry
  } = formValues

  const addresses = !formValues.sameAddress
    ? [
      {
        type: 'ADDRESS',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      },
      {
        type: 'MAILING',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      }
    ]
    : [
      {
        type: 'ADDRESS',
        street1: pStreet1,
        street2: pStreet2,
        city: pCity,
        state: pState,
        country: pCountry,
        zip: pZip
      },
      {
        type: 'MAILING',
        street1: mStreet1,
        street2: mStreet2,
        city: mCity,
        state: mState,
        country: mCountry,
        zip: mZip
      }
    ]

  const entity = formValues.type ? { type: formValues.type } : {}
  const userProfile = {
    ..._.pick(formValues, [
      'firstName',
      'lastName',
      'taxId',
      'foreignTaxId',
      'citizenshipCountry',
      'usResidendcyStatus',
      'phone',
      'annualIncome',
      'networthExcludingResidence',
      'capitalAllocation',
      'occupation',
      'employerName',
      'sectors',
      'investmentHorizon',
      'riskTolerance',
      'investmentExperience'
    ]),
    addresses
  }
  // If acknowledgedRiskAt Date exists then send to backend
  if (formValues?.acknowledgedRiskAt) {
    userProfile.acknowledgedRiskAt = formValues?.acknowledgedRiskAt
  }
  if (formValues?.acknowledgedCrsAt) {
    userProfile.acknowledgedCrsAt = formValues?.acknowledgedCrsAt
  }

  // remove dashes from tax id input
  userProfile.taxId = userProfile?.taxId?.replaceAll('-', '')

  const trustedContact = {}

  if (formValues.trustedContactFirstName !== '') {
    trustedContact.firstName = formValues.trustedContactFirstName
    trustedContact.lastName = formValues.trustedContactLastName
    trustedContact.phone = formValues.trustedContactPhone
    trustedContact.relationship = formValues.trustedContactRelationship
    trustedContact.address = {}
    trustedContact.address.street1 = formValues.trustedContactStreet1
    trustedContact.address.street2 = formValues.trustedContactStreet2 || ''
    trustedContact.address.city = formValues.trustedContactCity
    trustedContact.address.state = formValues.trustedContactState
    trustedContact.address.country = formValues.trustedContactCountry
    trustedContact.address.zip = formValues.trustedContactZip
    trustedContact.address.type = 'ADDRESS'
  }

  return {
    userProfile,
    entity,
    trustedContact
  }
}
export default profilePayload

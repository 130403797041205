import { formatDecimal } from 'utils'

const OriginationOfferCompanyOffer = ({ company }) => {
  return (
    <div className='section-nar origination-detail-offer origination-offer-offer'>
      <div className={`box orginiation-order-box not-logged-in offer ${company?.optionsStrikePrice ? 'option-offer' : ''}`}>
        <div className='offer'>DEAL SUMMARY</div>
        <h3 className='price-total'>{company && formatDecimal(company.totalPrice)}</h3>
        <div className='share-total'>{company && formatDecimal(company.shares, false, 0)} shares</div>
        <div className='share'>Price Per Share:
          <span className='b_18_semibold'>{company && formatDecimal(company.sharePrice)}</span></div>
        {company?.optionsStrikePrice && <div className='share strike-price'>Strike Price: <span className='b_18_semibold'>{company && formatDecimal(company.optionsStrikePrice)}</span></div>}
      </div>
    </div>
  )
}

export default OriginationOfferCompanyOffer

import * as yup from 'yup'

export const schema = yup.object().shape({
  accountName: yup
    .string()
    .when('$hasEntities', (hasEntities, schema) => {
      if (hasEntities) {
        return schema.required(
          'Please select account.'
        )
      }
    }),
  sharesForAccount: yup
    .string()
    .when('$selectedAccount', (selectedAccount, schema) => {
      if (selectedAccount) {
        return schema.test(
          'VaildAmount',
          'Enter valid amount',
          (val) => {
            if (val) {
              return val > 0 && val <= selectedAccount?.sellableShareCount
            } else {
              return true
            }
          }
        )
      }
    })
    .when('$minSell', (minSell, schema) => {
      if (minSell?.subTotal) {
        return schema.test(
          'orderMinimum',
          'Minimum not reached',
          (val) => {
            if (minSell.selectedAvailableShares !== parseInt(val)) {
              return minSell.subTotal >= minSell.minimumTotalSellPrice
            } else {
              return true
            }
          }
        )
      }
    })
})

import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { images } from 'assets'
import { getAccreditationQuiz, scoreAccreditationQuiz, getInvestorStatus } from 'slices/investorStatusSlice'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE, Mixpanel } from 'utils'
import { useHistory } from 'react-router-dom'
import RadioButton from 'components/Global/Inputs/RadioButton'
import Button from 'components/Global/Button'
import PageLoading from 'components/Global/PageLoading'
import FinanciallySophisticatedProgressBar from '../partials/FinanciallySophisticatedProgressBar'
import ConditionalRender from 'components/Global/ConditionalRender'
import FinanciallySophisticatedResultRevamp from '../partials/FinanciallySophisticatedResultRevamp'

const CurrentPage = ({ currentPage, questions, onClick, goBack, total, onSubmit, btnLoading, maxCurrentPageIncrememnt }) => {
  const { width } = useWindowSize()
  const {
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields }
  } = useFormContext()

  // takes in index of mapped question and returns question number
  const renderQuestionNumber = (i) => currentPage === 0 ? i + 1 : (i + (currentPage * 5)) + 1
  // list of either 1 or 5 questions displayed on the page
  const currentQuestionArray = questions?.[currentPage] || []
  // used for checking against total # of questions answered (to render checkbox and enable/disable next/submit button)
  const currentPageIncrement = currentPage + 1

  if (currentQuestionArray && currentQuestionArray.length) {
    // used to enable/disable button on last page with checkbox
    const maxTotalQuestions = (total * currentQuestionArray.length) + 1
    return (
      <>
        {currentQuestionArray.map((item, i) => (
          <div key={item?.questionId} className='question-container'>
            <p className={`index b_18_semibold ${errors[item?.questionId] ? 'error' : ''}`}>{width <= MOBILE_WIDTH_SIZE ? currentPageIncrement : renderQuestionNumber(i)}.</p>
            <div className='question'>
              <p className={`question-text b_18_semibold ${errors[item?.questionId] ? 'error' : ''}`} key={currentPage}>{item?.questionText}<span className={`b_18_regular ${errors[item?.questionId] ? 'error' : ''}`}>{' '}(please choose one option):</span></p>
              <Answers question={item} currentPage={currentPage} />
            </div>
          </div>
        ))}
        {currentPageIncrement === total && <Checkbox
          label='I hereby certify: (1) that I did not receive any third-party assistance answering the questions on this exam and that all work done on it was my own; and (2) each of my answers was generated independently by me, without use of external resources, including assistance from any other person, documents, manuals, search engines, social media or other resources.  I understand that if I am found to have violated the above certification that I may thereby lose accredited investor status and the ability to invest through the Linqto platform.'
          name='consent'
          ariaLabel='consent'
          required
        />}
        <div className='btn-group right'>
          <Button mode='tertiary' onClick={goBack} disabled={isSubmitting}>Back</Button>
          <Button id='nextBtn' loading={btnLoading}
            disabled={Object.keys(errors).length !== 0 ||
            ((Object.keys(dirtyFields)?.length !== (currentQuestionArray?.length * currentPageIncrement) && maxCurrentPageIncrememnt < currentPageIncrement) &&
            Object.keys(dirtyFields)?.length !== maxTotalQuestions)}
            onClick={currentPageIncrement === total ? handleSubmit(onSubmit) : onClick}>
            {currentPageIncrement === total ? 'Submit' : 'Next'}
          </Button>
        </div>
      </>
    )
  }
  return null
}

const Answers = ({ question }) => question?.answers?.map((item, i) => (
  <RadioButton
    key={i}
    name={item?.questionId?.toString()}
    value={item?.answerId}
    label={item?.answerText}
    ariaLabel='answer choice'
    required
    className='quiz-input'
  />
))

const FinanciallySophisticatedTestRevamp = ({ setShowAccreditationTypesModal }) => {
  const history = useHistory()
  const { quizQuestions, email, hasTakenAccreditationQuiz } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const [questions, setQuestions] = useState([])
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [maxCurrentPageIncrememnt, setMaxCurrentPageIncrememnt] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [showResults, setShowResults] = useState(hasTakenAccreditationQuiz)
  const { control } = methods

  const { fields } = useFieldArray({
    control,
    name: 'quizQuestions'
  })

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAccreditationQuiz())
      setLoading(false)
      Mixpanel.track('View Financially Sophisticated Test')
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (quizQuestions && quizQuestions.length) {
      methods.reset({ quizQuestions })
    }
  }, [quizQuestions])

  useEffect(() => {
    // get email and legal name if user refreshes the page
    if (!email) {
      dispatch(getInvestorStatus())
    }
  }, [email])

  useEffect(() => {
    const arr = []
    const maxQuestions = width <= MOBILE_WIDTH_SIZE ? 1 : 5
    if (fields && fields.length) {
      for (let i = 0; i <= fields.length; i += maxQuestions) {
        const data = fields.slice(i, i + maxQuestions)
        if (data && data.length) {
          arr.push(data)
        }
      }
      setQuestions(arr)
    }
  }, [width, fields])

  useEffect(() => window.scrollTo(0, 0), [currentPage])

  const onSubmit = data => {
    const filteredQuizAnswerQuestions = Object.entries(data).map(([questionId, answerId]) => ({ questionId, answerId }))
    const quizAnswerSelections = filteredQuizAnswerQuestions?.filter(item => item?.questionId !== 'quizQuestions' && item?.questionId !== 'consent' && item?.answerId !== null)
    setBtnLoading(true)
    dispatch(scoreAccreditationQuiz({ quizAnswerSelections })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Submit Financially Sophisticated Test')
        window.scrollTo(0, 0)
        setShowResults(true)
        setBtnLoading(false)
      }
    })
  }

  // if user is on first set of questions,
  // go back to investor status page, otherwise go to previous set of questions
  const handleBack = () => {
    if (currentPage === 0) {
      history.goBack()
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleClick = () => {
    Mixpanel.track('Click Next on Financially Sophisticated Test', { Page: currentPage + 1 })
    // blur button so focus state is not active
    document.getElementById('nextBtn').blur()
    setCurrentPage((prev) => {
      const newCurrentPage = prev + 1
      if (newCurrentPage > maxCurrentPageIncrememnt) {
        setMaxCurrentPageIncrememnt(newCurrentPage)
      }
      return newCurrentPage
    })
  }

  if (loading) {
    return <PageLoading />
  }

  return (
    <>
      <ConditionalRender isVisible={!showResults}>
        <p className='heading_7 test-title'>Financial Sophistication Test<span className='b_16_regular gray3'>
          {isMobile ? <br /> : null}
          <img src={images.clock} alt='Financial Sophistication Test' className='clock' />Estimated 7-10 minutes (20 questions)</span></p>
        <p className='b_18_regular'>Verifying your <Button className='inline-text-link' ariaLabel='Verifying your accreditation' onClick={() => setShowAccreditationTypesModal(true)}>accreditation</Button>{' '} through this test will give you access to invest in every company available on our platform. U.S. laws and regulations require us to verify if you are an accredited investor. If you would like to verify your accreditation a different way, you can select a different option on the previous page.</p>
        <p className='b_18_regular'><span className='b_18_semibold'>A couple things to know: </span>You have a total of 1 attempt to pass this test. Navigating away from the test without submission will not be recorded as an attempt. A score of 70% or higher is required to pass this test.</p>
        <FinanciallySophisticatedProgressBar currentPage={currentPage + 1} total={width <= MOBILE_WIDTH_SIZE ? 20 : 4} totalItems={20} isMobile={width <= MOBILE_WIDTH_SIZE}/>
        <FormProvider {...methods}>
          <CurrentPage
            currentPage={currentPage}
            questions={questions}
            onClick={handleClick}
            goBack={handleBack}
            total={width <= MOBILE_WIDTH_SIZE ? 20 : 4}
            onSubmit={onSubmit}
            btnLoading={btnLoading}
            maxCurrentPageIncrememnt={maxCurrentPageIncrememnt} />
        </FormProvider>
      </ConditionalRender>
      <ConditionalRender isVisible={showResults}>
        <FinanciallySophisticatedResultRevamp />
      </ConditionalRender>
    </>
  )
}

export default FinanciallySophisticatedTestRevamp

import renderBirthDay from './renderBirthDay'
import BirthDropdown from './BirthDropdown'
import { useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'

const ManualVerificationBirthInputsContainer = () => {
  const { watch, formState: { isSubmitting } } = useFormContext()
  const birthMonth = watch('birthMonth')
  const birthYear = watch('birthYear')

  return (
    <div className='row'>
      <div className='four column eight-tablet sixteen-mobile'>
        <TextInput name='legalName' label='Legal Name *' placeholder='Legal Name' ariaLabel='legal-name' disabled={isSubmitting} />
      </div>
      <div className='two column eight-tablet sixteen-mobile'>
        <BirthDropdown name='birthMonth' label='Birth Month*' ariaLabel='birth-month' items={renderBirthDay.renderMonth()} disabled={isSubmitting} />
      </div>
      <div className='two column eight-tablet sixteen-mobile'>
        <BirthDropdown name='birthDay' label='Birth Day*' ariaLabel='birth-day' items={renderBirthDay.renderDay({ birthMonth, birthYear })} disabled={isSubmitting} />
      </div>
      <div className='two column eight-tablet sixteen-mobile'>
        <BirthDropdown name='birthYear' label='Birth Year*' ariaLabel='birth-year' items={renderBirthDay.renderYear()} disabled={isSubmitting} />
      </div>
    </div>
  )
}

export default ManualVerificationBirthInputsContainer

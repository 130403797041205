import * as yup from 'yup'
import { isWhiteSpace, isValidEmail, isURL } from 'utils'

const regex = /\d+/g

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .when('$oAuthToken', (oAuthToken, schema) => {
      if (!oAuthToken) {
        return schema.required(
          'Please enter a first name.'
        ).test(
          'WhiteSpace',
          'Please enter a first name.',
          (val) => !isWhiteSpace(val)
        ).test(
          'URL',
          'Please enter a valid first name.',
          (val) => !isURL(val)
        )
      }
    }),
  lastName: yup
    .string()
    .when('$oAuthToken', (oAuthToken, schema) => {
      if (!oAuthToken) {
        return schema.required(
          'Please enter a last name.'
        ).test(
          'WhiteSpace',
          'Please enter a last name.',
          (val) => !isWhiteSpace(val)
        ).test(
          'URL',
          'Please enter a valid last name.',
          (val) => !isURL(val)
        )
      }
    }),
  email: yup
    .string()
    .when('$oAuthToken', (oAuthToken, schema) => {
      if (!oAuthToken) {
        return schema.required(
          'Please enter an email.'
        ).test('validEmail', 'Please enter a valid email.', (val) =>
          isValidEmail(val)
        )
      }
    }),
  password: yup
    .string()
    .when('$oAuthToken', (oAuthToken, schema) => {
      if (!oAuthToken) {
        return schema.required(
          'Please enter a password.'
        ).test(
          'len',
          'Password must be at least 8 characters long.',
          (val) => val.length > 7
        ).test('containsNum', 'Password must contain at least one number.', (val) =>
          val?.match(regex)
        )
      }
    }),
  accreditOption: yup
    .string()
    .ensure()
    .when('$fromOrigination', (fromOrigination, schema) => {
      if (fromOrigination) {
        return schema.required(
          'Please choose one accreditation option in order to proceed.'
        )
      }
    })
})

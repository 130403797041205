import USDocumentUpload from '../partials/USDocumentUpload'
import { USDoceumentTypes, InitialDocumentsToUpload } from '../../data'

const ManualUploadDocumentRevamp = ({ type }) => {
  return (
    <>
      <USDocumentUpload
        dropDownList={USDoceumentTypes[type]}
        intialDocsToUpload={InitialDocumentsToUpload[type]}
      />
    </>
  )
}

export default ManualUploadDocumentRevamp

import * as yup from 'yup'
import { isWhiteSpace, isValidEmail } from 'utils'

export const schema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter your email')
    .test(
      'WhiteSpace',
      'Please enter your email',
      (val) => !isWhiteSpace(val)
    ).test(
      'validEmail',
      'Please enter a valid email.',
      (val) => isValidEmail(val)
    )
})

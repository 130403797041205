import { useFlags } from 'launchdarkly-react-client-sdk'
import { Mixpanel } from './Mixpanel'
// adding this so we have a record of prior flags to compare to make sure when component re-renders we don't call mixpanel again
const allFlags = {}
/**
 * This is a hook that take in experiment keys, returns the variant name and also track experiment started event
 * @function useLDFlags
 * @param {array of strings} experimentKey
 * @returns {object} experiment variant value object
 */
export const useLDFlags = (experimentKeys) => {
  const flags = useFlags()
  for (let i = 0; i < experimentKeys.length; i++) {
    const variant = flags[experimentKeys[i]]
    // compare the variant to the previous variant and only lod mixpanel when it is different
    if (allFlags[experimentKeys[i]] !== variant) {
      Mixpanel.track('$experiment_started', {
        'Experiment name': experimentKeys[i],
        'Variant name': variant,
        $source: 'launchdarkly'
      })
      allFlags[experimentKeys[i]] = variant
    }
  }

  return flags
}

import { Button } from 'components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PlaidIncomeLinkButton from './partials/PlaidIncomeLinkButton'
import PlaidIncomeDocumentLinkButton from './partials/PlaidIncomeDocumentLinkButton'
import { useLDFlags } from 'utils'

const ButtonGroup = ({ loading, disabled, onClick = () => {}, submitButtonType = '' }) => {
  const history = useHistory()
  const { accreditedStatus, hasRequestedFinancialAdvisor } = useSelector((state) => state.investorStatusSlice)

  const { accreditationRevamp } = useLDFlags(['accreditationRevamp'])

  const redirectToProfile = () => {
    // change action if user is on web or app/webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToProfile')
    } else {
      if (accreditedStatus === 'ACCREDITED') {
        return history.push('/profile')
      }
      history.push('/profile?redirect=/investor-status')
    }
  }
  const renderSubmitButton = () => {
    switch (submitButtonType) {
    case 'financialAdvisor' :
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
        {hasRequestedFinancialAdvisor
          ? 'Request Sent - Resend'
          : 'Email Financial Professional'}
      </Button>
    case 'plaidIncome':
      return <PlaidIncomeLinkButton disabled={disabled}/>
    case 'quiz':
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
      Take Test
      </Button>
    case 'plaidDocumentIncome':
      return <PlaidIncomeDocumentLinkButton disabled={disabled}/>
    default:
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
        Submit
      </Button>
    }
  }

  return (
    <div className={`btn-group ${accreditationRevamp ? 'left' : 'right'} info-container`}>
      {!accreditationRevamp && <Button mode='tertiary' onClick={redirectToProfile} disabled={loading}>Back</Button>}
      {renderSubmitButton()}
    </div>
  )
}

export default ButtonGroup

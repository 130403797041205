import { images } from 'assets'
import { Button } from 'components'

const BundleCounter = ({ type, disabled, onClick }) => {
  const onCounterClick = () => {
    if (disabled) return null
    else onClick()
  }
  let imgSrc = ''
  if (type === 'plus') {
    imgSrc = disabled ? 'whiteplus_disabled' : 'whiteplus'
  } else {
    imgSrc = disabled ? 'whiteminus_disabled' : 'whiteminus'
  }
  return (
    <Button 
      className={`bundle-qty-btn${disabled ? ' bundle-qty-btn_disabled' : ''}`}
      data-testid={`qty-btn-${type}`}
      onClick={onCounterClick}
      disabled={disabled}
      ariaLabel='Change bundle quantity'
      >
      <img src={images[imgSrc]} alt='Change bundle quantity' />
    </Button>
  )
}

export default BundleCounter

import { useState, useEffect } from 'react'
import ConditionalRender from '../ConditionalRender'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { uploadDocument, resetUploadErr } from 'slices/commonSlice'
import {
  renderFileName,
  RenderLoadingUpload,
  RenderErrorUpload,
  RenderSuccessUpload,
  RenderIntitalUpload
} from './renderer'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const Upload = ({
  type = '',
  query = '',
  name = '',
  enableView = false,
  allowDelete = false,
  autoUpload = false, // upload file once it's dropped in the dropzone
  fileUploaded = [],
  setFileUploaded = () => {},
  onDropComplete = null,
  onDelete = null,
  url = '', // document get back from API and render
  ariaLabel = '',
  callAfterSuccessUpload,
  uploadError = false,
  disabled = false,
  onViewDocument = () => {},
  customClass = '',
  acceptedFileFormat = '.pdf, .png, .jpg, .jpeg',
  uploadErr = null,
  entityId = null
}) => {
  const { width } = useWindowSize()
  const [placeHolder, setPlaceHolder] = useState('')
  const [validationErr, setValidationErr] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')
  const [uploadErrorTitleMessage, setUploadErrorTitleMessage] = useState('')
  const [fileUploading, setFileUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    setPlaceHolder(width <= MOBILE_WIDTH_SIZE ? 'Upload file' : 'Drag & drop your file here')
  }, [width])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => completeDrop(acceptedFiles),
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
    accept: acceptedFileFormat,
    onDropRejected: (fileRejections) => UploadRejected(fileRejections)
  })

  const completeDrop = (acceptedFiles) => {
    if (disabled) return false
    if (onDropComplete) {
      // if onDrop complete function passed to the compoment, just exceude it
      onDropComplete(acceptedFiles)
    } else {
      // if no onDrop sent, check if it's autoupload to dispatch the upload action
      if (acceptedFiles.length > 0) {
        if (autoUpload) {
          setFileUploading(true)
          dispatch(
            uploadDocument({
              acceptedFile: acceptedFiles[0],
              fileName: renderFileName(name, acceptedFiles),
              type,
              query,
              func: loadProgress,
              entityId
            })
          ).then(({ meta, payload }) => {
            if (meta.requestStatus === 'fulfilled' && callAfterSuccessUpload) {
              if (payload.id) { // if id is returned from the api call
                callAfterSuccessUpload(payload.id)
              } else {
                callAfterSuccessUpload()
              }
            }
            if (meta.requestStatus === 'rejected') {
              setFileUploaded('')
              setValidationErr(true)
              setUploadErrorTitleMessage('Upload Failed: Please Try Again')
            }
            setFileUploading(false)
            setFileUploaded(acceptedFiles)
          })
        } else {
          // if it's not auto upload just set the fileUplod from parent component
          setFileUploaded(acceptedFiles)
        }
      }
    }
  }

  const UploadRejected = (fileRejections) => {
    // setting a specific error message when file uploading is not supported
    if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
      setUploadErrorTitleMessage('Upload Failed: File Not Supported')
      setUploadErrorMessage(`Please upload a ${acceptedFileFormat} file.`)
    } else {
      setUploadErrorTitleMessage('Upload Failed')
      setUploadErrorMessage(fileRejections[0]?.errors[0]?.message)
    }
    setValidationErr(true)
  }

  useEffect(() => {
    // render server error
    if (uploadErr) {
      setFileUploaded('')
      setValidationErr(true)
      setUploadErrorTitleMessage('Upload Failed: Please Try Again')
    }
  }, [uploadErr])

  const handleDelete = (e) => {
    setValidationErr(false)
    setFileUploaded('')
    setUploadErrorTitleMessage('')
    setUploadErrorMessage('')
    dispatch(resetUploadErr())
    if (onDelete) {
      onDelete()
    }
  }

  const loadProgress = (progressEvent) => {
    const percentCompleted = parseInt((progressEvent.loaded / progressEvent.total) * 100)
    setProgress(percentCompleted)
  }

  return (
    <div className={`upload-container-new ${customClass}`}>
      <ConditionalRender
        isVisible={
          url.length === 0 &&
          fileUploaded.length === 0 &&
          !validationErr &&
          !fileUploading
        }
      >
        <RenderIntitalUpload
          placeHolder={placeHolder}
          dragging={dragging}
          uploadError={uploadError}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          open={open}
          disabled={disabled}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={fileUploading}>
        {<RenderLoadingUpload progress={progress} testId={name}/>}
      </ConditionalRender>
      <ConditionalRender isVisible={fileUploaded.length > 0 && !validationErr}>
        <RenderSuccessUpload
          enableView={enableView}
          name={name}
          fileUploaded={fileUploaded}
          handleDelete={handleDelete}
          ariaLabel={ariaLabel}
          allowDelete={allowDelete}
          onViewDocument={onViewDocument}
          disabled={disabled}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={validationErr}>
        <RenderErrorUpload
          enableView={enableView}
          uploadErrorTitleMessage={uploadErrorTitleMessage}
          uploadErrorMessage={uploadErrorMessage}
          handleDelete={handleDelete}
        />
      </ConditionalRender>
      <ConditionalRender isVisible={url.length > 0 &&
          !fileUploading}>
        <RenderSuccessUpload
          enableView={enableView}
          name={name}
          fileUploaded={fileUploaded}
          handleDelete={handleDelete}
          url={url}
          allowDelete={allowDelete}
          ariaLabel={ariaLabel}
          onViewDocument={onViewDocument}
        />
      </ConditionalRender>
    </div>
  )
}

export default Upload

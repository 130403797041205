import { images } from 'assets'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import NumberInput from 'components/Global/Inputs/NumberInput'
import TextInput from 'components/Global/Inputs/TextInput'
import TooltipLabel from 'components/Global/TooltipLabel'
import { useFormContext } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

const tooltip = {
  swiftBic: 'Your SWIFT/BIC code is an 8 or 11 alpha-numeric character code. Usually you will find it on your statement or at your online banking site.',
  iban: 'IBAN is an International Banking Account Number.',
  sortCode: 'A bank sort code is a type of routing number used in the United Kingdom and in Ireland. It’s composed of six digits divided into three pairs. You can find your sort code on your online banking statements or your debit card.',
  bsb: 'If you need to transfer money to a bank in Australia, the quickest way to find a BSB number is to look on the bank’s website. All of its BSB codes should be included along with a branch locator. If you’re unsure of a bank’s BSB, call the branch to ensure you’ve got the correct number.'
}

const routingTooltip = (isUSA) => {
  if (isUSA) {
    return (
      <div>
        You can find your routing and account numbers printed at the bottom of your check or your account statement.
        <img className='tooltip-routing-image' alt='Routing Number' src={images['routing-image']} />
      </div>
    )
  }
  return (
    <div>
      Your routing number consists of a three digit financial institution number and a five digit transit number (or branch number). A leading zero is used when formatting a routing number for electronic payments.
      <br/><br/>
      If YYY is the financial institution number and XXXXX is the transit number, you will enter your routing number as follows:
      <br/><br/>
      <span className='b_18_semibold centered'>0YYYXXXXX</span>
    </div>
  )
}

const RoutingInput = ({ isUSA }) => (
  <NumberInput
    name='routingNumber'
    label={`Routing Number${isUSA ? ' (ABA)' : ''}`}
    content={routingTooltip(isUSA)}
    ariaLabel='routingNumber'
  />
)

const SwiftInput = () => (
  <TextInput
    name='swiftBic'
    label='SWIFT/BIC'
    content={tooltip.swiftBic}
    ariaLabel='swiftBic'
  />
)

const IBANInput = () => (
  <TextInput
    name='iban'
    label='IBAN'
    content={tooltip.iban}
    ariaLabel='iban'
  />
)

const AccountNumber = () => (
  <NumberInput
    name='accountNumber'
    label='Account Number'
    ariaLabel='accountNumber'
  />
)

const SortCodeInput = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${errors?.sortCode?.message && 'error'}`}>
      <TooltipLabel content={tooltip.bsb} label={'Sort Code'} />
      <ReactInputMask
        mask='99-99-99'
        alwaysShowMask
        {...register('sortCode')}
        aria-label={'sortCode'}
      />
      {errors?.sortCode?.message && <ErrorMessage message={errors?.sortCode?.message} />}
    </div>
  )
}

const BSBInput = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${errors?.bsb?.message && 'error'}`}>
      <TooltipLabel content={tooltip.bsb} label={'BSB'} />
      <ReactInputMask
        mask='999-999'
        alwaysShowMask
        {...register('bsb')}
        aria-label={'bsb'}
      />
      {errors?.bsb?.message && <ErrorMessage message={errors?.bsb?.message} />}
    </div>
  )
}

const UnitedStates = () => (
  <>
    <RoutingInput isUSA />
    <AccountNumber />
  </>
)

const Canada = () => (
  <>
    <SwiftInput />
    <AccountNumber />
    <RoutingInput />
  </>
)

const International = () => (
  <>
    <SwiftInput />
    <AccountNumber />
    <IBANInput />
  </>
)

const EuropeanUnion = () => (
  <>
    <SwiftInput />
    <AccountNumber />
    <IBANInput />
  </>
)

const UKIreland = () => (
  <>
    <SwiftInput />
    <IBANInput />
    <SortCodeInput />
  </>
)

const Austrailia = () => (
  <>
    <SwiftInput />
    <AccountNumber />
    <BSBInput />
  </>
)

export { International, EuropeanUnion, UKIreland, Austrailia, Canada, UnitedStates }

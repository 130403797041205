import Button from '../../Global/Button'

const OriginationDetailCompanyCTA = ({ company, submitOffer, directToSignUp, checkStrikeValue, checkIfOption }) => {
  return (
    <div className={`section-nar origination-detail-cta ${!company.isLoggedIn ? 'detail-not-logged' : ''}`}>
      {company.isLoggedIn && <Button
        customClass='place-order-button'
        onClick={submitOffer}
        disabled={checkIfOption() && checkStrikeValue()}
      >Continue</Button>
      }
      {!company.isLoggedIn && <Button
        customClass='place-order-button'
        onClick={directToSignUp}
      > Sign Up to Continue</Button>
      }
    </div>
  )
}

export default OriginationDetailCompanyCTA

import { images } from 'assets'
import { Button } from 'components'

const TopBanner = ({
  hideBanner,
  bannerAction,
  title,
  body,
  CTAText = '',
  type = 'confirmation'
}) => {
  return (
    <div className={`account-banner-container top-banner-contanier ${type}`}>
      <div className='account-banner-header'>
        <div className='heading_7'>{title}</div>
      </div>
      <div className='account-banner-body'>
        <div>{body}</div>
        {CTAText && (
          <Button className='account-banner-link' onClick={bannerAction}>
            {CTAText}
          </Button>
        )}
      </div>
      <Button className='account-banner-close' onClick={hideBanner} ariaLabel='Close Banner'>
        <img className='about-hero' alt='Close Banner' src={images['banner-close']} />
      </Button>
    </div>
  )
}

export default TopBanner

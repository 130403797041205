import USDocumentUpload from './USDocumentUpload'
import { USDoceumentTypes } from '../../data'

const USIndividualIncomeSection = () => {
  const intialDocsToUpload = [
    { id: 1, name: 'Select Income Last Year' },
    { id: 2, name: 'Select Income Previous Year' }
  ]

  return (
    <USDocumentUpload
      dropDownList={USDoceumentTypes.ANNUAL_INCOME}
      intialDocsToUpload={intialDocsToUpload}
    />
  )
}

export default USIndividualIncomeSection

import RadioButton from 'components/Global/Inputs/RadioButton'
import React from 'react'

const UserAccrediationReasons = ({ reasons }) => {
  return (
    <div className='radio-group radio-input-group'>
      {Object.keys(reasons).map((ele, id) => (
        <React.Fragment key={id}>
          <RadioButton label={reasons[ele]} name='reason' value={ele} ariaLabel={`reason-${id}`} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default UserAccrediationReasons

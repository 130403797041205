import { useSelector } from 'react-redux'
import { selfAccreditationOptions } from '../../data'
const SelfAccreditationSection = ({
  selfAccreditationState,
  handleSaveSelfAccreditation,
  loading,
  accreditedStatus
}) => {
  const { featureFlags } = useSelector(state => state.userSlice)
  const { hasTakenAccreditationQuiz, assetsAccounts, isProcessing, documentAccreditationIsProcessing } = useSelector((state) => state.investorStatusSlice)
  const { AccreditationThroughTestAllowed } = featureFlags || {}

  const options = { ...selfAccreditationOptions }

  if (AccreditationThroughTestAllowed) {
    if (!hasTakenAccreditationQuiz) {
      options.FINANCIAL_SOPHISTICATION = 'Financially Sophisticated (Take Test)'
    }
  }

  return (
    <div className='b_18_regular info-container'>
      <label>Choose how you qualify</label>
      <select
        value={selfAccreditationState}
        onChange={(e) => handleSaveSelfAccreditation(e.target.value)}
        disabled={
          loading ||
          (accreditedStatus !== 'NOT_VERIFIED' && accreditedStatus !== 'FAILED') ||
          assetsAccounts?.length !== 0 || isProcessing || documentAccreditationIsProcessing
        }
        role='selfAccreditation-dropdown'
        className='selfAccreditation-dropdown'
      >
        <option value='UNKNOWN' disabled>
          Please select an option
        </option>
        {Object.keys(options).map((el, id) => (
          <option key={Object.keys(options)[id]} value={el}>
            {options[el]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelfAccreditationSection

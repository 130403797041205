import { useHistory } from 'react-router-dom'
import { Button } from 'components'

const SignInLink = () => {
  const history = useHistory()
  return (
    <span className='account-signin'>
      Already have an account?
      <Button onClick={() => history.push('/signin')} className='link primary left-padded'>Sign In</Button>
    </span>
  )
}

export default SignInLink

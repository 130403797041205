import { Modal, Content, Wrapper, Row } from 'components'
import Button from 'components/Global/Button'
import ReactHtmlParser from 'react-html-parser'

const EUResidentsModalRevamp = ({ hideModal, data: { title, body }, choiceData: { checkbox1, checkbox2, checkbox3, experiencedInvestorChoice } }) => {
  return (
    <Modal crossToClose hideModal={hideModal} modalHeader={title} innerStyle='text-left'>
      <Wrapper>
        <Content>
          <div className='b_18_regular message'>{ReactHtmlParser(body)}</div>
          <br/>
          <div className='b_18_regular message'>{checkbox1}</div>
          <div className='b_18_regular message'>{checkbox2}</div>
          <div className='b_18_regular message'>{checkbox3}</div>
          <div className='b_18_regular message'>{experiencedInvestorChoice}</div>
        </Content>
        <br/>
        <Row className='btn-group centered'><Button onClick={hideModal}>Continue to Verify</Button></Row>
      </Wrapper>
    </Modal>
  )
}

export default EUResidentsModalRevamp

import { images } from 'assets'
import AccreditationHeaderRevamp from './AccreditationHeaderRevamp'

const UnderReviewStatusView = ({ accreditationTypeData, accreditationSubTypeData }) => {
  const { ICON: typeIcon, TITLE: typeTitle } = accreditationTypeData || {}
  const { ICON: methodIcon, TITLE: methodTitle } = accreditationSubTypeData || {}
  return (
    <div className='accreditation-under-review'>
      <AccreditationHeaderRevamp />
      <div className='accreditation-under-review__box margined'>
        <div className='flex-justify-start__mobile-column'>
          <div className='heading_7'>Accreditation Method </div>
          <div className='status-tag b_14_regular accent-ember'><img width={16} height={16} src={images['acc-under-review']} alt='Accreditation Under Review' />Under Review</div>
        </div>
        <div className='flex-justify-start__mobile-column'>
          {typeTitle && <div className='accreditation-type flex-align-center'>
            <img className='type-icon' src={images[typeIcon]} alt={typeTitle} />
            <div className='b_16_semibold'>{typeTitle}</div>
          </div>
          }
          <div className='accreditation-type flex-align-center'>
            <img className='type-icon' src={images[methodIcon]} alt={methodTitle} />
            <div className='b_16_semibold'>{methodTitle}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderReviewStatusView


const ProgressBar = ({
  activeStep
}) => {
  let width = '33%'
  let step = 1
  if (activeStep === 'Verify') {
    width = '100%'
    step = 3
  } else if (activeStep === 'finraProfile') {
    // Need to update the step name
    width = '66%'
    step = 2
  }

  return (
    <div className='progressbar-wrapper'>
      <p data-testid='progressBarValue'>{step} of 3</p>
      <div className='progressbar-line-bg'>
        <div className='progressbar-line' style={{ width: width }}/>
      </div>
    </div>
  )
}

export default ProgressBar

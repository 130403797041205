import React from 'react'

const HistoryTitle = ({ account, type, showCompany }) => {
  return (
    <div className='b_18_semibold history-title'>
      {showCompany ? type === 'history' ? 'Order History for' : 'Past Holdings for' : ''} {account}
    </div>
  )
}

export default HistoryTitle

import React from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../../../Global/Inputs/ErrorMessage'

const DocumentTypeDropdown = ({ items, name, label, ariaLabel, disabled, onChange = () => {}, className }) => {
  const { register, formState: { errors } } = useFormContext()

  const options = items.map(({ label, value }, i) => (
    <option value={value} key={`${value} + ${i}`} data-testid='select-option'>{label}</option>
  ))

  return (
    <div className={`input-group  ${errors?.name ? 'error' : ''} ${className}`}>
      <label>{label}</label>
      <select {...register(name, { onChange })} disabled={disabled} aria-label={ariaLabel}>
        <option value=''>Document Type</option>
        {options}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default DocumentTypeDropdown

import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'

const SuccessModal = () => {
  const history = useHistory()

  return (
    <Modal modalHeader='Successful Save'>
      <div className='b_18_regular content'>
        Your broker information has been saved successfully!
      </div>
      <div className='btn-group centered'>
        <Button onClick={() => history.push('/profile?tab=brokerage')}>
          Return to Basic Information
        </Button>
      </div>
    </Modal>
  )
}

export default SuccessModal

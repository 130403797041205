import { Mixpanel } from 'utils'
import { Button } from 'components'

const InvestmentTabContainer = ({ onChange, tab }) => {
  const handleChange = (type) => {
    onChange(type)
    Mixpanel.track('Click Portfolio Tab', { 'Portfolio Tab': type === 'history' ? 'History' : 'Current Investments' })
  }

  return (
    <div className='invest-tabs-container'>
      <div className='page-tabs section-seperator '>
        <Button  className={`tab heading_7 ${tab === 'current' && 'active connectedCobalt'}`} onClick={() => handleChange('current')}>Current Investments</Button>
        <Button className={`tab heading_7 ${tab === 'history' && 'active connectedCobalt'}`} onClick={() => handleChange('history')}>History</Button>
      </div>
    </div>
  )
}

export default InvestmentTabContainer

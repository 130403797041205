import axios from 'axios'

const request = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_URL}`
})
request.interceptors.request.use(config => {
  const hostname = window.location.hostname
  if (hostname) {
    config.headers.hostname = hostname
  }
  return config
})

export default request

import React from 'react'
import { useSelector } from 'react-redux'
import { formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import { images } from 'assets'
import Pill from 'components/Global/Pill'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'hooks/useWindowSize'

const InvestmentItem = ({ type, company: { iconUrl, companyName, companyMessages, purchased, shares, tag, status, urlName, hasRecurringInvestment } }) => {
  const history = useHistory()
  const { width } = useWindowSize()
  const { featureFlags } = useSelector(state => state.userSlice)
  const { RecurringInvestmentEnabled } = featureFlags || {}
  const hasCompanyUpdate = companyMessages?.length > 0
  if (type === 'history') {
    return (
      <div className='current-investment-item' onClick={() => history.push(`/portfolio/${urlName}`)} data-testid={`investment-item-${companyName}`} role='button' aria-label={`Go to ${companyName}`}>
        <div className='current-investment-item_full'>
          <div className='current-investment-item_left'>
            <img src={iconUrl} alt={companyName} />
            <span className='b_18_regular'>{companyName}</span>
          </div>
          <div className='current-investment-item_right'>
            <span className='current-investment-item_right status'>{status === 'OPEN' ? 'Sold' : status?.toLowerCase()}</span>
            <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} alt={companyName}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='current-investment-item' onClick={() => history.push(`/portfolio/${urlName}`)} role='button' aria-label={`Go to ${companyName}`}>
      {tag === 'ORDER_PENDING' && <Pill className='b_14_regular flag pending' status='Pending' />}
      <div className='current-investment-item_full'>
        <div className='current-investment-item_left'>
          <img src={iconUrl} alt={companyName}/>
          <div className='current-investment-item_recurring'>
            <span className='b_18_regular'>{companyName}</span>
            {hasCompanyUpdate && width > MOBILE_WIDTH_SIZE && <span className='b_16_regular gray4 recurring-copy'><img src={images['update-exclamation']} />{'Company Update'}</span>}
            {!hasCompanyUpdate && width > MOBILE_WIDTH_SIZE && RecurringInvestmentEnabled && hasRecurringInvestment && <span className='b_16_regular gray4 recurring-copy'><img src={images.restart_alt}/>Recurring Investment Scheduled</span>}
          </div>
        </div>
        <div className='current-investment-item_right'>
          <div className='current-investment-item_right shares-total-price-container'>
            <span className='current-investment-item_right shares'>{formatDecimal(shares, false, 0)} shares</span>
            <span className='b_18_semibold current-investment-item_right total-price'>{formatDecimal(purchased)}</span>
          </div>
          <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} alt='Shares'/>
        </div>
      </div>
      {hasCompanyUpdate && width <= MOBILE_WIDTH_SIZE && <span className='b_16_regular gray4 recurring-copy'><img className='update-mobile-icon' src={images['update-exclamation']} alt='Company Update'/>{'Company Update'}</span>}
      {!hasCompanyUpdate && width <= MOBILE_WIDTH_SIZE && RecurringInvestmentEnabled && hasRecurringInvestment && <span className='b_16_regular gray4 recurring-copy'><img src={images.restart_alt} alt='Recurring Investment Scheduled'/>Recurring Investment Scheduled</span>}
    </div>
  )
}

export default InvestmentItem

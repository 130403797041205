import * as yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const schema = yup.object().shape({
  occupation: yup
    .string()
    .required('Please enter your occupation.'),
  employerName: yup
    .string()
    .required('Please enter your employer\'s name.'),
  annualIncome: yup
    .string()
    .required('Please select your annual income.')
    .test('len', 'Please select your annual income.', (val) => val !== 'Please select'),
  networthExcludingResidence: yup
    .string()
    .required('Please select your net worth.')
    .test('len', 'Please select your net worth.', (val) => val !== 'Please select'),
  capitalAllocation: yup
    .string()
    .required('Please select how much you will allocate.')
    .test('len', 'Please select how much you will allocate.', (val) => val !== 'Please select'),
  sectors: yup
    .array()
    .required('Please select a sector.')
    .test('len', 'Please select a sector.', (val) => val?.length > 0),
  acknowlegeCheckbox: yup
    .boolean()
    .when('acknowledgedRiskAt', {
      is: (acknowledgedRiskAt) => !acknowledgedRiskAt,
      then: yup.bool().oneOf([true], 'Please confirm.')
    }),
  trustedContactPhone: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a phone number.')
      }
    })
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.test(
          'validPhone',
          'Please enter a valid phone number.',
          (val) => val && isValidPhoneNumber(val)
        )
      }
    }),
  trustedContactFirstName: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a first name.')
      }
    }),
  trustedContactLastName: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a last name.')
      }
    }),
  trustedContactRelationship: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a relationship.')
      }
    }),
  trustedContactStreet1: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a street address.')
      }
    }),
  trustedContactCity: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a city.')
      }
    }),
  trustedContactState: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please select a state or province.')
      }
    }),
  trustedContactCountry: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please select a country.')
      }
    }),
  trustedContactZip: yup
    .string()
    .ensure()
    .when('$validateTrustedContact', (validateTrustedContact, schema) => {
      if (validateTrustedContact) {
        return schema.required('Please enter a postal code.')
      }
    })
})

import * as yup from 'yup'

const schema = yup.object().shape({
  occupation: yup
    .string()
    .required('Please enter your occupation.'),
  employerName: yup
    .string()
    .required('Please enter your employer\'s name.'),
  acknowlegeCheckbox: yup.bool().oneOf([true])
})
export default schema

import { useEffect } from 'react'
import { month } from '../../../data'
import Button from '../../../Global/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getIdentity } from 'slices/kycSlice'

const IdentityConfirm = ({ handleConfirm, handleRestart }) => {
  const dispatch = useDispatch()
  const { legalName, birthYear, birthMonth, birthDay, address } = useSelector((state) => state.kyc)

  useEffect(() => {
    dispatch(getIdentity())
  }, [])

  return (
    <>
      <div className='b_18_regular'>The following information was gathered from your document:</div>
      <div className='b_18_regular identity-confirm'>
        <div className='item'>{legalName && legalName}</div>
        <div className='item'>{birthMonth && month[birthMonth]} {birthDay && birthDay}, {birthYear && birthYear}</div>
        <div className='item'>{address && address.split('\n').map(s => <div key={s}>{s}</div>)}</div>
      </div>
      <div className='btn-group'>
        <Button onClick={() => handleConfirm()}>Confirm</Button>
      </div>
      <a className='b_18_semibold restart-link' href='/identity/auto-verification/1' onClick={() => handleRestart()}>Restart with New Document</a>
    </>
  )
}

export default IdentityConfirm

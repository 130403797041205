/* These Components are used for better readability instead of using divs.
   The divs pass style, props and children through.
*/

export const Wrapper = ({ className, id, children, ...props }) =>
  <div {...props} id={id} className={className}>{children}</div>

export const Header = ({ className, children, ...props }) =>
  <div {...props} className={className}>{children}</div>

export const Content = ({ className, children, ...props }) =>
  <div {...props} className={`b_18_regular ${className}`}>{children}</div>

export const Row = ({ className, children, ...props }) =>
  <div {...props} className={className}>{children}</div>

export const Link = ({ className, children, ...props }) =>
  <a {...props} className={className}>{children}</a>

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import OriginationDetailHeader from 'components/OriginationDetail/partials/OriginationDetailHeader'
import OriginationDetailCompanyLogo from 'components/OriginationDetail/partials/OriginationDetailCompanyLogo'
import OriginationOfferCompanyOffer from 'components/OriginationOffer/partials/OriginationOfferCompanyOffer'
import OriginationDetailCompanyCopy from 'components/OriginationDetail/partials/OriginationDetailCompanyCopy'
import { Mixpanel, seoTitleTemplate } from 'utils'
import { getOriginationConfirmedOffer } from 'slices/originationSlice'

const OriginationConfirmed = ({ match }) => {
  const { pageLoading, offerConfirmed } = useSelector(
    (state) => state.origination
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const originationId = match.params.originationId
    dispatch(getOriginationConfirmedOffer(originationId)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Originations Offer Confirmed Page', { 'Company Name': payload?.offerConfirmed?.name })
      }
    })
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Confirmed | Sell')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed | Sell')} />
      <div className='origination-container origination-details-container unicorns-details-container'>
        <OriginationDetailHeader />
        <div className='page-container'>
          <div className='inner-container'>
            <div className='sections-container'>
              <OriginationDetailCompanyLogo company={offerConfirmed} />
              <OriginationOfferCompanyOffer company={offerConfirmed} />
              <OriginationDetailCompanyCopy
                company={offerConfirmed}
                page='confirmed'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OriginationConfirmed

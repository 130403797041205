import { Wrapper } from 'components'
import InvestDetailSummaryOfficers from './InvestDetailSummaryOfficers'
import InvestDetailSummaryFacts from './InvestDetailSummaryFacts'
import { useSelector } from 'react-redux'
// import { images } from 'assets'
import InformationContainer from './InformationContainer'

const InvestDetailSummary = ({ setMosaicShowModal }) => {
  const { details } = useSelector(state => state.investSlice)
  const { isSignedIn } = useSelector(state => state.userSlice)
  return (
    <Wrapper className={`invest-tab ${!isSignedIn ? 'blur-info' : ''}`} id='summary'>
      <div className='column sixteen row desktop-b_18_regular container-margin'>
        <div className='section-seprator' />
      </div>
      <div className='sixteen column row container-margin'>
        <h1 className='accessibleIndigo'>Summary</h1>
        <div className='container-margin'>
          <div className='summary-container'>
            <div className='summary-container__left'>
              {details.customerFacts && (
                <InvestDetailSummaryFacts
                  facts={details.customerFacts}
                  title='Customers'
                  icon='summary-customers'
                />
              )}
              {details.keyInvestors && (
                <InvestDetailSummaryFacts
                  facts={details.keyInvestors}
                  title='Key Investors'
                  icon='summary-key-investors'
                />
              )}
              {details.marketFacts && (
                <InvestDetailSummaryFacts
                  facts={details.marketFacts}
                  title='Size of Market'
                  icon='summary-size-of-market'
                />
              )}
              {details.marketPositionFacts && (
                <InvestDetailSummaryFacts
                  facts={details.marketPositionFacts}
                  title='Market Position'
                  icon='summary-market-position'
                />
              )}
            </div>
            <InformationContainer isSummary setMosaicShowModal={setMosaicShowModal} />
          </div>
        </div>
      </div>
      <div className='sixteen column row container-margin accessibleIndigo'>
        <div className='subheader-title'>
          <h6>Key Officers</h6>
        </div>
        <InvestDetailSummaryOfficers officers={details.keyOfficers} />
      </div>
    </Wrapper>
  )
}
export default InvestDetailSummary

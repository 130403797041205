import { useSelector } from 'react-redux'
import { formatDecimal } from 'utils'
import moment from 'moment'

const SellOrderPurchaseCredit = () => {
  const { sellOrder } = useSelector(state => state.sellOrder)

  if (!sellOrder?.purchaseCreditsEarned) {
    return null
  }
  return (
    <>
      <div className='sell-order-summary-container__row spaced'>
        <span className='b_18_semibold gray5'>Purchase Credits Earned</span>
        <span className='b_18_regular gray5'>{formatDecimal(sellOrder.purchaseCreditsEarned)}</span>
      </div>
      <div className='sell-order-summary-container__row m-4'>
        <span className='b_16_regular gray3'>Credits expire on {moment(sellOrder.purchaseCreditsExpirationDate).format('MM/DD/YYYY') }</span>
      </div>
    </>
  )
}

export default SellOrderPurchaseCredit

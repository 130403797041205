import { images } from 'assets'

const OriginationDetailHeader = () => {
  return (
    <div className='page-header'>
      <img src={images['radial-lines-gray']} className='unicorn-backgeound' alt='unciron background'/>
    </div>
  )
}

export default OriginationDetailHeader

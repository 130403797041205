
const parseWithdraw = (data) => {
  return {
    ...data,
    upholdAccessId: data?.upholdAccess?.upholdAccessId || null,
    upholdCards: data?.upholdAccess?.upholdCards || [],
    pendingCashExternalAccounts: data?.cashExternalAccounts?.filter((a) => a.amlStatus === 'NEEDS_REVIEW' || a.amlStatus === 'CS_NEEDS_REVIEW') || [],
    approvedCashExternalAccounts: data?.cashExternalAccounts?.filter((a) => a.amlStatus === 'APPROVED') || []
  }
}

export default parseWithdraw

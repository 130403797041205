import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import axios from 'axios'
import api from '../apis/private.js'
const initialState = {
  advisorName: '',
  allowDocumentDelete: true,
  documents: [],
  financialAdvisorRequestId: '',
  firstName: '',
  isAccredited: false,
  lastName: '',
  showVerificationForm: false,
  country: '',
  financialAdvisorAttestation: {
    description: '',
    options: [
      {
        accreditationType: '',
        optionId: '',
        optionText: ''
      }
    ]
  }
}

export const getFinancialAdvisor = createAsyncThunk('getFinancialAdvisor', async (query, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const config = {
    method: 'get',
    url: `${import.meta.env.VITE_APP_API_URL}/page/financialAdvisor`,
    headers: query
  }
  try {
    const res = await axios(config)
    if (res) {
      return fulfillWithValue(res.data)
    }
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
    return rejectWithValue(err.response)
  }
})

export const saveFinancialAdvisorReqest = createAsyncThunk('saveFinancialAdvisorRequest', async (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    await api.post('/page/financialAdvisor', { ...formValues })
    return fulfillWithValue()
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
    return rejectWithValue(err.response)
  }
})

export const financialAdvisorSlice = createSlice({
  name: 'financialAdvisor',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialAdvisor.fulfilled, (state, { payload }) => {
        return {
          ...payload
        }
      })
      .addCase(saveFinancialAdvisorReqest.fulfilled, (state, { payload }) => {
        return {
          ...payload
        }
      })
  }
})

export default financialAdvisorSlice.reducer

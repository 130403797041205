import React from 'react'
import { Helmet } from 'react-helmet'

const SeoMeta = ({ title, description = '', keywords = '', ogImage = '', children }) => {
  return (
    <Helmet>
      ‍<title>{title}</title>
      {description !== '' && <meta name='description' content={description} />}
      {keywords !== '' && <meta name='keywords' content={keywords} />}
      {ogImage !== '' && <meta property='og:image' content={ogImage} />}
      {children}
    </Helmet>
  )
}

export default SeoMeta

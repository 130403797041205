import { useEffect, useState } from 'react'
import BaseModal from './BaseModal'
import ReactDOM from 'react-dom'
const modalRoot = document.createElement('div')
modalRoot.setAttribute('id', 'modal')
document.body.appendChild(modalRoot)
const Modal = (props) => {
  const [domready, setdomready] = useState(false)

  useEffect(() => {
    setdomready(true)
  }, [])

  return domready ? ReactDOM.createPortal(<BaseModal {...props}/>, modalRoot) : null
}

export default Modal

import Button from 'components/Global/Button'
import { useFormContext } from 'react-hook-form'

const FBOAddFundsCTA = ({ onSubmit, selectedAccount, cancelAddFunds }) => {
  const { handleSubmit, formState: { errors }, watch } = useFormContext()
  const amountValue = watch('amount')

  return (
    <div className='btn-group two'>
      <Button mode='tertiary' type='button' onClick={cancelAddFunds}>
        Cancel
      </Button>
      <Button
        onClick={(e) => handleSubmit(onSubmit)}
        disabled={[Object.keys(errors).length] > 0 || !selectedAccount || amountValue <= 0 }
      >
        Next
      </Button>
    </div>
  )
}

export default FBOAddFundsCTA

import { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { Mixpanel, useLDFlags } from 'utils'
import { Button, ConditionalRender } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import USSuccessModal from './USSuccessModal'
import {
  getPlaidDocumentIncomeToken,
  getDocumentIncomeLinkingComplete,
  getInvestorStatus
} from 'slices/investorStatusSlice'

const PlaidIncomeDocumentLinkButton = ({ disabled }) => {
  const dispatch = useDispatch()

  const [showPlaidDocumentSuccessModal, setShowPlaidDocumentSuccessModal] = useState(false)
  const { accreditationIncomeFlow } = useLDFlags(['accreditationIncomeFlow'])
  const { loading, plaidDocumentIncomeToken, documentAccreditationIsProcessing, accreditedStatus } = useSelector((state) => state.investorStatusSlice)
  const disableBtn = disabled || loading || (!plaidDocumentIncomeToken && !window.ReactNativeWebView) || documentAccreditationIsProcessing || accreditedStatus === 'UNDER_REVIEW'

  useEffect(() => {
    if (accreditationIncomeFlow && !window.ReactNativeWebView) {
      dispatch(getPlaidDocumentIncomeToken())
    }
  }, [])

  useEffect(() => {
    const messageListener = window.addEventListener('message', async (nativeEvent) => {
      try {
        const data = JSON.parse(nativeEvent?.data)
        if (data?.type === 'plaid income document success') {
          onLinkSuccess()
        }
      } catch (err) {
        // ignore error intentionally because there are too many error log here.
      }
    })
    return messageListener
  }, [])

  const onLinkSuccess = async () => {
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto/Manual',
      'Accredited Country': 'United States',
      'Accreditation Status': '$200k Individual Income for 2 Years'
    })
    setShowPlaidDocumentSuccessModal(true)
    if (!window.ReactNativeWebView) {
      await dispatch(getDocumentIncomeLinkingComplete())
    }
    dispatch(getInvestorStatus('noRefresh'))
  }

  const onExit = async (error) => {
    console.log('Plaid error', error)
  }
  const onEvent = async (eventName, metadata) => {
    if (eventName === 'ERROR') {
      Mixpanel.track('Accreditation Plaid Document Income Event Error', { Error: metadata.error_code })
    } else {
      Mixpanel.track(`Accreditation Plaid Document Income Event: ${eventName}`, { 'Link Step': metadata.view_name })
    }
  }
  // Plaid configurations
  const config = {
    onSuccess: onLinkSuccess,
    onExit,
    onEvent,
    token: plaidDocumentIncomeToken
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('triggerPlaidIncomeDocument')
    } else {
      open()
    }
  }

  return (
    <>
      <Button loading={loading} disabled={disableBtn} onClick={connectPlaid}>
        Proceed to Upload
      </Button>
      <ConditionalRender isVisible={showPlaidDocumentSuccessModal}>
        <USSuccessModal hideModal={() => setShowPlaidDocumentSuccessModal(false)} />
      </ConditionalRender>
    </>
  )
}

export default PlaidIncomeDocumentLinkButton

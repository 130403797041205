import { useSelector } from 'react-redux'
import { Mixpanel, truncate } from 'utils'
const NewsAndInsights = () => {
  const { details: { news, name } } = useSelector(state => state.investSlice)

  const handleClick = (news) => {
    Mixpanel.track('Click News & Insights Article on Product Page', { 'Company Name': name, Article: news.title })
    window.open(news.sourceLink)
  }

  const renderNewsCard = (news) => {
    return (
      <div key={news.source} className='box news-card' onClick={() => handleClick(news)} role='button' aria-label='News'>
        <div className='b_14_regular connectedCobalt news-source'>{news.source}</div>
        <div className='heading_7 accessibleIndigo news-title'>{truncate(news.title, 78)}</div>
        <div className='b_16_regular gray3'>{truncate(news.text, 140)}</div>
      </div>
    )
  }

  if (!news || news?.length === 0) {
    return null
  }

  return (
    <div className='column sixteen'>
      <div className='subheader-title accessibleIndigo'>
        <h6>News & Insights</h6>
      </div>
      <div className='desc news-container'>
        {news && news.map((n) => renderNewsCard(n))}
      </div>
    </div>
  )
}

export default NewsAndInsights

import parseOrders from 'actions/parsing/parseOrders.js'

const parsingPortfolio = (data) => {
  return {
    ...data,
    company: data?.company,
    recentOrders: data.recentOrders ? parseOrders(data.recentOrders) : [],
    entities: data.entities ? data.entities : [],
    recurringEvents: data.recurringEvents ? data.recurringEvents : []
  }
}

export default parsingPortfolio

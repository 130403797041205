import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from 'hooks/useQuery'
import { authenticateAIUser } from 'slices/commonSlice'

const AiAuth = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const state = query.get('state')
  useEffect(() => {
    const aiSignIn = async () => {
      const { payload, meta } = await dispatch(authenticateAIUser())
      if (meta.requestStatus === 'fulfilled') {
        window.location.assign(`${import.meta.env.VITE_AI_URL}?state=` + state + '&tempCode=' + payload)
      }
    }
    if (state) {
      aiSignIn()
    }
  }, [state])
  
  return (
    <div className='min-h-[80vh] py-16 px-40'>
      <p>redirecting...</p>
    </div>
  )
}

export default AiAuth

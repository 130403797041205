import { formatDecimal } from 'utils'

const UpholdWalletAccountsItem = ({ account }) => {
  return (
    <div className='uphold-wallet-account-row'>
      <div>
        <img alt='usd' src={account.iconUrl} />
        <span>{account.label}</span>
      </div>
      <div>
        <span>{formatDecimal(account.usdBalance)}</span>
      </div>
    </div>
  )
}

export default UpholdWalletAccountsItem

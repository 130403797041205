import Button from '../../../Global/Button'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

const MfaModalCTA = ({ steps, setSteps, onSubmit, type }) => {
  const { submitOTPLoading } = useSelector((state) => state.securitySlice)

  const {
    handleSubmit
  } = useFormContext()

  if (type === 'totp' && steps !== 3) {
    if (steps === 1) {
      return (
        <div className='btn-group centered'>
          <Button
            type='button'
            onClick={() => setSteps(2)}
          >
            Next
          </Button>
        </div>
      )
    } else if (steps === 2) {
      return (
        <div className='btn-group centered'>
          <Button
            type='button'
            onClick={(e) => {
              e.preventDefault()
              setSteps(3)
            }}
          >
            Next
          </Button>
        </div>
      )
    }
  } else if (type === 'sms' && steps === 1) {
    return (
      <div className='btn-group centered'>
        <Button
          type='button'
          onClick={handleSubmit(onSubmit)}
          disabled={submitOTPLoading}
          loading={submitOTPLoading}
        >
          Next
        </Button>
      </div>
    )
  } else {
    return (
      <div className='btn-group centered'>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={submitOTPLoading}
          loading={submitOTPLoading}
        >
          Confirm
        </Button>
      </div>
    )
  }
}

export default MfaModalCTA

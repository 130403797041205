import { useEffect, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPlaidAssetsToken,
  sendAssetsLinkedAccountInfo,
  getInvestorStatus
} from 'slices/investorStatusSlice'
import { images } from 'assets'
import { Mixpanel } from 'utils'
import { useLDFlags } from 'utils/LaunchDarkly'
import { Button } from 'components'

const PlaidAssetsLink = ({ hasLinked }) => {
  const dispatch = useDispatch()
  const { plaidAssetsToken, isProcessing } = useSelector((state) => state.investorStatusSlice)
  const { plaidAccreditation } = useLDFlags(['plaidAccreditation'])
  useEffect(() => {
    if (plaidAccreditation && !window.ReactNativeWebView) {
      dispatch(getPlaidAssetsToken())
    }
  }, [])
  const onLinkSuccess = useCallback(async (publicToken, metadata) => {
    await dispatch(sendAssetsLinkedAccountInfo(metadata))
    dispatch(getInvestorStatus())
  })

  const onExit = async (error) => {
    console.log('Plaid error', error)
  }
  const onEvent = async (eventName, metadata) => {
    if (eventName === 'ERROR') {
      Mixpanel.track('Accreditation Plaid Assets Event Error', { Error: metadata.error_code })
    } else {
      Mixpanel.track(`Accreditation Plaid Assets Event: ${eventName}`, { 'Link Step': metadata.view_name })
    }
  }
  // Plaid configurations
  const config = {
    onSuccess: onLinkSuccess,
    onExit,
    onEvent,
    token: plaidAssetsToken
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    Mixpanel.track('Click Connect Bank Account on Accreditation Page')
    if (!isProcessing) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('triggerPlaidAssets')
      } else {
        open()
      }
    }
  }
  return (
    <Button className={`link ${isProcessing ? 'gray' : 'primary'} connect-account-btn ${isProcessing ? 'disabled' : ''}`} onClick={connectPlaid} ariaLabel={hasLinked ? 'Connect another bank' : 'Connect a bank account'}>
      <img className='plus-sign' src={isProcessing ? images['plus-sign-gray'] : images['plus-sign']} alt='Add Account' />
      {hasLinked ? 'Connect another bank' : 'Connect a bank account'}
    </Button>
  )
}

export default PlaidAssetsLink

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// import { useSelector } from 'react-redux'

const GoogleRecaptcha = ({ children }) => {
  const googleReCaptchaKeyId = {
    'app.dev.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'dev.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'app.alpha.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'alpha.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'app.beta.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'beta.linqto.com': '6Le7IwYkAAAAAGtVQvg703EU6zwk4DuNT6pvvked',
    'app.linqto.com': '6LduFywkAAAAAL69I87KWwwn6hxIyKuh63__zSP_',
    'www.linqto.com': '6LduFywkAAAAAL69I87KWwwn6hxIyKuh63__zSP_'
  }

  // const { googleReCaptchaKeyId } = useSelector((state) => state.userSlice)
  if (googleReCaptchaKeyId[window.location.host]) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={googleReCaptchaKeyId[window.location.host]}
        useEnterprise={true}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

export default GoogleRecaptcha

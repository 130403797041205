import { useEffect, useState } from 'react'
import { Button } from 'components'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormError from 'components/Global/FormError'

const SuitabilityButtons = ({ activeStep, setActiveStep, bigError, onSubmit }) => {
  const history = useHistory()
  const { handleSubmit, watch } = useFormContext()
  const { featureFlags: { CrsCheckEnabled } } = useSelector(state => state.userSlice)
  const { userProfile } = useSelector(state => state.profile)
  const [disableNextButton, setDisableNextButton] = useState(false)

  const occupation = watch('occupation')
  const employerName = watch('employerName')
  const annualIncome = watch('annualIncome')
  const networthExcludingResidence = watch('networthExcludingResidence')
  const investmentHorizon = watch('investmentHorizon')
  const riskTolerance = watch('riskTolerance')
  const investmentExperience = watch('investmentExperience')
  const crsCheckbox = watch('acknowlegeCheckbox')

  useEffect(() => {
    const disableNextButton = () => {
      switch (activeStep) {
        case 1: return !occupation || !employerName
        case 2: return annualIncome === 'UNKNOWN'
        case 3: return networthExcludingResidence === 'UNKNOWN'
        case 4: return investmentHorizon === 'UNKNOWN'
        case 5: return riskTolerance === 'UNKNOWN'
        case 6: return investmentExperience === 'UNKNOWN'
        case 7: return !crsCheckbox
      }
    }
    setDisableNextButton(disableNextButton())
  }, [activeStep, occupation, employerName, annualIncome, networthExcludingResidence,
    investmentHorizon, riskTolerance, investmentExperience, crsCheckbox])

  const goBack = () => {
    setActiveStep((prev) => {
      if (prev === 1) history.push('/profile')
      return prev - 1
    })
  }

  const onNext = () => {
    const shouldSubmit = activeStep === 7 || (activeStep === 6 && (userProfile?.acknowledgedRiskAt || userProfile?.acknowledgedCrsAt))
    if (shouldSubmit) {
      handleSubmit(submitQuestions)()
      return
    }
    setActiveStep(activeStep + 1)
  }

  const submitQuestions = (data) => {
    let body = data
    // Add acknowlededAt date for backend payload
    if (!userProfile?.acknowledgedCrsAt && CrsCheckEnabled) {
      body.acknowledgedCrsAt = new Date()
    }
    if (!userProfile?.acknowledgedRiskAt) {
      body.acknowledgedRiskAt = new Date()
    }
    // For legacy users with sector field, map selected sectors for backend payload
    const sectors = data.sectors?.map(s => ({ name: s.label, sectorId: s.value }))
    body.sectors = sectors

    onSubmit(body)
  }

  return (
    <>
      <div className='btn-group right'>
        <Button
          mode='tertiary'
          style={{ marginRight: '10px' }}
          onClick={goBack}>
          Back
        </Button>
        <Button
          disabled={disableNextButton}
          onClick={onNext}
        >
          Next
        </Button>
      </div>
      <div className='flex justify-end mt-4'>
        {bigError && (
          <FormError
            errorMessageHeader='Something Went Wrong'
            errorMessageCopy='Unable to save profile.'
          />
        )}
      </div>
    </>
  )
}
export default SuitabilityButtons
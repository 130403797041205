import AppleSignInBtn from './AppleSignInBtn'
import GoogleSignInBtn from './GoogleSignInBtn'

const SignInOAuthBtns = ({ oAuthToken, type = 'Signup', onOAuthSuccess }) => {
  if (oAuthToken) {
    return null
  }

  const copy = type === 'Signup' ? 'Sign up with ' : 'Sign in with '
  return (
    <div className='signup-oath-container'>
      <GoogleSignInBtn
        copy={`${copy} Google`}
        onOAuthSuccess={onOAuthSuccess}
        type={type}
      />
      <AppleSignInBtn
        copy={`${copy} Apple ID`}
        onOAuthSuccess={onOAuthSuccess}
      />
      Or
    </div>
  )
}

export default SignInOAuthBtns

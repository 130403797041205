
export const handleReCaptchaVerify = async (executeRecaptcha, action) => {
  if (!executeRecaptcha) {
    console.log('Execute recaptcha not yet available')
    return
  }

  const token = await executeRecaptcha(action)
  // Do whatever you want with the token
  return token
}

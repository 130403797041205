const parsingInvestorStatus = (data) => {
  const documents = data.documents || []
  const RDocuments = []
  const ODocuments = []
  for (let i = 0; i < documents.length; i++) {
    const ele = documents[i]
    if (
      ele.name === 'Last year W2' ||
      ele.name === 'Previous year W2' ||
      ele.name === 'Last year tax returns' ||
      ele.name === 'Previous year tax returns' ||
      ele.name === 'Last year 1099' ||
      ele.name === 'Previous year 1099' ||
      ele.name === 'Spouse Last year W2' ||
      ele.name === 'Spouse Previous year W2' ||
      ele.name === 'Spouse Last year tax returns' ||
      ele.name === 'Spouse Previous year tax returns' ||
      ele.name === 'Spouse Last year 1099' ||
      ele.name === 'Spouse Previous year 1099'
    ) {
      RDocuments.push(ele)
    } else {
      ODocuments.push(ele)
    }
  }

  return {
    isSignedIn: !!data.isSignedIn,
    accreditedCountry: data.accreditedCountry ? data.accreditedCountry : '',
    accreditedStatus: data.accreditedStatus ? data.accreditedStatus : '',
    selfAccreditation: data.selfAccreditation ? data.selfAccreditation : '',
    hasRequestedFinancialAdvisor: !!data.hasRequestedFinancialAdvisor,
    allowDocumentDelete: !!data.allowDocumentDelete,
    RDocuments,
    ODocuments,
    documents: data.documents ? data.documents : [],
    crdNumber: data.crdNumber ? data.crdNumber : '',
    hasKyc: !!data.hasKyc,
    isUserProfileComplete: !!data.isUserProfileComplete,
    accreditedCountrySource: data.accreditedCountrySource ? data.accreditedCountrySource : '',
    accreditationRegion: data?.accreditationRegion || '',
    userFullName: data?.userFullName || '',
    isRetailPurchaser: !!data.isRetailPurchaser,
    email: data?.userEmail || '',
    legalName: data?.userLegalName || '',
    hasTakenAccreditationQuiz: !!data?.hasTakenAccreditationQuiz,
    assetsAccounts: data?.assetsAccreditation?.assetAccounts || [],
    isProcessing: data?.assetsAccreditation?.isProcessing || false,
    documentAccreditationIsProcessing: !!data?.documentAccreditationIsProcessing,
    canAttestAsAccreditation: data?.canAttestAsAccreditation
  }
}

export default parsingInvestorStatus

import React from 'react'
import { useSelector } from 'react-redux'
import RecurringItem from './RecurringItem'
import HistoryTitle from './HistoryTitle'
import { ConditionalRender } from 'components'

const RecurringInvestmentEvents = ({ showCompany = true, groupBy }) => {
  const { recurringEvents, entities } = useSelector(
    (state) => state.portfolioSlice
  )
  const { firstName, lastName, featureFlags } = useSelector((state) => state.userSlice)
  const { RecurringInvestmentEnabled } = featureFlags || {}
  const hasEntities = entities?.length > 0

  if (!recurringEvents || !recurringEvents.length || !RecurringInvestmentEnabled) {
    return null
  }

  const eventsByAccount = (filteredEvents) => {
    if (filteredEvents.length > 0) {
      return filteredEvents.map((event) => (
        <RecurringItem
          key={event.recurringEventId}
          event={event}
        />
      ))
    }
  }

  return (
    <div className='history-grid-container'>
      <ConditionalRender
        isVisible={!hasEntities || (hasEntities && groupBy === 'investment')}
      >
        <h3 className='heading_7'>Recurring Investment</h3>
        {recurringEvents?.length
          ? recurringEvents.map((event) => (
            <RecurringItem
              key={event.recurringEventId}
              event={event}
            />
          ))
          : null}
      </ConditionalRender>
      <ConditionalRender isVisible={hasEntities && groupBy === 'account'}>
        {!showCompany && <h3 className='heading_7'>Recurring Investment</h3>}
        {recurringEvents.filter((item) => !('entityId' in item)).length > 0 &&
        <>
          <HistoryTitle account={`${firstName} ${lastName}`} type='history' showCompany={showCompany} />
          {eventsByAccount(
            recurringEvents.filter((item) => !('entityId' in item))
          )}
        </>
        }
        {entities.map((entity, i) => (
          recurringEvents.filter((a) => a.entityId === entity.entityId).length > 0 &&
          <div key={`entity_${i}`}>
            <HistoryTitle account={entity.name} type='history' showCompany={showCompany}/>
            {eventsByAccount(
              recurringEvents.filter((a) => a.entityId === entity.entityId)
            )}
          </div>
        ))}
      </ConditionalRender>
    </div>
  )
}

export default RecurringInvestmentEvents

const TransactionColumns = () => {
  return (
    <div className='nonclickable-grid-container__columns cash_history'>
      <span>DATE</span>
      <span>DESCRIPTION</span>
      <span className='align-right'>AMOUNT</span>
    </div>
  )
}

export default TransactionColumns

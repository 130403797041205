import Button from 'components/Global/Button'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const ProfileNextBtn = ({
  onSubmit
}) => {
  const {
    handleSubmit
  } = useFormContext()
  const { profileLoading } = useSelector(state => state.profile)
  const disabled = profileLoading
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        handleSubmit(onSubmit)
      }}
    >
      Next
    </Button>
  )
}

export default ProfileNextBtn

import { images } from 'assets'

const OriginationHeader = () => {
  return (
    <div className='origination-header'>
      <img src={images['origination-hero-desktop']} className='origination-backgeound' alt='Origination background'/>
      <img src={images['origination-hero-mobile']} className='origination-backgeound-mobile' alt='Origination background'/>
      <div className='page-container'>
        <div className='inner-container'>
          <div className='origination-copy'>
            <h1>Turn your shares into your dreams.</h1>
            <div className='page-sub-title'>We make it easy for you to sell us your privately held shares of companies valued at $1B or more.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OriginationHeader

import 'rc-slider/assets/index.css'
import { Wrapper, Content, ConditionalRender } from 'components'
import Button from 'components/Global/Button'
import OrderDetails from './OrderDetails'
import { useState } from 'react'
import PricingIndicatorModal from './PricingIndicatorModal'
import { useSelector, useDispatch } from 'react-redux'
import PlaceOrderHeader from './PlaceOrderHeader'
import Toggle from 'components/Global/Toggle'
import { toggleInterest } from 'slices/investSlice'
import { useHistory } from 'react-router-dom'
import { Mixpanel, Fullstory } from 'utils'
import InCompleteProfileSection from './InCompleteProfileSection'
import NotifyMeCopy from './NotifyMeCopy'
import ScrollToTopBtn from './ScrollToTopBtn'
import InCompleteAccreditationSection from './InCompleteAccreditationSection'
import { useQuery } from 'hooks/useQuery'
import FinraCompleteProfileModal from './FinraCompleteProfileModal'
import { useLDFlags } from 'utils/LaunchDarkly'

const PlaceOrder = ({
  investAmount,
  setAmount,
  stickyHeader,
  setShares,
  shares,
  setSharePrice,
  sharePrice,
  totalTillNextTier,
  setTotalTillNextTear,
  totalSavings,
  setTotalSavings,
  isPreferred,
  setIsPreferred,
  tiers,
  setTiers,
  discountPercent,
  setDiscountPercent,
  discountedAmount,
  setDiscountedAmount,
  setShowQuestionsModal
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const query = useQuery()
  const promo = query.get('promo')
  const user = useSelector((state) => state.userSlice)
  const {
    featureFlags: { RetailPurchaseAllowed }
  } = user || {}
  const { details, canPayWithShares, isFinraUser, isFinraNotConfirmedUser } = useSelector((state) => state.investSlice)
  const { tradeButtonExperiment } = useLDFlags(['tradeButtonExperiment'])
  const [showTierModal, setShowTierModal] = useState(false)
  const [showFinraCompleteProfileModal, setShowFinraCompleteProfileModal] =
    useState(false)
  const {
    isSoldOut,
    promoFlag,
    companyId,
    name,
    sliderMinimum,
    urlName,
    discountNote,
    offers,
    isInterested,
    retailPurchaseEnabled
  } = details
  const { isPreferredCustomer } = user || {}
  const { isProfileComplete, isAccredited, isRetailPurchaser } =
    details?.user || {}
  const firstOfferShares = offers?.[0]?.shares || 0
  const isFirstOffer = firstOfferShares >= shares
  const disableBtn =
    (!isProfileComplete ||
      (!isAccredited && !isRetailPurchaser) ||
      (!isAccredited && !RetailPurchaseAllowed) ||
      (!isAccredited && !retailPurchaseEnabled)) &&
    !isFinraUser &&
    !isFinraNotConfirmedUser

  const placeOrder = (e, fromTradeShares = false) => {
    e?.preventDefault()
    Mixpanel.register({ 'Team Apollo': isPreferredCustomer })
    Mixpanel.track(fromTradeShares ? 'Trade Shares Button Clicked' : 'Place Order Started', {
      'Minimum investment amount': sliderMinimum,
      'Amount selected': investAmount,
      'Estimated shares': shares,
      'Company Name': name
    })
    Fullstory.track('Buy Order Start', {
      minimum_investment_amount: sliderMinimum,
      amount_selected: investAmount,
      estimated_shares: shares,
      company_name: name
    })
    history.push({
      pathname: '/orderconfirmation/cash',
      search: `?companyId=${companyId}&companyName=${name}&amount=${investAmount}&type=CASH&shares=${shares}&minimumInvestmentAmount=${sliderMinimum}&urlName=${urlName}&discountedAmount=${discountedAmount}&discountNote=${discountNote}${
        promo ? `&promo=${promo}` : ''
      }${fromTradeShares ? '&fromTradeShares=true' : ''}`
    })
  }

  const handleChange = () => {
    Mixpanel.track('Click Notify Me on Product Page', {
      'Company Name': name,
      Toggle: !isInterested
    })
    dispatch(
      toggleInterest({ companyUrlName: urlName, isInterested: !isInterested })
    )
  }

  const handleGoToOrder = (e, tradeShares) => {
    if (isFinraUser || isFinraNotConfirmedUser) {
      setShowFinraCompleteProfileModal(true)
    } else tradeShares ? placeOrder(e, true) : placeOrder()
  }

  return (
    <Wrapper className='product-order invest-order'>
      {isSoldOut && (
        <Toggle
          label={`Notify me ${
            promoFlag === 'COMING_SOON' ? 'when' : 'if'
          } this becomes available.`}
          handleChange={handleChange}
          checked={isInterested}
          ariaLabel='notifyMeSwitch'
        />
      )}
      {showTierModal && tiers?.length >= 1 && isFirstOffer && (
        <PricingIndicatorModal
          hideModal={() => setShowTierModal(false)}
          tiers={tiers}
        />
      )}
      <Content>
        <PlaceOrderHeader sharePrice={sharePrice} />
        {!isSoldOut && (
          <OrderDetails
            setAmount={setAmount}
            setShares={setShares}
            shares={shares}
            setSharePrice={setSharePrice}
            onTierPress={() => isFirstOffer && setShowTierModal(true)}
            totalTillNextTier={totalTillNextTier}
            setTotalTillNextTear={setTotalTillNextTear}
            totalSavings={totalSavings}
            setTotalSavings={setTotalSavings}
            isPreferred={isPreferred}
            setIsPreferred={setIsPreferred}
            tiers={tiers}
            setTiers={setTiers}
            isFirstOffer={isFirstOffer}
            discountPercent={discountPercent}
            setDiscountPercent={setDiscountPercent}
            discountedAmount={discountedAmount}
            setDiscountedAmount={setDiscountedAmount}
          />
        )}
        <ConditionalRender
          isVisible={!isSoldOut && tradeButtonExperiment && canPayWithShares}
          style={{ display: 'flex', flexDirection: 'row' }}
          className='cta-buttons'
        >
          <Button
            customClass={`${stickyHeader ? 'sticky-content' : ''}`}
            mode='secondary'
            style={{ marginRight: '12px' }}
            onClick={(e) => handleGoToOrder(e, true)}
            disabled={disableBtn}
          >
            Trade Shares
          </Button>
          <Button
            customClass={`${stickyHeader ? 'sticky-content' : ''}`}
            style={{ marginLeft: '12px' }}
            onClick={handleGoToOrder}
            disabled={disableBtn}
          >
            Begin Order
          </Button>
        </ConditionalRender>
        <ConditionalRender
          isVisible={
            (!isSoldOut && !tradeButtonExperiment) ||
            (!isSoldOut && !canPayWithShares)
          }
        >
          <Button
            customClass={`full-width ${stickyHeader ? 'sticky-content' : ''}`}
            onClick={handleGoToOrder}
            disabled={disableBtn}
          >
            Begin Order
          </Button>
        </ConditionalRender>
        {!isFinraUser && !isFinraNotConfirmedUser && (
          <>
            <InCompleteProfileSection setShowQuestionsModal={setShowQuestionsModal} />
            <InCompleteAccreditationSection />
          </>
        )}
        <NotifyMeCopy />
      </Content>
      {showFinraCompleteProfileModal && (
        <FinraCompleteProfileModal
          hideModal={() => setShowFinraCompleteProfileModal(false)}
          actionAfterSave={placeOrder}
          showEmailAndPassword={isFinraNotConfirmedUser}
        />
      )}
      <ScrollToTopBtn stickyHeader={stickyHeader} />
    </Wrapper>
  )
}
export default PlaceOrder

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'

const initialState = {
  companyName: '',
  companyIconUrl: '',
  ipoDetails: {
    lockUpPeriodEndDisplayDate: '',
    latestNews: []
  },
  sharesOwners: [],
  didSubmitSelection: { user: false, entity: false },
  canChangeDecision: false
}

/**
 * GET the ipo company data for a given company
 * @function getCompanyIpoData
 * @param {Id} companyId - companyId from url
 * @returns - company data
 */
export const getCompanyIpoData = createAsyncThunk('getCompanyIpoData', async (companyId, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.get(`/page/companyIpoData/${companyId}`)
    return fulfillWithValue(response.data)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * SAVE the user selection for a given company that will IPO
 * @function saveUserCompanyIpoChoice
 * @param {obj} Object
 */
export const saveUserCompanyIpoChoice = createAsyncThunk('saveUserCompanyIpoChoice', async (obj, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.post('/page/companyIpoUserData', obj)
    return fulfillWithValue(response.data)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

export const exitSlice = createSlice({
  name: 'exit',
  initialState,
  reducers: {
    // flag to check if user has already submitted ipo choice
    resetSubmitSelection: (state, { payload }) => {
      state.didSubmitSelection[payload] = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyIpoData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          didSubmitSelection: { user: !!(payload?.sharesOwners?.[0]?.transferChoice), entity: !!(payload?.sharesOwners?.[1]?.transferChoice) }
        }
      })
      .addCase(saveUserCompanyIpoChoice.fulfilled, (state, { payload }) => ({
        ...state,
        didSubmitSelection: { user: !payload?.entityId, entity: !!payload?.entityId }
      }))
  }
})

export const { resetSubmitSelection } = exitSlice.actions

export default exitSlice.reducer

import * as yup from 'yup'
import { checkIfNotNumber, formatDecimal } from 'utils'

export const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Please enter an amount.')
    .test(
      'ChechNumber',
      'Please enter an amount.',
      (val) => !checkIfNotNumber(val?.charAt(0) === '$' ? val.substring(1) : val)
    )
    .when('$achLimit', (achLimit, schema) => {
      return schema.test(
        'len',
        `Amount cannot exceed ${formatDecimal(achLimit)}`,
        (val) => {
          const updatedValue = val?.charAt(0) === '$' ? val.substring(1) : val
          return updatedValue <= achLimit
        }
      )
    }
    )
})

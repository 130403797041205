const parseCashAccount = (data) => {
  const pendingCashExternalAccounts = data?.cashExternalAccounts?.filter((a) => a.amlStatus === 'NEEDS_REVIEW' || a.amlStatus === 'CS_NEEDS_REVIEW') || []
  const approvedCashExternalAccounts = data?.cashExternalAccounts?.filter((a) => a.amlStatus === 'APPROVED') || []
  return {
    ...data,
    pendingCashExternalAccounts,
    approvedCashExternalAccounts,
    upholdUnderReview: (data?.upholdAmlStatus === 'NEEDS_REVIEW' || data?.upholdAmlStatus === 'CS_NEEDS_REVIEW') && data?.hasUphold,
    // NOTE: UKNOWN is treated as APPROVED for now for legacy UPhold Access
    upholdIsApproved: (data?.upholdAmlStatus === 'APPROVED' || data?.upholdAmlStatus === 'UNKNOWN') && data?.hasUphold
  }
}

export default parseCashAccount

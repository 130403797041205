const Toggle = ({ checked, handleChange, label, disabled = false, ariaLabel = '' }) => {
  return (
    <div className='toggle-switch-container'>
      <label className='b_18_regular toggle-switch-container-label'>{label}</label>
      <div>
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='toggle-switch-checkbox'
            name={`toggleSwitch_${ariaLabel}`}
            id={`toggleSwitch_${ariaLabel}`}
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            aria-label={ariaLabel}
          />
          <label className={`toggle-switch-label ${disabled ? 'toggle-switch-disabled ' : ''}`} htmlFor={`toggleSwitch_${ariaLabel}`}>
            <span className='toggle-switch-inner' />
            <span className='toggle-switch-switch' />
          </label>
        </div>
      </div>
    </div>
  )
}

export default Toggle

import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const InvestDetailValuationRounds = ({ fundingRounds }) => {
  return (
    <>
      <div className='grid unicorn-table invest-table desktop-content'>
        <div className='unicorn-table-header row'>
          <div className='column five max-15 b_16_semibold'>Date</div>
          <div className='column five b_16_semibold'>Round</div>
          <div className='column five max-15 b_16_semibold'>Amount</div>
          <div className='column five max-15 b_16_semibold'>Valuation</div>
          <div className='column five min-35 b_16_semibold'>Investors</div>
        </div>
        { fundingRounds && fundingRounds.length > 0 && fundingRounds.map((f, i) =>
          <div key={`fundingRound_${i}`} className='row unicorn-table-body'>
            <div className='column five dark-color max-15 b_16_regular'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
            <div className='column five dark-color b_16_regular'>{f.round}</div>
            <div className='column five dark-color max-15 b_16_regular'>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</div>
            <div className='column five dark-color max-15 b_16_regular'>{f.valuation && formatNumberWithLetter(f.valuation, true, true, 1, true)}</div>
            <div className='column five dark-color min-35 b_16_regular'>
              {f.investors && f.investors.length > 0 && f.investors.map(function (obj) {
                return obj.name
              }).join(', ')}
            </div>
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {fundingRounds && fundingRounds.length > 0 && fundingRounds.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div className='container-margin'>
              <div className='row unicorn-card-row'>
                <div className='column eight-mobile no-padding-horizontal'>
                  <span className='b_16_semibold gray5'>Date: </span>
                  <span className='b_16_regular gray3'>
                    {f.date && moment(f.date).format('MM/DD/YYYY')}
                  </span>
                </div>
                <div className='column eight-mobile no-padding-horizontal'>
                  <span className='b_16_semibold gray5'>Round: </span>
                  <span className='b_16_regular gray3'>{f.round}</span>
                </div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column eight-mobile no-padding-horizontal'>
                  <span className='b_16_semibold gray5'>Amount: </span>
                  <span className='b_16_regular gray3'>{f.amount && formatNumberWithLetter(f.amount, true, true, 1, true)}</span>
                </div>
                <div className='column eight-mobile no-padding-horizontal'>
                  <span className='b_16_semibold gray5'>Valuation: </span>
                  <span className='b_16_regular gray3'>{f.amount && formatNumberWithLetter(f.valuation, true, true, 1, true)}</span>
                </div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='sixteen'>
                  <span className='b_16_semibold gray5'>Investors: </span>
                  <span className='b_16_regular gray3'>{f.investors && f.investors.length > 0 && f.investors.map(({ name }) => name)?.join(', ')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default InvestDetailValuationRounds

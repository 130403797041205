import Checkbox from 'components/Global/Inputs/Checkbox'
import { useFormContext } from 'react-hook-form'

const SameAddress = ({ profileLoading }) => {
  const {
    clearErrors,
    setValue
  } = useFormContext()

  return (
    <div className='column eight sixteen-mobile'>
      <Checkbox
        label='This is not my mailing address'
        name='sameAddress'
        disabled={profileLoading}
        onChange={(e) => {
          if (e.target.checked) {
            setValue('mStreet1', '')
            setValue('mStreet2', '')
            setValue('mCity', '')
            setValue('mState', '')
            setValue('mZip', '')
            setValue('mCountry', '')
          } else {
            clearErrors('mStreet1')
            clearErrors('mCity')
            clearErrors('mState')
            clearErrors('mZip')
            clearErrors('mCountry')
          }
        }}
      />
    </div>
  )
}

export default SameAddress

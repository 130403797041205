import { useEffect, useState } from 'react'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import SuccessModal from './partials/SuccessModal'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getBrokerInfo, submitBrokerInfo } from 'slices/brokerSlice'
import { schema } from 'schemas/brokerInfoSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import BrokerInfoForm from './partials/BrokerInfoForm'
import { useQuery } from 'hooks/useQuery'
import { useHistory, useParams } from 'react-router-dom'

const BrokerInfo = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const redirect = query.get('redirect')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })
  const { initialValues, pageLoading, submitLoading } = useSelector((state) => state.broker)
  const { entityId } = useParams() || null

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBrokerInfo(entityId))
    }
    fetchData()
  }, [])

  useEffect(() => {
    methods.reset(initialValues)
  }, [initialValues])

  const onSubmit = async (formValues) => {
    await dispatch(submitBrokerInfo({ ...formValues, entityId }))
    // for exit-ipo page, once user updates broker info, go back to exit-ipo detail
    if (redirect) {
      history.goBack()
    }
    setSubmitSuccess(true)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Broker Information')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Broker Information')} />
      <FormProvider {...methods}>
        <div className='profile-container page-container'>
          <div className='inner-container'>
            <h1>Broker Information</h1>
            <div className='b_18_regular'>
              We&apos;ll need this to distribute your shares in the event of an
              exit.
            </div>
            <BrokerInfoForm onSubmit={onSubmit} loading={submitLoading} />
          </div>
        </div>
        {submitSuccess && (
          <SuccessModal />
        )}
      </FormProvider>
    </>
  )
}

export default BrokerInfo

import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { confirmSellOrder, getSellOrderSummary } from 'slices/sellOrderSlice'
import { seoTitleTemplate, Mixpanel, getDomain } from 'utils'
import SellOrderHeader from '../partials/SellOrderHeader'
import SellOrderContainer from '../partials/SellOrderContainer'
import ErrorModal from 'components/Global/ErrorModal'
import { sellOrderErrors } from 'components/data'
import qs from 'qs'

const SellOrderSummary = () => {
  const location = useLocation()
  const methods = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { handleSubmit } = methods
  const { sharePrice, subTotal, loading } = useSelector(state => state.sellOrder)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [summaryError, setSummaryError] = useState({})
  const [pageLoading, setPageLoading] = useState({})

  const query = location.search
  const { companyUrlName, shareCount, entityId } = qs.parse(query, { ignoreQueryPrefix: true })

  const renderErrorModal = (error) => {
    if (error) {
      Mixpanel.track('Sell Order Error', { 'Error Type': error.mixPanel })
      setSummaryError(error)
      setShowErrorModal(true)
    }
  }

  useEffect(() => {
    getSellOrderSummaryPage()
  }, [])

  const getSellOrderSummaryPage = () => {
    setPageLoading(true)
    dispatch(getSellOrderSummary({ companyUrlName, shareCount, entityId })).then(({ meta, payload }) => {
      setPageLoading(false)
      if (meta.requestStatus === 'fulfilled') {
        if (payload && !payload.sharePrice) {
          renderErrorModal(sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE)
        } else {
          Mixpanel.track('View Sell Order Review Page', { 'Company Name': payload?.companyName, Shares: shareCount, 'Share Price': sharePrice })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (sellOrderErrors[payload]) {
          renderErrorModal(sellOrderErrors[payload])
        }
      }
    })
  }

  const onSubmit = async () => {
    const { meta, payload } = await dispatch(confirmSellOrder({ companyUrlName, numberOfShares: shareCount, sharePrice, totalPrice: subTotal, entityId }))
    if (meta.requestStatus === 'fulfilled') {
      history.push(`/sell-order/${payload?.sellOrderId}`)
    } else if (meta.requestStatus === 'rejected') {
      if (payload === 'PLACE_ORDER_SELL_TOO_SOON' || payload === 'PLACE_ORDER_NEGATIVE_CASH_BALANCE') {
        setErrorMessage(sellOrderErrors[payload].message)
        Mixpanel.track('Sell Order Error', { 'Error Type': sellOrderErrors[payload].mixPanel })
      } else if (sellOrderErrors[payload]) {
        renderErrorModal(sellOrderErrors[payload])
      } else {
        Mixpanel.track('Sell Order Error', { 'Error Type': 'Unknown' })
      }
    }
  }

  const handleBack = event => {
    event.preventDefault()
    history.goBack()
  }

  const hideErrorModal = () => {
    setShowErrorModal(false)
    if (summaryError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED') {
      getSellOrderSummaryPage()
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sell Order Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Sell Order Summary')} />
      <FormProvider {...methods}>
        <div className='page-container sell-order-container'>
          <form className='inner-container' onSubmit={handleSubmit(onSubmit)}>
            <SellOrderHeader title='Sell Order Summary'/>
            <div className='sell-order-page-container'>
              <SellOrderContainer titleCopy='Please review your sell offer.' type='summary' />
              <span className='customer-support-copy'>
                All sales are final.
              </span>
              <div className='btn-group right'>
                <Button mode='tertiary' style={{ marginRight: '10px' }} onClick={handleBack}>Go Back</Button>
                <Button disabled={loading || errorMessage} loading={loading}>Submit</Button>
              </div>
              {errorMessage && <div className='b_18_regular error-message'>{errorMessage}</div>}
              <div className='b_18_regular space-above-sm'>
              Have a question about your order? {' '}
                Visit our <a
                  className='link primary'
                  href={getDomain('/sell-order/how-it-works/', true)}
                  target='_blank'
                  rel='noreferrer'
                  role='button'
                  onClick={() => Mixpanel.track('Click See How It Works on Sell Order Review Page') }
                >Selling FAQS</a> or{' '}
                <a onClick={() => {
                  Mixpanel.track('Click Contact Us', { Location: 'Sell Order Summary' })
                  history.push('/contact?topic=Order Support&message=Re: Sell Order')
                }}
                className='link primary contact-us' data-testid='contact-us-link' role='button'>Contact Us</a>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
      {showErrorModal && <ErrorModal error={summaryError} crossToClose={true} hideModal={hideErrorModal} message={summaryError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED' ? 'Review' : 'OK'} />}
    </>
  )
}

export default SellOrderSummary

import PropTypes from 'prop-types'
import Select, { components as SelectComponents } from 'react-select'
import TooltipLabel from '../TooltipLabel'
import { images } from 'assets'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'

// Styles to modify react select style
const baseStyle = {
  control: {
    boxShadow: 0,
    borderRadius: 8,
    cursor: 'pointer'
  },
  border: '1px solid #717171',
  borderActive: '1px solid #0039ec',
  borderError: '1px solid #ff0000',
  placeholder: {
    color: '#333',
    fontWeight: 600,
    top: '47%',
    margin: '0 !important'
  }
}
const customStyles = {
  container: base => ({
    ...base
  }),
  indicatorsContainer: base => ({
    ...base
  }),
  placeholder: base => ({
    ...base,
    ...baseStyle.placeholder
  }),
  option: (style, state) => ({
    ...style,
    background: state.isSelected ? '#F2F2F2' : 'white',
    marginBottom: 1,
    color: '#000000',
    '&:active': {
      background: 'none'
    }
  }),
  multiValue: base => ({
    ...base,
    background: '#F2F2F2',
    borderRadius: 8,
    height: 33,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 5,
    marginRight: 5
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#050505',
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    wrap: 'flex-wrap'
  }),
  multiValueRemove: base => ({
    ...base,
    '&:hover': {
      background: 'none'
    }
  })
}

const errorStyles = {
  placeholder: base => ({
    ...base,
    ...baseStyle.placeholder
  })
}

// Custom components replacing existing react-select components
const customComponents = {
  DropdownIndicator: (props) => (
    <SelectComponents.DropdownIndicator {...props}>
      <img style={{ height: 25, width: 25 }} src={images['chevron-down']} alt='Open Dropdown' />
    </SelectComponents.DropdownIndicator>
  ),
  ClearIndicator: () => null,
  IndicatorSeparator: () => null,
  MultiValueRemove: (props) => (
    <SelectComponents.MultiValueRemove {...props}>
      <img style={{ height: 12, width: 12 }} src={images['remove-X']} alt='Remove Option' />
    </SelectComponents.MultiValueRemove>
  )
}

// Note: options and selectedOptions is an array of objects with value and label keys
const MultiSelect = ({ name, placeholder = '', className = '', optionsList, selectedOptions, field, label, extraLabel, content = '' }) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    watch
  } = useFormContext()
  return (
    <div className={`input-group ${className}`}>
      <TooltipLabel content={content} label={label} extraLabel={extraLabel} />
      <Select
        {...field}
        aria-label={name}
        styles={errors[name] ? {
          ...errorStyles,
          control: (style) => ({
            ...style,
            ...baseStyle.control,
            height: watch(name)?.length > 0 ? 'auto' : '48px',
            border: baseStyle.borderError,
            '&:hover': {
              border: baseStyle.borderError
            }
          })
        } : {
          ...customStyles,
          control: (style, state) => ({
            ...style,
            ...baseStyle.control,
            height: watch(name)?.length > 0 ? 'auto' : '48px',
            border: state.isSelected ? baseStyle.borderActive : baseStyle.border,
            '&:hover': {
              border: baseStyle.borderActive
            }
          }),
          valueContainer: base => ({
            ...base,
            padding: watch(name)?.length > 0 ? '0' : '0 16px'
          })
        }}
        components={customComponents}
        defaultValue={selectedOptions}
        placeholder={placeholder}
        onChange={(options) => {
          setValue(name, options, { shouldDirty: true })
          clearErrors(name)
        }}
        options={optionsList}
        isMulti
        isSearchable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

MultiSelect.propTypes = {
  optionsList: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  setSelectedOptions: PropTypes.func,
  label: PropTypes.string,
  content: PropTypes.any
}

MultiSelect.defaultProps = {
  optionsList: [],
  selectedOptions: null
}

export default MultiSelect

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import { setUserIsSignedIn } from 'slices/userSlice'
import api from '../apis/private.js'
import parsingUnicorns from 'actions/parsing/parsingUnicorns'

const initialState = {
  companyId: null,
  name: '',
  logoUrl: '',
  urlName: '',
  websiteUrl: '',
  foundedYear: null,
  sector: '',
  valuation: null,
  sharePrice: null,
  stage: '',
  totalRaised: null,
  lastRaised: null,
  lastRaisedDate: '',
  description: '',
  address: {},
  mosaic: {},
  investors: '',
  officers: [],
  boardMemebers: [],
  fundingRounds: [],
  competitorsFundings: {},
  partners: []
}

export const getUnicornsDetails = createAsyncThunk('getUnicornsDetails', async ({ companyName }, { dispatch, fulfillWithValue }) => {
  try {
    const res = await api.get(`/page/unicorns/${companyName}`)
    if (res.data) {
      if (res.data.isLoggedIn) {
        dispatch(setUserIsSignedIn())
      }
      return fulfillWithValue(parsingUnicorns(res.data))
    }
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
  }
})

export const setInterestedInUnicorn = createAsyncThunk('setInterestedInUnicorn', async ({ urlName, isInterested }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    await api.post(`/page/unicorns/${urlName}`, { interested: isInterested })
    return fulfillWithValue({ isInterested })
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
    return rejectWithValue(err.response.data.error)
  }
})

export const unicornsSlice = createSlice({
  name: 'unicorns',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUnicornsDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(setInterestedInUnicorn.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
  }
})

export default unicornsSlice.reducer

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel, getDomain } from 'utils'

const FooterLinks = () => {
  const { isSignedIn } = useSelector((state) => state.userSlice)
  const supportItem = [
    { route: '/faq', name: 'FAQs', cms: true, label: 'Read FAQs' },
    { route: '/contact', name: 'Contact Us', cms: false, label: 'Contact Us' },
    { route: '/signup', name: 'Get Started', cms: false, label: 'Sign up with Linqto' },
    { route: '/', name: 'Help Center', cms: 'help.linqto.com', label: 'Help Center' }
  ]
  const forInvestorItem = [
    { route: '/products', name: 'Invest', cms: !isSignedIn, label: 'Invest with Linqto' },
    { route: '/market-insights', name: 'Insights', cms: true, label: 'Read Market Insights' },
    { route: '/blog', name: 'Blog', cms: true, label: 'Read our Blog' }
  ]
  const companyItem = [
    { route: '/about', name: 'About Us', cms: true, label: 'Learn about Linqto' },
    { route: '/team', name: 'Team', cms: true, label: 'Meet Our Team' },
    { route: '/partners', name: 'Partners', cms: true, label: 'Meet Our Partners' },
    { route: '/terms-of-use', name: 'Terms of Use', cms: true, label: 'Read Terms of Use' },
    { route: '/crs', name: 'Form CRS', cms: true, label: 'Read Form CRS' },
    { route: '/privacy', name: 'Privacy', cms: true, label: 'Read Privacy Policy' },
    { route: '/press', name: 'Press', cms: true, label: 'Read Press Releases' },
    { route: '/careers', name: 'Careers', cms: true, label: 'Join Our Careers' }
  ]

  const forEquityHolderItem = [{ route: '/sell', name: 'Sell to Linqto', cms: true, label: 'Sell Equity to Linqto' }]
  const trackLink = (link) => {
    Mixpanel.track('Click Footer Link', { 'Footer Link': link })
    if (link === 'Invest') {
      localStorage?.removeItem('investFeedScroll')
    }
  }

  const renderLinkItem = (route, name, cms, label) => {
    if (isSignedIn && name === 'Sign up') {
      return
    }
    if (route === '/crs') {
      return (
        <div key={name}>
          <a
            role='button'
            tabIndex={0}
            key={`footer-${name}`}
            target="_blank"
            rel="noreferrer"
            href={'https://files.brokercheck.finra.org/crs_314557.pdf'}
            onClick={() => trackLink(name)}
            className='medium_1 text-gray2 hover:text-white'
            aria-label={label}
          >
            {name}
          </a>
        </div>
      )
    }
    if (cms) {
      return (
        <div key={name}>
          <a
            role='button'
            tabIndex={0}
            key={`footer-${name}`}
            href={getDomain(route, cms)}
            onClick={() => trackLink(name)}
            className='medium_1 text-gray2 hover:text-white'
            aria-label={label}
          >
            {name}
          </a>
        </div>
      )
    } else {
      return (
        <div key={name}>
          <Link
            to={route}
            className='medium_1 text-gray2 hover:text-white'
            onClick={() => trackLink(name)}
            role='button'
            tabIndex='0'
            aria-label={label}
          >
            {name}
          </Link>
        </div>
      )
    }
  }
  return (
    <div className='flex justify-center mt-10 md:justify-between md:mt-14'>
      <div className='flex-col w-1/2'>
        <div className='flex flex-col'>
          <div className='mb-4 medium_4'>Support</div>
          {supportItem.map((i) => renderLinkItem(i.route, i.name, i.cms))}
        </div>
        <div className='flex flex-col'>
          <div className='mt-10 mb-4 medium_4'>For Investors</div>
          {forInvestorItem.map((i) =>
            renderLinkItem(i.route, i.name, i.cms, i.label)
          )}
        </div>
      </div>
      <div className='flex-col w-1/2'>
        <div className='flex flex-col'>
          <div className='mb-4 medium_4'>Company</div>
          {companyItem.map((i) => renderLinkItem(i.route, i.name, i.cms))}
        </div>
        <div className='flex flex-col'>
            <div className='mt-10 mb-4 medium_4'>For Equity Holders</div>
            {forEquityHolderItem.map((i) =>
              renderLinkItem(i.route, i.name, i.cms)
            )}
        </div>
      </div>
    </div>
  )
}

export default FooterLinks

import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { images } from 'assets'
import AddFundsModalItem from './AddFundsModalItem'
import { connectUphold } from 'slices/walletSlice'
import { Mixpanel } from 'utils'

const UpholdFundingMethod = () => {
  const dispatch = useDispatch()

  const connectUpholdWallet = () => {
    sessionStorage.setItem('connectUpholdAddFunds', true)
    Cookies.set('redirectPath', location.pathname)
    Mixpanel.track('Click Link Account on Select Funding Method', { 'Account Type': 'Uphold' })
    dispatch(connectUphold()).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        window.open(payload, '_self')
      }
    })
  }

  return (
    <AddFundsModalItem
      onSelect={connectUpholdWallet}
      account={{
        label: 'Uphold',
        iconUrl: images['wallet-uphold'],
        description: 'Link your account. Funds are available immediately'
      }}
    />
  )
}

export default UpholdFundingMethod

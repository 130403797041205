import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'
import { images } from 'assets'

const ActAsMyselfButton = ({ viewAs }) => {
  const { masterUser } = useSelector((state) => state.ria)

  return (
    <div className='btn-group right act-as-myself-container'>
      <Button
        size='sm'
        ariaLabel='Act as myself'
        onClick={() => viewAs(masterUser.userId)}>
        Act as myself
        <img
          alt=' Act as myself'
          src={images.arrow_teal_right}
          className='view-icon'
        />
      </Button>
      {masterUser.isCurrentIdentity && (
        <span className='b_14_regular action-sub-title'>Currently active</span>
      )}
    </div>
  )
}

export default ActAsMyselfButton

import USDocumentUpload from './USDocumentUpload'
import { USDoceumentTypes } from '../../data'
import InvestorStatusSubHeader from './InvestorStatusSubHeader'
import SelfAccreditationSection from './SelfAccreditationSection'
import { useSelector } from 'react-redux'
import { ConditionalRender } from 'components'

const OtherRegCountriesSection = () => {
  const intialDocsToUpload = [{ id: 1, name: 'Select Document Type' }]
  const { selfAccreditation, accreditedStatus } = useSelector(
    (state) => state.investorStatusSlice
  )

  return (
    <>
      <InvestorStatusSubHeader />
      <ConditionalRender
        isVisible={
          selfAccreditation === 'FOREIGN_INVESTOR' &&
          accreditedStatus === 'UNDER_REVIEW'
        }
      >
        <div className='self-acc-container'>
          <SelfAccreditationSection
            selfAccreditationOptions={{
              FOREIGN_INVESTOR: 'Foreign Investor'
            }}
          />
        </div>
      </ConditionalRender>
      <USDocumentUpload
        dropDownList={USDoceumentTypes.OTHER_REG}
        intialDocsToUpload={intialDocsToUpload}
      />
    </>
  )
}

export default OtherRegCountriesSection

import UpholdWalletAccounts from './UpholdWalletAccounts'
import UpholdWalletHeader from './UpholdWalletHeader'
import { useSelector } from 'react-redux'

const UpholdWalletConnected = ({ disconnectUpholdWallet }) => {
  const { upholdIsApproved } = useSelector((state) => state.walletSlice)

  return (
    <>
      <UpholdWalletHeader
        connectUpholdWallet={() => {}}
        disconnectUpholdWallet={disconnectUpholdWallet}
      />
      {upholdIsApproved &&
      <UpholdWalletAccounts />
      }
    </>
  )
}

export default UpholdWalletConnected

import RequestModal from './RequestModal'

const FinancialProfessionalSection = ({
  showModal,
  setShowModal
}) => {
  return (
    <>
      <div className='verification-section request-container'>
        <div className='b_18_regular request-info'>
          <div>
          We can expedite Verification by having your third-party professional prepare a simple letter on your behalf that states:
          </div>
          <ul>
            <li>
              Their name, address, professional credentials, and their
              relationship to you, e.g., your  accountant, banker, broker, or
              attorney.
            </li>
            <li>
              They have access to your financial records and know your financial
              position.
            </li>
            <li>
              That on the basis of the above, they can attest that you have
              either the Gross Income or Net Worth to meet the requirements for
              Accredited Investor status.
            </li>
          </ul>
        </div>
        <div className='top-margin'>
        Enter the email address of your financial professional and we will request the document on your behalf. You will be copied on the message.
        </div>
      </div>
      {showModal && <RequestModal hideModal={() => setShowModal(false)} />}
    </>
  )
}

export default FinancialProfessionalSection

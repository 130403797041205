import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Mixpanel } from 'utils'
import { logEvent, restartKycProcess } from 'slices/kycSlice'
const MicroBlinkError = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const manualUpload = () => {
    history.push('/identity/manual-verification')
    dispatch(logEvent({ path: '/identityVerification/step2.5/manualUploadOptionSelected' }))
  }

  const handleRestart = () => {
    dispatch(logEvent({ path: '/identityVerification/step4/restart' }))
    Mixpanel.track('Onboarding Restart with New Document')
    dispatch(restartKycProcess())
  }
  return (
    <div className='page-container'>
      <div className='auto-verification-error'>
        <div className='b_18_regular'>
            We&apos;re having some trouble with your photo ID and self-photo. Please try again or submit your information for manual verification.
        </div>
        <div className='btn-group'>
          <a className='btn primary lg' href='/identity/auto-verification/1' onClick={() => handleRestart()}>Try Again</a>
          <Button
            onClick={() => manualUpload()}
          >
            Manual Verification
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MicroBlinkError

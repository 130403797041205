import * as yup from 'yup'
const regex = /\d+/g
export const schema = yup.object().shape({
  currentPsd: yup.string().required('Please enter the current password.'),
  newPsd: yup
    .string()
    .required('Please enter a new password.')
    .test(
      'len',
      'Password must be at least 8 characters long.',
      (val) => val.length > 7
    )
    .test('containsNum', 'Password must contain at least one number.', (val) =>
      val?.match(regex)
    ),
  confirmPsd: yup
    .string()
    .required('Please confirm your password.')
    .oneOf(
      [yup.ref('newPsd'), null],
      'Confirm password should match the new password above.'
    )
})

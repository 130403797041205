import { formatNumberWithLetter, formatDecimal } from 'utils'
const parseInvest = ({ accreditedStatus, announcements, cashPaymentEnabled, companies, hasSellableShares, hasWalletDeposit, isLoggedIn, isRetailPurchaser, needToCompleteInvestorProfile, userProfileIsComplete, verticals, wirePaymentEnabled }) => {
  const companyList = (companies) => {
    return companies.map(c => {
      let linqtoImpliedValuation = '-'
      let percentageVSLastRound = '-'
      if (c.sharePrice && c.impliedValuationSharePrice && c.impliedValuation) {
        linqtoImpliedValuation = (c.sharePrice / c.impliedValuationSharePrice) * c.impliedValuation
      }
      if (linqtoImpliedValuation !== '-' && c.lastFundingRoundValuation) {
        const rawLastRound = (linqtoImpliedValuation - c.lastFundingRoundValuation) / c.lastFundingRoundValuation * 100
        percentageVSLastRound = `${rawLastRound > 0 ? '+' : ''}${formatDecimal(rawLastRound, false, 0)}%`
      }

      return {
        ...c,
        linqtoImpliedValuation: linqtoImpliedValuation !== '-' ? formatNumberWithLetter(linqtoImpliedValuation, true, true, 1, true) : linqtoImpliedValuation,
        percentageVSLastRound
      }
    })
  }
  const parseCompanyList = companies ? companyList(companies) : []

  const topSellers = companies ? parseCompanyList.filter(p => p.topSeller) : []
  return {
    accreditedStatus: accreditedStatus || '',
    announcements: announcements || [],
    cashPaymentEnabled: cashPaymentEnabled || true,
    companies: companies ? parseCompanyList : [],
    hasSellableShares: hasSellableShares || false,
    hasWalletDeposit: hasWalletDeposit || false,
    isLoggedIn: isLoggedIn || true,
    isRetailPurchaser: isRetailPurchaser || false,
    needToCompleteInvestorProfile: !!needToCompleteInvestorProfile,
    userProfileIsComplete: userProfileIsComplete || false,
    verticals: verticals || [],
    wirePaymentEnabled: wirePaymentEnabled || false,
    retailCompanies: companies ? parseCompanyList.filter(p => p.retailPurchaseEnabled) : [],
    nonRetailCompanies: companies ? parseCompanyList.filter(p => !p.retailPurchaseEnabled) : [],
    topSellers: topSellers || []
  }
}

export default parseInvest

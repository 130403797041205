import useWindowSize from 'hooks/useWindowSize'
import { formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import { images } from 'assets'
import { Button } from 'components'

const AddFundsModalItem = ({ account, onSelect, disabled = false, customClass = '', readOnly = false }) => {
  const { width } = useWindowSize()
  const isMobile = width < MOBILE_WIDTH_SIZE
  return (
    <Button
      className={`order-modal-account-item add-funding-modal-item gray5 min-w-full ${customClass} ${disabled ? 'disabled' : ''} ${readOnly ? 'read-only' : ''}`}
      onClick={() => !disabled && onSelect && onSelect(account)}
      disabled={disabled}
      ariaLabel={`Select ${account.label} account`}
    >
      <div>
        <img alt={account.label} src={account.iconUrl} className='account-icon' />
      </div>
      {!isMobile && <div className='b_18_semibold add-funding-modal-item-name ml-2'>{account.label}</div>}
      <div className='add-funding-modal-item-right'>
        {isMobile && <div className='b_18_semibold add-funding-modal-item-name-sm'>{account.label}</div>}
        {account.balance !== null && formatDecimal(account.balance)}
        {!account?.balance && account?.usdBalance !== null && formatDecimal(account.usdBalance)}
        {account?.description && <span className='text-left order-modal-account-desc'>{account.description}</span>}
        {!readOnly && <img src={images['right-arrow']} alt={account.label} className='account-arrow' />}
      </div>
    </Button>
  )
}

export default AddFundsModalItem

const parsingContactUs = (data) => {
  const result = {}
  result.addresses = data.addresses ? data.addresses : []
  result.email = data.email ? data.email : ''
  result.firstName = data.firstName ? data.firstName : ''
  result.lastName = data.lastName ? data.lastName : ''
  result.phone = data.phone ? data.phone : ''
  result.topics = data.topics ? data.topics : []
  return result
}

export default parsingContactUs

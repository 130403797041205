import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const DownloadSummary = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/documents')
  }

  return (
    <div className='download-pdf'>
      <a
        onClick={handleClick}
        download='LinqtoPortfolio'
        aria-label='portfolioPDFDownload'
        role='button'
        tabIndex={0}
      >
        <img alt='Download Portfolio Summary' src={images.download} />
      </a>
    </div>
  )
}

export default DownloadSummary

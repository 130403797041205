const parsingUnicorns = ({ company }) => {
  return {
    companyId: company.companyId,
    logoUrl: company.logoUrl,
    urlName: company.urlName,
    name: company.name,
    description: company.description,
    foundedYear: company.foundedYear ? company.foundedYear : null,
    estimatedRevenue: company.estimatedRevenue ? company.estimatedRevenue : null,
    lastRaisedDate: company.lastRaisedDate ? company.lastRaisedDate : null,
    lastRaised: company.lastRaisedDate ? company.lastRaised : null,
    sector: company.sector ? company.sector : null,
    stage: company.stage ? company.stage : null,
    totalRaised: company.totalRaised ? company.totalRaised : null,
    valuation: company.valuation ? company.valuation : '',
    websiteUrl: company.websiteUrl ? company.websiteUrl : null,
    sharePrice: company.sharePrice ? company.sharePrice : null,
    mosaic: {
      market: (company.mosaic && company.mosaic.market) ? company.mosaic.market : null,
      momentum: (company.mosaic && company.mosaic.momentum) ? company.mosaic.momentum : null,
      money: (company.mosaic && company.mosaic.money) ? company.mosaic.money : null,
      overall: (company.mosaic && company.mosaic.overall) ? company.mosaic.overall : null,
      management: (company.mosaic && company.mosaic.management) ? company.mosaic.management : null
    },
    address: {
      city: company.address && company.address.city ? company.address.city : '',
      country: company.address && company.address.country ? company.address.country : '',
      postalCode: company.address && company.address.postalCode ? company.address.postalCode : '',
      state: company.address && company.address.state ? company.address.state : '',
      street: company.address && company.address.street ? company.address.street : null
    },
    investors: company.investors ? company.investors : null,
    officers: company.officers ? company.officers : [],
    fundingRounds: company.fundingRounds ? company.fundingRounds : [],
    competitorFundingsUnicrons: (company.competitorsFundings && company.competitorsFundings.unicorn) ? company.competitorsFundings.unicorn : [],
    competitorFundingsOther: (company.competitorsFundings && company.competitorsFundings.other) ? company.competitorsFundings.other : [],
    partners: company.partners ? company.partners : [],
    boardMemebers: company.boardMemebers ? company.boardMemebers : [],
    isInterested: company.isInterested ? company.isInterested : null
  }
}

export default parsingUnicorns

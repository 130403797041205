import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatDecimal, toCamalCase } from 'utils'

const PurchaseCreditsHistory = () => {
  let { creditPurchaseEntries } = useSelector((state) => state.purchaseCredits)
  creditPurchaseEntries = creditPurchaseEntries.slice().sort((a, b) => b.createdAt - a.createdAt)
  return (
    <>
      <div className='grid transactions-table desktop-content'>
        <div className='row transactions-table-header'>
          <div className='column four b_16_regular gray5'>DATE</div>
          <div className='column eight b_16_regular gray5'>TRANSACTION</div>
          <div className='column four b_16_regular gray5 right'>AMOUNT</div>
        </div>
        {creditPurchaseEntries && creditPurchaseEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='row transactions-table-body'>
            <div className='column four b_16_regular gray5'>{moment(f?.createdAt).format('MM/DD/YYYY')}</div>
            <div className='column eight b_16_regular gray5'>{f.transactionType && toCamalCase(f?.transactionType)}</div>
            <div className='column four b_16_regular gray5 right'>{formatDecimal(f?.amount)}</div>
          </div>
        )}
      </div>
      <div className='grid transactions-table mobile-content'>
        {creditPurchaseEntries && creditPurchaseEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='row transactions-table-body'>
            <div className='column ten-mobile b_16_regular gray5'>{moment(f?.createdAt).format('MM/DD/YYYY')}<br/>{f.transactionType && toCamalCase(f?.transactionType)}</div>
            <div className='column six-mobile b_16_regular gray5 right'>{formatDecimal(f.amount)}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default PurchaseCreditsHistory

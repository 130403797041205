import * as yup from 'yup'
import { isWhiteSpace } from 'utils'
/**
 * Schema is the builder we use for validation
 * 1) Define your field values and add any validation + required fields, with the error message
 * 2) Connect your schema with React-Hook-Form's useForm() hook: { resolver: yupResolver(schema) }
 */
export const schema = yup.object().shape({
  crdNumber: yup.string().required('Please enter a license number.').test(
    'WhiteSpace',
    'Please enter a license number.',
    (val) => !isWhiteSpace(val)
  )
})

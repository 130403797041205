import Upload from '../../Global/Upload/Upload'
import { useState } from 'react'
import { ConditionalRender } from 'components'

const USDocumentUploadItem = ({
  dropDownLabel = '',
  dropDownList = [],
  onDelete,
  showDocType = false,
  document,
  allowDelete,
  onSuccess = () => {}
}) => {
  const [type, setType] = useState('')

  return (
    <>
      <ConditionalRender isVisible={showDocType}>
        <div className='us-documents-upload'>
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value)
            }}
            role='us-document-dropdown'
          >
            <option value='' disabled>
              {dropDownLabel}
            </option>
            {Object.keys(dropDownList).map((el, id) => (
              <option
                key={Object.keys(dropDownList)[id]}
                value={dropDownList[el]}
              >
                {dropDownList[el]}
              </option>
            ))}
          </select>
          <div className='file-new'>
            <Upload
              type='AI'
              onDelete={onDelete}
              name= {type}
              disabled={type === ''}
              allowDelete={allowDelete}
              autoUpload={true}
              callAfterSuccessUpload={onSuccess}
            />
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={!showDocType}>
        <div className='us-documents-uploaded'>
          <Upload
            type='AI'
            allowDelete={allowDelete}
            onDelete={onDelete}
            {...document}
          />
        </div>
      </ConditionalRender>
    </>
  )
}

export default USDocumentUploadItem

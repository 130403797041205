import { formatNumberWithLetter } from 'utils'
import moment from 'moment'

const UnicornDetailsCompetitorFundings = ({ competitorFundings }) => {
  return (
    <>
      <div className='desktop-content'>
        <div className='grid unicorn-table'>
          <div className='row unicorn-table-header'>
            <div className='column five' style={{ paddingLeft: 0 }}>Latest Funding</div>
            <div className='column five'>Companies</div>
            <div className='column five column-right'>Total Funding</div>
            <div className='column five column-right'>Mosaic Score</div>
            <div className='column five'>Country</div>
          </div>
          { competitorFundings && competitorFundings.map((f, i) =>
            <div key={`competitorFundings_${i}`} className='row unicorn-table-body'>
              <div className='column five'>{f.latestFundingDate && moment(f.latestFundingDate).format('MM/DD/YYYY')}</div>
              <div className='column five'>{f.companyName}</div>
              <div className='column five column-right'>{f.totalFunding && formatNumberWithLetter(f.totalFunding, true, true, 1)}</div>
              <div className='column five column-right'>{f.mosaicScore}</div>
              <div className='column five'>{f.location}</div>
            </div>
          )}
        </div>
      </div>
      <div className='unicorn-card-container'>
        {competitorFundings && competitorFundings.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div className='grid'>
              <div className='row unicorn-card-row'>
                <div className='column sixteen'>{f.latestFundingDate && moment(f.latestFundingDate).format('MM/DD/YYYY')}</div>
              </div>
              <div className='sixteen column row unicorn-card-row-line-sepretaor' />
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Companies: </strong></div>
                <div className='column eight'>{f.companyName}</div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Total Funding: </strong></div>
                <div className='column eight'>{f.totalFunding && formatNumberWithLetter(f.totalFunding, true, true, 1)}</div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Mosaic Score: </strong></div>
                <div className='column eight'>{f.mosaicScore}</div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Country: </strong></div>
                <div className='column eight'>{f.location}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsCompetitorFundings

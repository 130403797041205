import { useFormContext } from 'react-hook-form'
import FBOWithdrawAmount from './FBOWithdrawAmount'
import FBOWithdrawCTA from './FBOWithdrawCTA'
import FBOAddFundsFundingSource from 'components/FBOAddFunds/partials/FBOAddFundsFundingSource'

const FBOWithdrawForm = ({
  onSubmit,
  selectedAccount,
  setSelectedAccount,
  entityId
}) => {
  const { handleSubmit } = useFormContext()

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FBOWithdrawAmount />
      <FBOAddFundsFundingSource
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        insufficientError={null}
        setInsufficientError={() => {}}
        setShowWireInstruction={() => {}}
        connectPlaidAccount={null}
        entityId={entityId}
        type='withdraw'
        label='Destination Account'
        testId='select-withdraw-account'
      />
      <FBOWithdrawCTA />
    </form>
  )
}

export default FBOWithdrawForm


// Currecy list of local images. Compared to accounts pull from backend
const currencyList = ['USD', 'BTC', 'EUR', 'XAU', 'XRP']

const parseUpholdAccounts = (data) => ({
  hasUpholdWallet: data.hasUpholdWallet,
  cards: (data.cards) ? (data.cards.map((account) => ({
    currency: account.currency || '',
    id: account.id || '',
    label: account.label || '',
    balance: account.usdBalance || 0,
    hasImage: currencyList.includes(account.currency),
    iconUrl: account.iconUrl
  })).sort((a, b) => b.balance - a.balance)) : [],
  linqtoBucks: data.linqtoBucks,
  isAccredited: !!data?.isAccredited,
  isProfileComplete: !!data?.isProfileComplete,
  upholdPaymentEnabled: !!data?.upholdPaymentEnabled,
  wirePaymentEnabled: !!data?.wirePaymentEnabled,
  cashPaymentEnabled: !!data?.cashPaymentEnabled,
  cashBalance: data?.cashBalance || 0,
  upholdError: data?.upholdError || null
})

export default parseUpholdAccounts

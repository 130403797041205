import { useSelector } from 'react-redux'
const PurchaseCreditsTotal = () => {
  const { totalPurchaseCredits } = useSelector((state) => state.purchaseCredits)

  const totalInvestmentValueArr = totalPurchaseCredits?.toString().split('.')
  let decimal = totalInvestmentValueArr[1]
  if (decimal && decimal.length === 1) {
    decimal += '0'
  }

  return (
    <div className='total-investment-container spaced'>
      <div className='investment-total-container'>
        <p className='title_1 investment-total'>
          ${(+totalInvestmentValueArr[0]).toLocaleString('en-US')}
          <sup className='b_18_regular super-script'>{decimal || '00'}</sup>
        </p>
        <span className='investment-total_copy'>Total Available Credits</span>
      </div>
    </div>
  )
}

export default PurchaseCreditsTotal

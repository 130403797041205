import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import Select from 'components/Global/Inputs/Select'
import { getRegions } from 'slices/commonSlice'

const AddressInput = ({
  disabled,
  countries,
  control
}) => {
  const {
    setValue,
    watch
  } = useFormContext()
  const country = watch('country')
  const dispatch = useDispatch()
  const [regions, setRegions] = useState([])

  useEffect(() => {
    setValue('state', '', { shouldDirty: true })
    if (country !== '') {
      dispatch(getRegions(country)).then(({ payload }) => {
        setRegions(payload)
      })
    } else {
      setRegions([])
    }
  }, [country])

  return (
    <>
      <div className='eight wide tablet eight wide computer column'>
        <TextInput
          name='street1'
          label='Company Street Address Line 1'
          disabled={disabled}
          ariaLabel='street1'
        />
      </div>
      <div className='eight wide tablet eight wide computer column'>
        <TextInput
          name='street2'
          label='Address Line 2'
          disabled={disabled}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Controller
          control={control}
          name='country'
          render={({ field }) => (
            <Select
              field={field}
              label='Country'
              name='country'
              options={countries}
              ref={null}
              disabled={disabled}
              defaultOption={{ value: '', label: 'Select Country' }}
              ariaLabel='country'
            />
          )}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <Controller
          control={control}
          name='state'
          render={({ field }) => (
            <Select
              field={field}
              label='State or Province'
              name='state'
              options={regions}
              ref={null}
              disabled={disabled}
              defaultOption={{ value: '', label: 'Select Region' }}
              ariaLabel='state'
            />
          )}
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <TextInput
          name='city'
          label='City'
          disabled={disabled}
          ariaLabel='city'
        />
      </div>
      <div className='eight wide tablet four wide computer column'>
        <TextInput
          name='zip'
          label='Postal Code'
          disabled={disabled}
          ariaLabel='zip'
        />
      </div>
    </>
  )
}

export default AddressInput

import { useEffect, useState } from 'react'
import { PageLoading, Button } from 'components'
import { images } from 'assets'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getInvestorStatus,
  saveAccreditedInvestorStatusChoice,
  saveSelfAccreditation
} from 'slices/investorStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Mixpanel, useLDFlags } from 'utils'
import ReactHtmlParser from 'react-html-parser'

const SelfAttestationUS = ({ withProgressBar = false, updateProgressBar }) => {
  const { selfAttestUs } = useLDFlags('selfAttestUs')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { featureFlags } = useSelector(state => state.userSlice)
  const [pageLoading, setPageLoading] = useState(true)

  const { RetailPurchaseAllowed } = featureFlags || {}
  useEffect(() => {
    if (withProgressBar) {
      setPageLoading(false)
      return
    }
    if (selfAttestUs) {
      getInvestorStatusPage()
    } else if (selfAttestUs !== undefined && !selfAttestUs) {
      history.push('/profile')
    }
  }, [selfAttestUs, withProgressBar])

  const getInvestorStatusPage = async () => {
    const { meta, payload } = await dispatch(getInvestorStatus())
    if (meta.requestStatus === 'fulfilled') {
      const data = payload
      if (data?.accreditedStatus === 'ACCREDITED') {
        history.push('/products')
      }
      if (!data?.hasKyc || !data?.canAttestAsAccreditation) {
        history.push('/profile')
      } else {
        Mixpanel.track('View Self Attestation Page')
      }
    }
    setPageLoading(false)
  }

  const handleSubmitSelfAccredited = async () => {
    setPageLoading(true)
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': 'United States',
      'Accreditation Status': 'Self Accredited'
    })
    await dispatch(saveAccreditedInvestorStatusChoice({ choice: 'PROFESSIONAL_CLIENT', subChoice: 'Self Attestation USA' }))
    setPageLoading(false)
    if (withProgressBar) {
      updateProgressBar('selfAttestationSubmitSuccess')
    }
    history.replace(location.pathname, { type: 'selfAccredited' })
  }
  const handleSubmitRetail = async () => {
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': 'United States',
      'Accreditation Status': 'Not Accredited'
    })
    if (!RetailPurchaseAllowed) {
      history.replace(location.pathname, { type: 'retailNotAllowed' })
      return
    }
    setPageLoading(true)
    await dispatch(saveSelfAccreditation({ selfAccreditation: 'NOT_ACCREDITED' }))
    setPageLoading(false)
    if (withProgressBar) {
      updateProgressBar('selfAttestationSubmitSuccess')
    }
    history.replace(location.pathname, { type: 'notAccredited' })
  }

  const handleExploreButton = () => {
    /* If loaded in react native webview, post message
    so webview can remove required elements from DOM */
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      history.push('/products')
    }
  }

  if (location?.state?.type) {
    const type = location.state.type
    const image = type === 'retailNotAllowed' ? images.retail_not_allowed : images.acc_verified
    const copy = {
      notAccredited: "You now have access to a select number of companies.",
      selfAccredited: "You now have full access to companies revolutionizing AI, digital assets, and space technology.",
      retailNotAllowed: "Regulations require investors on Linqto to qualify as accredited investors."
    };
    const title = type === 'retailNotAllowed' ? 'Investments Are Only Open to Accredited Investors' : 'You’re ready to invest!'
    const buttonCopy = type === 'retailNotAllowed' ? 'Continue' : 'Explore Investments'
    return (
      <div className='flex flex-col h-min-[80vh] pb-3 px-0 xl:pb-16 lg:px-36 md:px-10'>
        <div className='inner-container'>
          <div className='accreditation-verified self-attest'>
            <img src={image} alt='Accreditation Verified' aria-label='accreditation-verified'/>
            <h1 className='mb-6 large_1 mt-9 centered'>{title}</h1>
            <div className='mb-16 medium_1'>{ReactHtmlParser(copy[type])}</div>
            <div className='flex flex-col-reverse justify-between w-full mb-36 md:flex-row md:justify-center'>
              {type === 'retailNotAllowed' &&  <Button mode='tertiary' onClick={() => history.replace(location.pathname)} customClass=''>Update Your Status</Button>}
              <Button onClick={handleExploreButton} customClass=''>{buttonCopy}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (pageLoading) {
    return (
      <PageLoading />
    )
  }

  const mainComponent = (
    <>
      <h1 className='medium_4'>Are you an Accredited Investor?</h1>
      <div className='flex flex-row justify-between w-full px-4 py-3 mt-6 mb-6 border rounded-lg md:w-64 self-attest' onClick={handleSubmitSelfAccredited} role='button' tabIndex={0} aria-label='Yes'>
        <div className='acc-nav-copy'>
          <div className='medium_1 gray5'>Yes</div>
        </div>
        <img src={images.arrow_forward_ios} alt='Yes, I am an accredited investor' className='acc-nav-arrow' />
      </div>
      <div className='flex flex-row justify-between w-full px-4 py-3 mb-6 border rounded-lg md:w-64 self-attest' onClick={handleSubmitRetail} role='button' tabIndex={1} aria-label='No'>
        <div className='acc-nav-copy'>
          <div className='medium_1 gray5'>No</div>
        </div>
        <img src={images.arrow_forward_ios} alt='No, I am not an accredited investor' className='acc-nav-arrow' />
      </div>
      <div className='px-4 pt-4 pb-8 mt-10 mb-16 rounded-lg md:px-4 md:py-3 bg-gray1 border-gray2'>
        <div className='my-6 body_2'>You are an Accredited Investor if you satisfy one of the following:</div>
        <div className='my-6 body_1' data-testid='acc-option-200k'>An earned income of more than $200,000 (or $300,000 together with a spouse) in <span className='body_3'>each</span> of the last two years and expect to meet this level in the current year.</div>
        <div className='my-7 body_1'>A net worth over $1 million (excluding the value of a primary residence)</div>
        <div className='my-6 body_1'>An active Series 7, Series 65 or Series 82 financial security license</div>
      </div>
    </>
  )
  if (withProgressBar) {
    return mainComponent
  }
  return (
    <div className='page-container investor-status-container'>
      <div className='inner-container'>
        {mainComponent}
      </div>
    </div>
  )
}

export default SelfAttestationUS

import SecondaryButton from 'components/Global/SecondaryButton'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Mixpanel, Fullstory } from 'utils'

const InvestDetailAboutKeyFacts = ({ keyFacts }) => {
  const { details } = useSelector(state => state.investSlice)
  const { name } = details || {}
  const [showAllKeyFacts, setShowAllKeyFacts] = useState(false)
  let croppedKeyFacts = [...keyFacts.slice(0, 3)]

  const handleClick = () => {
    setShowAllKeyFacts(!showAllKeyFacts)
    Mixpanel.track('Click Load More Key Facts on Product Page', { 'Company Name': name })
    Fullstory.track('Button', { name: 'Load More Key Facts', company_name: name })
  }

  if (showAllKeyFacts) {
    croppedKeyFacts = [...keyFacts]
  } else {
    croppedKeyFacts = [...keyFacts.slice(0, 3)]
  }

  return (
    <>
      <ul className='key-facts__list'>
        {croppedKeyFacts && croppedKeyFacts.map((p, i) =>
          <li className='b_16_regular key-facts__fact' key={`key-fact${i}`}>{p.fact}</li>
        )}
      </ul>
      <SecondaryButton text={`${showAllKeyFacts ? 'Show Less' : 'Load More'} Key Facts`} onClick={handleClick} />
    </>
  )
}
export default InvestDetailAboutKeyFacts

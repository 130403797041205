import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

const ContactTeamModal = ({ hideModal, handleClick }) => {
  return (
    <Modal
      crossToClose
      modalHeader='Contact our team to fund account'
      hideModal={hideModal}
    >
      <div className='b_18_regular content'>
        To fund your cash balance of your retirement account, contact our team.
      </div>
      <div className='btn-group centered'>
        <Button onClick={handleClick}>
          Contact Us
        </Button>
      </div>
    </Modal>
  )
}

export default ContactTeamModal

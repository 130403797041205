import { useEffect } from 'react'
import Upload from '../Global/Upload/Upload'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getDocuments, deleteDocument } from 'slices/documentsSlice'
import { PageLoading, ConditionalRender } from 'components'
import { Link } from 'react-router-dom'
import DocumentSection from './partials/DocumentSection'

const Documents = () => {
  const dispatch = useDispatch()
  const { pageLoading, taxDocuments, uploadedDocuments, monthlyStatmentDocuments } =
    useSelector((state) => state.documents)
  useEffect(() => {
    dispatch(getDocuments())
    Mixpanel.track('View Documents Page')
  }, [])

  const uploadDocumentCompleted = () => {
    Mixpanel.track('Upload Document on Documents Page')
    dispatch(getDocuments('noRefresh'))
  }
  const handleDelete = (documentId) => {
    dispatch(deleteDocument(documentId)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        dispatch(getDocuments('noRefresh'))
      }
    })
  }
  const onViewDocument = (Type) => {
    Mixpanel.track('View Document on Documents Page', { Type })
  }

  if (pageLoading) return <PageLoading />
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Documents')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1>Documents</h1>
          <div className='b_18_regular header-copy'>
            Have a question about a document?{' '}
            <Link
              onClick={() =>
                Mixpanel.track('Click Contact Us', { Location: 'Documents' })
              }
              to={'/contact'}
              className='inline-text-link'
              role='button'
              aria-label='Contact Us'
              tabIndex={0}
            >
              Contact Us
            </Link>
          </div>
          <div className='document-section'>
            <div className='heading_7'>Upload a Document</div>
            <ConditionalRender isVisible={uploadedDocuments.length > 0}>
              <div className='margined documents-list'>
                {uploadedDocuments.map((doc) => {
                  return (
                    <Upload
                      key={doc.name}
                      ariaLabel={doc.name}
                      onDelete={() => handleDelete(doc.documentId)}
                      enableView={true}
                      allowDelete={true}
                      {...doc}
                      onViewDocument={() =>
                        onViewDocument('User Uploaded Document')
                      }
                    />
                  )
                })}
              </div>
            </ConditionalRender>
            <div className='margined documents-list'>
              <Upload
                type='USER_UPLOADED'
                autoUpload={true}
                callAfterSuccessUpload={uploadDocumentCompleted}
              />
            </div>
          </div>
          <DocumentSection
            title='Statements'
            emptyState='Your monthly statements will be available to download at the end of each month.'
            documents={monthlyStatmentDocuments}
            onViewDocument={() => onViewDocument('Statements')}
          />
          <DocumentSection
            title='Tax Documents'
            emptyState='Your tax documents will be made available to download following the end of the year.'
            documents={taxDocuments}
            onViewDocument={() => onViewDocument('Tax Document')}
          />
        </div>
      </div>
    </>
  )
}
// dispatch(getDocuments('noRefresh'))
export default Documents

import { useState, useEffect } from 'react'

/**
 * Calculates current width/height (and updates on resize)
 * @returns current window viewport width & height
 */
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined })

  useEffect(() => {
    const handleResize = () => {
      if (window) {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      }
    }
    // update state on resize
    window.addEventListener('resize', handleResize)

    handleResize()
    // cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize

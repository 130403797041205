import * as yup from 'yup'

export const schema = yup.object().shape({
  country: yup.string().required('Select a country.'),
  idType: yup.string().required('Select document type.'),
  legalName: yup.string().required('Enter your legal name.'),
  birthMonth: yup.string().required('Select month.'),
  birthDay: yup.string().required('Select day.'),
  birthYear: yup.string().required('Select year.'),
  selfieFile: yup.string().required('Upload self-photo.'),
  idFrontFile: yup.string()
    .when('idType', { is: 'DRIVERS_LICENSE', then: yup.string().required('Upload your driver\'s license (front side).') })
    .when('idType', { is: 'OTHER', then: yup.string().required('Upload your driver\'s license (front side).') })
    .when('idType', { is: 'PASSPORT', then: yup.string().required('Upload your passport.') }),
  idBackFile: yup.string().when('idType', { is: 'DRIVERS_LICENSE', then: yup.string().required('Upload your driver\'s license (back side).') })
})

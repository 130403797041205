import { useState, useEffect } from 'react'
import Modal from 'components/Global/Modal'
import 'react-phone-number-input/style.css'
import { useDispatch } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/mfaSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import MfaModalHeader from './partials/MfaModalHeader'
import MfaModalNotes from './partials/MfaModalNotes'
import MfaModalForm from './partials/MfaModalForm'
import { verifySecurityPageMfas } from 'slices/securitySlice'
import { parsePhoneNumber } from 'react-phone-number-input'

const header = {
  totp: 'Authenticator App',
  sms: 'SMS Text Message',
  email: 'Email Authentication'
}

let interval
const MfaModal = ({ userMfaId, hideModal, type, handleAddSMS }) => {
  const [steps, setSteps] = useState(1)
  const [formErr, setFormErr] = useState('')
  const dispatch = useDispatch()
  const formContext = { type, steps }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    context: { formContext }
  })

  const { getValues } = methods

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleOtpSubmit = () => {
    const otp = getValues('opt')
    if (userMfaId && otp.length === 6) {
      dispatch(verifySecurityPageMfas({ userMfaId, otp })).then(
        ({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            hideModal()
          }
          if (meta.requestStatus === 'rejected' && payload) {
            setFormErr(payload.err)
          }
        }
      )
    }
  }

  const handleClickAddSMS = () => {
    const phoneNumber = getValues('phoneNumber')
    if (!parsePhoneNumber(phoneNumber)) {
      methods?.setError('phoneNumber', {
        type: 'required',
        message: 'Please enter a phone number.'
      })
    } else {
      handleAddSMS(phoneNumber)
      setSteps(2)
    }
  }

  const onSubmit = () => {
    if (type === 'sms' && steps === 1) {
      handleClickAddSMS()
    } else {
      handleOtpSubmit()
    }
  }

  return (
    <Modal
      innerStyle='mfa-modal'
      hideModal={() => hideModal(userMfaId)}
      crossToClose={userMfaId.toString().length > 0}
      modalHeader={header[type]}
    >
      <div className='security mfa'>
        <MfaModalHeader type={type} steps={steps} />
        <FormProvider {...methods}>
          <MfaModalForm
            type={type}
            steps={steps}
            formErr={formErr}
            setFormErr={setFormErr}
            setSteps={setSteps}
            onSubmit={onSubmit}
          />
        </FormProvider>
        <MfaModalNotes
          type={type}
          steps={steps}
          interval={interval}
          userMfaId={userMfaId}
        />
      </div>
    </Modal>
  )
}

export default MfaModal

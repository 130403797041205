import { isValidPhoneNumber } from 'react-phone-number-input'
import { isWhiteSpace, isValidEmail } from 'utils'
import * as yup from 'yup'

export const schema = yup.object().shape({
  email: yup
    .string()
    .when('$addionalValidation', (addionalValidation, schema) => {
      if (addionalValidation) {
        return schema.required(
          'Please enter an email.'
        ).test(
          'validEmail',
          'Please enter a valid email.',
          (val) => isValidEmail(val)
        )
      }
    }),
  password: yup
    .string()
    .when('$addionalValidation', (addionalValidation, schema) => {
      if (addionalValidation) {
        return schema.required(
          'Please enter a password.'
        ).test(
          'len',
          'Password must be at least 8 characters long.',
          (val) => val.length > 7
        ).test('containsNum', 'Password must contain at least one number.', (val) =>
          val?.match(/\d+/g)
        )
      }
    }),
  phone: yup
    .string()
    .ensure()
    .required('Please enter a phone number.')
    .test(
      'validPhone',
      'Please enter a valid phone number.',
      (val) => val && isValidPhoneNumber(val)
    ),
  taxId: yup
    .string()
    .required('Please enter a valid tax ID.')
    .test(
      'WhiteSpace',
      'Please enter a valid tax ID.',
      (val) => !isWhiteSpace(val)
    )
    .test(
      'len',
      'Please enter a valid tax ID.',
      (val) => val?.replace(/[_ -]/g, '').length === 9
    ),
  birthYear: yup
    .string()
    .required('Please select a Birth Year.')
    .test(
      'WhiteSpace',
      'Please select a Birth Year.',
      (val) => val !== 'NONE'
    ),
  birthMonth: yup
    .string()
    .required('Please select a Birth Month.')
    .test(
      'WhiteSpace',
      'Please select a Birth Month.',
      (val) => val !== 'NONE'
    ),
  birthDay: yup
    .string()
    .required('Please select a Birth Day.')
    .test(
      'WhiteSpace',
      'Please select a Birth Day.',
      (val) => val !== 'NONE'
    )
})

const InvestorStatusSubHeader = () => {
  return (
    <div className='b_18_regular header-content'>
      Accreditation requirements vary by country, but typically require that you
      are able to prove you have sufficient income or net worth. You can upload
      that documentation below.
    </div>
  )
}

export default InvestorStatusSubHeader

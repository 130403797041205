import { useEffect, useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'
import { formatNumberWithLetter } from 'utils'

const InvestDetailValuationOverTime = ({ valuationOverTime }) => {
  const chatContainer = useRef(null)

  const setScroll = () => {
    chatContainer.current.scrollLeft = chatContainer.current.scrollWidth
  }
  useEffect(() => {
    setScroll()
    window.addEventListener('resize', setScroll, false)
    return () => {
      window.removeEventListener('resize', setScroll)
    }
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    ChartDataLabels
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#14235A',
        align: 'top',
        font: {
          size: 16,
          family: 'Source Sans Pro'
        },
        formatter: function (value, context) {
          const formatted = formatNumberWithLetter(value, true, true, 1, true)
          const sourceIndexArry = context.dataset.sourceIndex
          const dataIndex = context.dataIndex
          return formatted.toString() + sourceIndexArry[dataIndex]
        }
      }
    },
    scales: {
      xAxes: {
        display: true,
        grid: {
          borderColor: '#0E1624'
        },
        gridLines: {
          display: false
        },
        ticks: {
          font: {
            size: 16
          },
          color: '#717171'
        }
      },
      yAxes: {
        type: 'linear',
        display: false,
        position: 'left',
        ticks: {
          beginAtZero: true
        }
      }
    },
    layout: {
      padding: {
        left: 36,
        right: 36,
        top: 36,
        bottom: 24
      }
    },
    elements: {
      point: {
        radius: 6,
        hoverRadius: 8,
        hitRadius: 10,
        backgroundColor: '#0039EC'
      }
    }
  }

  const uniCodes = {
    1: '\u00B9',
    2: '\u00B2',
    3: '\u00B3',
    4: '\u2074',
    5: '\u2075',
    6: '\u2076',
    7: '\u2077',
    8: '\u2078',
    9: '\u2079',
    10: '\u00B9\u2070',
    11: '\u00B9\u00B9',
    12: '\u00B9\u00B2',
    13: '\u00B9\u00B3',
    14: '\u00B9\u2074',
    15: '\u00B9\u2075'
  }
  const labels = valuationOverTime.map(obj => {
    return obj.year
  })

  const dataset = valuationOverTime.map(obj => {
    return obj.amount
  })

  const footNoteSoruce = valuationOverTime.reduce(function (ds, obj) {
    if (obj.valuationSource && obj.valuationSource !== '') {
      ds.push(obj)
    }
    return ds
  }, [])

  let index = 1
  const charFootNoteSoruce = valuationOverTime.reduce(function (ds, obj) {
    if (obj.valuationSource && obj.valuationSource !== '') {
      ds.push(uniCodes[index])
      index++
    } else {
      ds.push('')
    }
    return ds
  }, [])

  const data = {
    labels,
    datasets: [
      {
        data: dataset,
        borderColor: '#0039EC',
        sourceIndex: charFootNoteSoruce
      }
    ]
  }

  const width = dataset.length > 5 ? dataset.length * 60 : '100%'

  return (
    <>
      <div className='mobile-chart-container' ref={chatContainer}>
        <div className='chart-container' style={{ minWidth: width }} >
          <Line options={options} data={data} plugins={[ChartDataLabels]} />
        </div>
      </div>
      {footNoteSoruce.length > 0 && footNoteSoruce.map((f, i) =>
        <div key={`footnote_${i}`} className='b_14_regular gray3 chart-footnote'><sup>{i + 1}</sup> {f.valuationSource}</div>
      )}
    </>
  )
}

export default InvestDetailValuationOverTime

import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { Mixpanel, MOBILE_WIDTH_SIZE, toCamalCase } from 'utils'

const ProductFilters = ({ changeSearchFilter, verticals, loading, debounceFun, searchInputRef, sectorSelectRef, sortSelectRef, vertical, sort }) => {
  const { width } = useWindowSize()
  return (
    <div className='product-filters'>
      <div className='grid'>
        <div className='column four eight-mobile'>
          <select
            ref={sectorSelectRef}
            value={vertical}
            onChange={(e) => {
              Mixpanel.track('Filter by Sector on Invest Page', { Vertical: e.target.value })
              changeSearchFilter(e.target.value, 'vertical')
            }}
            disabled={loading} >
            <option value='' >{width <= MOBILE_WIDTH_SIZE ? 'Sector' : 'Filter by Sector'}</option>
            { verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
          </select>
        </div>
        <div className='column four eight-mobile'>
          <select
            ref={sortSelectRef}
            value={sort}
            onChange={(e) => {
              Mixpanel.track('Sort by on Invest Page', { 'Sort by': toCamalCase(e.target.value || '') })
              changeSearchFilter(e.target.value, 'sort')
            }}
            disabled={loading}
          >
            <option value='' >Sort by</option>
            <option value='NAME' >Name</option>
            <option value='VALUATION'>Valuation</option>
            <option value='DATE'>Date Added</option>
          </select>
        </div>
        <div className='column eight sixteen-mobile'>
          <div className='unicrons-filters-search'>
            <img alt='Search Companies' src={images['icon-search']} className='search-btn'/>
            <div className='input-group'>
              <input
                type='text'
                ref={searchInputRef}
                placeholder='Search Companies'
                onChange={(e) => debounceFun(e.target.value)}
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductFilters

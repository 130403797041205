const OrderRedirect = () => {
  return (
    <span className='b_18_regular unaccredited-order-copy-margin'>
      If you have initiated an order, you must complete all steps to finalize
      your purchase.
    </span>
  )
}

export default OrderRedirect

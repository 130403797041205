const ResetModalHeader = () => {
  return (
    <>
      <div className='b_18_regular content'>Please enter the email address associated with your account. We&apos;ll send you an email with instructions on how to reset your password.</div>
      <div className='b_18_regular content'>If you used Google or Apple to join Linqto, please close this pop-up and use the Google/Apple Sign In buttons.</div>
    </>
  )
}

export default ResetModalHeader

import Modal from '../../Global/Modal'
import Button from '../../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const TransferErrorModal = ({ hideModal, handleClick, message, header }) => {
  return (
    <>
      <Modal
        crossToClose
        modalHeader={header || 'Oops. Something Went Wrong'}
        hideModal={hideModal}
      >
        <div className='b_18_regular content'>
          {ReactHtmlParser(message)}
        </div>
        <div className='btn-group centered'>
          <Button onClick={handleClick}>
            {message ? 'OK' : 'Retry'}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default TransferErrorModal

import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import Image from 'components/Global/ImageWithLoader'
import PassCodeInput from 'components/Global/Inputs/PassCodeInput'
import PhoneNoInput from 'components/Global/Inputs/PhoneNoInput'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'
import Divider from 'components/Global/Divider'
import { smsCountriesList } from '../../../data'

const MfaInnerContent = ({
  type,
  steps,
  setFormErr,
  openAuthenticatorApp,
  copiedKey,
  copySecretKey
}) => {
  const { submitOTPLoading, currentMfa } = useSelector(
    (state) => state.securitySlice
  )

  const { control, register } = useFormContext()
  const { width } = useWindowSize()

  const isMobile = width <= MOBILE_WIDTH_SIZE

  if (type === 'totp' && steps !== 3) {
    if (steps === 1) {
      return (
        <p>
          If you don&apos;t have an authenticator app already, search
          &apos;Authenticator App&apos; on App Store or Google Play. We support
          all major apps such as iCloud Keychain, Google Authenticator, Authy,
          and Microsoft Authenticator.
        </p>
      )
    } else if (steps === 2) {
      if (isMobile) {
        const secretKey = currentMfa?.value
        return (
          <div>
            <div className='b_18_regular left-align'>
              Tap the link below to add your account to your default
              Authenticator App:
            </div>
            <div className='link' onClick={openAuthenticatorApp}>
              Go to Authenticator App
            </div>
            <Divider content='OR' />
            <div className='b_18_regular left-align'>
              Use the secret key to add your account manually to your
              Authenticator App:
            </div>
            <div className='link' onClick={() => copySecretKey(secretKey)}>
              {copiedKey ? 'Secret Key Copied!' : 'Copy Secret Key'}
            </div>
            <div className='b_18_regular left-align'>
              Once you&apos;ve set up your account, tap Next.
            </div>
          </div>
        )
      } else {
        return (
          <div className='item-group'>
            {currentMfa?.qrCodeUrl && (
              <Image
                alt='qrCode'
                src={currentMfa.qrCodeUrl + '?width=200&height=200'}
              />
            )}
          </div>
        )
      }
    }
  } else if (type === 'sms' && steps === 1) {
    return (
      <PhoneNoInput
        name='phoneNumber'
        control={control}
        disabled={submitOTPLoading}
        register={register}
        ariaLabel='phoneNumber'
        onChange={() => {
          setFormErr('')
        }}
        autoFocus
        countries= {smsCountriesList}
      />
    )
  } else {
    return (
      <PassCodeInput
        name='opt'
        disabled={submitOTPLoading}
        maxLength='6'
        autoFocus
        autoComplete='one-time-code'
        ariaLabel='otp-code'
        onChange={() => {
          setFormErr('')
        }}
      />
    )
  }
}

export default MfaInnerContent

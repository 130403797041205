
// Get all images from the images directory
const allImages = import.meta.glob('./images/*.{png,svg,jpg}', { eager: true })

// Return an object of all images with the filename as the key.
export const images = Object.keys(allImages).reduce((images, path) => {
  const key = path.match('/(?!.*/)(.*)(.png|.svg|.jpg)')[1]
  images[key] = allImages[path].default
  return images
}, {})

const parseDocuments = ({ uploadedDocuments = [], taxDocuments = [], monthlyStatmentDocuments = [] }) => {
  return {
    taxDocuments: taxDocuments.length > 0 ? taxDocuments.map((doc) => {
      doc.type = 'DOC'
      doc.delete = false
      return doc
    }) : [],
    uploadedDocuments: uploadedDocuments.length > 0 ? uploadedDocuments.map((doc) => {
      doc.type = 'USER_UPLOADED'
      doc.delete = true
      return doc
    }) : [],
    monthlyStatmentDocuments: monthlyStatmentDocuments.length > 0 ? monthlyStatmentDocuments.map((doc) => {
      doc.type = 'MONTHLY_STATEMENT'
      doc.delete = false
      return doc
    }) : []
  }
}

export default parseDocuments

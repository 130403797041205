import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
const SuccessSubmission = () => {
  const history = useHistory()
  return (
    <div className='manual-verfication-container page-container'>
      <div className='inner-container'>
        <h1>Verify Identity</h1>
        <div className='b_18_regular'>We received your documents and information. Your submission is being reviewed.</div>
        <div className='margined'>
          <Button onClick={() => history.push('/products')}>
            View Investment Opportunities
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessSubmission

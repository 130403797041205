import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Mixpanel, formatNumberWithLetter } from 'utils'
import { Button } from 'components'

const InformationContainer = ({ isSummary = false, setMosaicShowModal }) => {
  const { details, linqtoImpliedValuation } = useSelector(state => state.investSlice)
  const { impliedValuation, totalRaised, postMoney, lastRound, industry, mosaic, vertical, employeeCount, name } = details || {}
  const { amount, round, date } = lastRound || {}
  const { management, market, momentum, money, overall } = mosaic || {}
  const { industry: primaryIndustry } = industry || {}

  const handleClick = () => {
    setMosaicShowModal(true)
    Mixpanel.track('Click What is a Mosaic Score? on Product Page', { 'Company Name': name })
  }

  if (!isSummary) {
    return (
      <div className='column eight row valuation-block min-w-[320px] shrink-[2]'>
        <div className='valuation-block__top'>
          {round && <div className='valuation-block__info'>
            <span className='b_14_regular gray3'>Last Round</span>
            <span className='b_18_semibold text-wrap'>{moment.utc(date).format('MMM. YYYY')}, {round}</span>
          </div>}
          {postMoney && <div className='valuation-block__info'>
            <span className='b_14_regular gray3'>Valuation Post-Money</span>
            <span className='b_18_semibold'>{formatNumberWithLetter(postMoney, true, true, 2, true)}</span>
          </div>}
          {amount && <div className='valuation-block__info'>
            <span className='b_14_regular gray3'>Amount Raised</span>
            <span className='b_18_semibold'>{formatNumberWithLetter(amount, true, true, 2, true)}</span>
          </div>}
          {totalRaised && <div className='valuation-block__info'>
            <span className='b_14_regular gray3'>Total Funds Raised</span>
            <span className='b_18_semibold'>{formatNumberWithLetter(totalRaised, true, true, 2, true)}</span>
          </div>}
        </div>
        {impliedValuation && <div className='valuation-block__bottom'>
          <div className='valuation-block__info'>
            <span className='b_14_regular gray3 margin-bottom'>Implied Valuation</span>
            <span className='valuation-block__valuation margin-bottom'>{linqtoImpliedValuation}</span>
          </div>
        </div>}
      </div>
    )
  }

  return (
    <div className='column eight row valuation-block'>
      <div className='valuation-block__top'>
        {primaryIndustry && <div className='valuation-block__info'>
          <span className='b_14_regular gray3'>Industry</span>
          <span className='b_18_semibold'>{primaryIndustry}</span>
        </div>}
        {vertical && <div className='valuation-block__info'>
          <span className='b_14_regular gray3'>Primary Vertical</span>
          <span className='b_18_semibold'>{vertical}</span>
        </div>}
        {employeeCount && <div className='valuation-block__info'>
          <span className='b_14_regular gray3'>Employees</span>
          <span className='b_18_semibold'>{employeeCount}</span>
        </div>}
      </div>
      {overall && <div className='valuation-block__bottom'>
        <div className='valuation-block__container'>
          <div className='valuation-block__info'>
            <span className='b_14_regular gray3 margin-bottom'>Mosaic Score</span>
            <span className='valuation-block__valuation'>{overall}</span>
          </div>
          <div className='valuation-block__right'>
            <div className='valuation-block__info'>
              <span className='b_14_regular gray3'>Money</span>
              <span className='b_18_semibold'>{money}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='b_14_regular gray3'>Momentum</span>
              <span className='b_18_semibold'>{momentum}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='b_14_regular gray3'>Management</span>
              <span className='b_18_semibold'>{management}</span>
            </div>
            <div className='valuation-block__info'>
              <span className='b_14_regular gray3'>Market</span>
              <span className='b_18_semibold'>{market}</span>
            </div>
          </div>
        </div>
        <Button className='mt-6 mb-3 b_18_semibold text-connectedCobalt' onClick={handleClick}>What is a Mosaic Score?</Button>
      </div>
      }
    </div>
  )
}

export default InformationContainer

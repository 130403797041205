import * as yup from 'yup'
import { isWhiteSpace } from 'utils'

/**
 * Schema is the builder we use for validation
 * 1) Define your field values and add any validation + required fields, with the error message
 * 2) Connect your schema with React-Hook-Form's useForm() hook: { resolver: yupResolver(schema) }
 */
export const schema = yup.object().shape({
  accountName: yup
    .string()
    .required('Please enter name on broker account.')
    .test(
      'WhiteSpace',
      'Please enter name on broker account.',
      (val) => !isWhiteSpace(val)
    ),
  firmName: yup
    .string()
    .required('Please enter valid equity brokerage firm name.')
    .test(
      'WhiteSpace',
      'Please enter valid equity brokerage firm name.',
      (val) => !isWhiteSpace(val)
    ),
  accountNumber: yup
    .string()
    .required('Please enter valid brokerage account number.')
    .test(
      'WhiteSpace',
      'Please enter valid brokerage account number.',
      (val) => !isWhiteSpace(val)
    ),
  confirmAccountNumber: yup
    .string()
    .oneOf([yup.ref('accountNumber'), null], 'Account numbers must match.')
    .required('Account numbers must match.'),
  dtc: yup
    .string()
    .required('Please enter valid 4-digit brokerage DTC#')
    .test(
      'len',
      'Please enter valid 4-digit brokerage DTC#',
      (val) => val.length === 4
    ),
  specialInstructions: yup.string()
})

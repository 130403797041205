import Button from 'components/Global/Button'

const SecondaryButton = ({ text, onClick }) => {
  return (
    <Button className='b_18_semibold border-imaged-element secondary-button' onClick={onClick} ariaLabel={text}>
      <span className='b_18_semibold key-facts__more-facts border-imaged-element accessibleIndigo'>{text}</span>
    </Button>
  )
}

export default SecondaryButton

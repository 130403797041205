import Checkbox from 'components/Global/Inputs/Checkbox'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getInvestorStatus, saveAccreditedInvestorStatusChoice } from 'slices/investorStatusSlice'
import { Mixpanel } from 'utils'
import ButtonGroup from '../ButtonGroup'

const NoRegCountriesSection = () => {
  const methods = useForm()
  const { watch } = methods
  const dispatch = useDispatch()
  const { userFullName, accreditedCountry, accreditedStatus } = useSelector(state => state.investorStatusSlice)

  const onSubmit = async () => {
    const choice = watch('choice')
    Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Auto', 'Accredited Country': accreditedCountry, 'Accreditation Status': accreditedStatus })
    await dispatch(saveAccreditedInvestorStatusChoice({ choice }))
    await dispatch(getInvestorStatus())
  }

  return (
    <FormProvider {...methods}>
      <form className='no-reg-container'>
        <span>Please confirm that the following statements are true:</span>
        <ol className='no-reg-container_ordered-list-container'>
          <li>I <b>{userFullName.toUpperCase()}</b> am a citizen of <b>{accreditedCountry.toUpperCase()}</b> and not of the USA</li>
          <li>My investments are for my own account and not on behalf of any citizen of the USA</li>
          <li>There are no laws or regulations in my home country that restrict me from making investments in the unlisted, private companies</li>
          <li>I am financially sophisticated. I accept and acknowledge the risk of loss associated with investments in unlisted, private companies. I understand that these companies do not report and disclose their operating and financial data in the way that public companies do
            , that their securities are not freely tradeable, and that the market for these private securities are illiquid and inefficient.
          </li>
        </ol>
        <Checkbox label='I confirm I have read the above statements, and it is accurate and correct.' name='choice' value='UNREGULATED_COUNTRY' />
        <div className='b_18_regular accreditation-terms'>
          <ButtonGroup disabled={!watch('choice')} loading={false} onClick={onSubmit} />
        </div>
      </form>
    </FormProvider>
  )
}

export default NoRegCountriesSection

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'

const initialState = {
  pageLoading: false,
  loading: false,
  submitOTPLoading: false,
  isVerified: false,
  qrCodeUrl: '',
  mfas: [],
  currentMfa: {},
  email: '',
  mfaEnabled: false,
  selfDeleteAvailable: false
}

export const getSecurityPage = createAsyncThunk('getSecurityPage', (_, { dispatch }) => {
  return api
    .get('/page/security')
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const removeSecurityMfa = createAsyncThunk('removeSecurityMfa', ({ userMfaId }, { dispatch }) => {
  return api
    .delete(`/page/security/mfas/${userMfaId}`)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const addSecurityMfa = createAsyncThunk('addSecurityMfa', ({ type, value }, { dispatch }) => {
  return api
    .post('/page/security/mfas', { type, value })
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const changePassword = createAsyncThunk(
  'changePassword',
  (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/changePassword', {
        currentPassword: formValues.currentPsd,
        newPassword: formValues.newPsd
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const submitDeleteAccount = createAsyncThunk(
  'submitDeleteAccount',
  (reason, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/page/requestToDeleteMyUser', {
        reason
      })
      .then(res => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getUserCanSelfDelete = createAsyncThunk('getUserCanSelfDelete', (_, { dispatch }) => {
  return api
    .get('/page/userCanSelfDelete')
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const verifySecurityPageMfas = createAsyncThunk('verifySecurityPageMfas', ({ userMfaId, otp }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .post(`/page/security/mfas/${userMfaId}/verify`, { otp, userMfaId })
    .then(() => {
      dispatch(getSecurityPage())
    })
    .catch(err => {
      if (err.response && err.response.data.error === 'MFA_VERICATION_INCORRECT') {
        return rejectWithValue({ err: 'The one-time passcode is incorrrect. Try again.' })
      }
      dispatch(handleServerError(err.response.data.error))
      return rejectWithValue(err.response)
    })
})

export const sendSecurityPageMfa = createAsyncThunk('sendSecurityPageMfa', (userMfaId, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .post(`/page/security/mfas/${userMfaId}/send`)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const SecuritySlice = createSlice({
  name: 'securitySlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = false
      })
      .addCase(submitDeleteAccount.pending, (state) => {
        state.loading = true
      })
      .addCase(submitDeleteAccount.fulfilled, (state, { payload }) => {
        state.loading = false
      })
      .addCase(submitDeleteAccount.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(verifySecurityPageMfas.pending, (state) => {
        state.submitOTPLoading = true
      })
      .addCase(verifySecurityPageMfas.fulfilled, (state) => {
        state.submitOTPLoading = false
      })
      .addCase(verifySecurityPageMfas.rejected, (state) => {
        state.submitOTPLoading = false
      })
      .addCase(getSecurityPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSecurityPage.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getSecurityPage.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(addSecurityMfa.fulfilled, (state, { payload }) => {
        return { ...state, currentMfa: payload }
      })
      .addCase(removeSecurityMfa.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(removeSecurityMfa.fulfilled, (state, { payload }) => {
        state.pageLoading = false
      })
      .addCase(removeSecurityMfa.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getUserCanSelfDelete.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
  }
})

export default SecuritySlice.reducer

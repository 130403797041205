import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { Mixpanel, Fullstory } from 'utils'

const SignUpLink = () => {
  const handleSignUp = () => {
    history.push('/signup')
    Mixpanel.track('Click Sign Up on Sign In Page')
    Fullstory.track('Click Sign Up on Sign In Page')
  }
  const history = useHistory()
  return (
    <span className='account-signin'>
      Don&apos;t have an account?
      <Button onClick={handleSignUp} className='link primary left-padded'>Sign Up</Button>
    </span>
  )
}

export default SignUpLink

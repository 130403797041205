
const NewProgressBar = ({
  activeStep
}) => {
  let width
  switch (activeStep) {
    case 0: width = '2%' 
      break
    case 1: width = '20%'
      break
    case 2: width = '30%'
      break
    case 3: width = '40%'
      break
    case 4: width = '50%'
      break
    case 5: width = '60%'
      break
    case 6: width = '70%'
      break
    case 7: width = '80%'
      break
    case 8: width = '90%'
      break
  }
  return (
    <div className='w-auto h-1 my-12 border rounded-[30px] bg-gray1 border-none'>
      <div style={{ width }} className={`h-1 border rounded-[30px] bg-connectedCobalt border-none`} />
    </div>
  )
}

export default NewProgressBar
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import parseRewardHistory from 'actions/parsing/parseRewardHistory'
import api from '../apis/private'

const initialState = {
  transactions: [],
  linqtoBucks: '',
  referralCode: '',
  signUpBonus: '',
  firstOrderBonus: ''
}

export const getRewards = createAsyncThunk('getRewards', (_, { dispatch }) => {
  return api
    .get('/page/rewards')
    .then(res => {
      const transactions = (res?.data?.transactions) ? parseRewardHistory(res.data.transactions) : {}
      return { ...res?.data, transactions }
    })
    .catch(err => {
      if (err?.response) {
        dispatch(handleServerError(err?.response?.data?.error))
      }
    })
})

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRewards.fulfilled, (_, { payload }) => {
        return {
          ...payload
        }
      })
  }
})

export default rewardsSlice.reducer

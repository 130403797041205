import { formatNumberWithLetter } from 'utils'

const InvestDetailValuationCompetitive = ({ competitors }) => {
  const footNotes = []
  let fnCounter = 1
  const compArr = competitors.map((obj) => {
    const compObj = { ...obj }
    if (compObj.valueFootnote && compObj.valueFootnote !== '') {
      compObj.valueFNCounter = fnCounter
      footNotes.push({ no: fnCounter, desc: compObj.valueFootnote })
      fnCounter++
    }

    if (compObj.revenueFootnote && compObj.revenueFootnote !== '') {
      compObj.revenueFNCounter = fnCounter
      footNotes.push({ no: fnCounter, desc: compObj.revenueFootnote })
      fnCounter++
    }
    return compObj
  })

  return (
    <>
      <div className='grid unicorn-table invest-table column-table'>
        <div className='row unicorn-table-header'>
          <div className='column ten eight-mobile b_16_semibold'>Company</div>
          <div className='column two four-mobile b_16_semibold'>Valued</div>
          <div className='column two four-mobile b_16_semibold'>Revenue</div>
        </div>
        {compArr && compArr.map((f, i) =>
          <div key={`competitor_${i}`} className='row unicorn-table-body row-bottom'>
            <div className='column ten eight-mobile'>
              <span className='full-line b_16_semibold'>{f.companyName}</span>
              {f.headquarters && (f.headquarters.city || f.headquarters.state) &&
                <span className='full-line b_16_regular'>{f.headquarters && `${f.headquarters.city},`} {f.headquarters && f.headquarters.state}</span>
              }
            </div>
            <div className='column two four-mobile'>
              <span className='b_16_regular'>
                {f.value ? formatNumberWithLetter(f.value, true, true, 1, true) : 'N/A'}
                {f.valueFNCounter && <sup>{f.valueFNCounter}</sup>}
              </span>
            </div>
            <div className='column two four-mobile'>
              <span className='b_16_regular'>
                {f.revenue ? formatNumberWithLetter(f.revenue, true, true, 1, true) : 'N/A'}
                {f.revenueFNCounter && <sup>{f.revenueFNCounter}</sup>}
              </span>
            </div>
          </div>
        )}
      </div>
      {footNotes.length > 0 &&
      <div className='table-footnote'>
        {footNotes.map((f, i) =>
          <div key={`note${i}`} className='footnote b_16_regular gray3'>
            <sup>{f.no}</sup>{f.desc}{' '}
          </div>
        )}
      </div>}
    </>
  )
}

export default InvestDetailValuationCompetitive

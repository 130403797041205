
import { useSelector } from 'react-redux'

const InvestmentNoData = ({ tab }) => {
  const { cashPaymentEnabled, needToCompleteInvestorProfile, totalCashAvailable } = useSelector(state => state.portfolioSlice)

  const emptyStateMainText = 'You haven\'t made any investments yet.'
  let emptyStateSecondaryText = 'Start Investing today in one of our exciting opportunities.'

  if (cashPaymentEnabled) {
    if (needToCompleteInvestorProfile) {
      emptyStateSecondaryText = 'Complete your profile to start investing.'
    }
    if (!needToCompleteInvestorProfile && totalCashAvailable === 0) {
      emptyStateSecondaryText = 'Add funds to your account to start investing.'
    }
  }

  return (
    <div className='investment-no-data-container'>
      {tab === 'history' && <h3>Order History</h3>}
      <div className='copy-container'>
        <p className='heading_7 main'>{emptyStateMainText}</p>
        <p className='b_18_regular secondary'>{emptyStateSecondaryText}</p>
      </div>
    </div>
  )
}

export default InvestmentNoData

import { images } from 'assets'
import { useSelector } from 'react-redux'
import AddFundsModalItem from './AddFundsModalItem'
import { Mixpanel } from 'utils'

const PlaidAccounts = ({
  hideModal,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction = () => {},
  accounts,
  isWidthdraw = false
}) => {
  const { hasUphold } = useSelector((state) => state.walletSlice)

  return (
    <div className={`${hasUphold ? 'plaids-accounts-container' : ''}`}>
      {accounts?.map((account) => (
        <AddFundsModalItem
          key={account.cashExternalAccountId}
          account={{
            ...account,
            label: account.accountDescription,
            iconUrl: account.amlStatus === 'APPROVED' ? images['plaid-account-active'] : images['plaid-account-pending'],
            description: account.amlStatus === 'APPROVED' ? '' : 'Under Review'
          }}
          onSelect={(acc) => {
            if (account.amlStatus === 'APPROVED') {
              setSelectedAccount({ ...acc, label: account.accountDescription, id: account.cashExternalAccountId, method: 'Bank Account', currency: 'USD' })
              hideModal()
              if (!isWidthdraw) {
                if (insufficientError) {
                  setInsufficientError(null)
                }
                setShowWireInstruction(false)
                Mixpanel.track('Select Funding Method', { 'Funding Method': 'Bank Account' })
              }
            }
          }}
          readOnly = {account.amlStatus !== 'APPROVED'}
        />
      ))}
    </div>

  )
}

export default PlaidAccounts

import Breadcrumb from 'components/Global/Breadcrumb'

const FBOWithdrawHeader = ({ entityId }) => {
  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Cash Account', route: `/cash-account${entityId ? `?entityId=${entityId}` : ''}` }
  ]
  return (
    <>
      <Breadcrumb items={items} />
      <h1>Withdraw Funds</h1>
    </>
  )
}

export default FBOWithdrawHeader

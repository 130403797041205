import { images } from 'assets'

const SignUpOAuthInfo = ({ err }) => {
  return (
    <div className='column sixteen'>
      <div className='signup-oath-user'>
        <div className='heading_7 signup-oath-user-header'>
          <img alt='capture button' src={images[err === 500 ? 'something-went-wrong' : 'checkmark']} />
          {err === 500 ? 'Something Went Wrong' : 'Email Verified'}
        </div>
      </div>
    </div>
  )
}

export default SignUpOAuthInfo

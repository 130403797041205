import RadioButton from 'components/Global/Inputs/RadioButton'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const DeleteAccountReasons = ({ reasons, url }) => {
  const { watch } = useFormContext()
  const reason = watch('reason')

  return (
    <div className='radio-group'>
      {Object.values(reasons).map((ele, id) => (
        <React.Fragment key={id}>
          <RadioButton label={ele} name='reason' value={ele} />
          {reason === ele && reason === reasons.TOO_MANY_EMAIL && (
            <a className='btn secondary lg' alt='Update Email Preferences' rel='noopener noreferrer' href={url}>
                Update Email Preferences
            </a>)}
        </React.Fragment>
      ))}
    </div>
  )
}

export default DeleteAccountReasons

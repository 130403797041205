
import { toCamalCase } from 'utils'

const parseOrders = orders => {
  return orders.reduce((result, o) => {
    if (o.createdAt && o.price !== null) {
      result.push({
        ...o,
        seriesName: o.seriesName ? o.seriesName : '',
        createdAt: o.createdAt ? o.createdAt : '',
        status: !o.status?.length ? '' : toCamalCase(o.status),
        shares: o.shares ? o.shares : '',
        price: o.price !== null ? o.price : '',
        sharePrice: o.sharePrice !== null ? o.sharePrice : ''
      })
    }
    return result
  }, []
  )
}

export default parseOrders

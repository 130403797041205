import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

const InCompleteProfileModal = ({ hideModal, type, crossToClose = true }) => {
  const copy = {
    ADD_FUNDS: 'Before you can add funds to your account,',
    SELL_ORDER: 'Before you can create sell order,'
  }
  return (
    <>
      <Modal
        crossToClose={crossToClose}
        modalHeader='Incomplete Investor Profile'
        hideModal={() => hideModal('closeBtn')}
        innerStyle='text-left'
      >
        <div className='b_18_regular content'>
          {copy[type]} you need to complete your profile and verify your status as an accredited investor.
        </div>
        <div className='btn-group centered'>
          <Button onClick={() => hideModal('mainBtn')}>
            Go to Investor Profile
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default InCompleteProfileModal

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel, useLDFlags } from 'utils'
import { Button } from 'components'

const InCompleteProfileSection = ({ setShowQuestionsModal }) => {
  const { details } = useSelector(state => state.investSlice)
  const history = useHistory()

  const { isSoldOut, user } = details
  const { isProfileComplete, missingProfileFields, isAccredited } = user || {}
  const { featureFlags } = useSelector((state) => state.userSlice)
  const { RequireBrokerDealerSuitabilityQuestions } = featureFlags || {}
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])

  const goToProfile = () => {
    Mixpanel.track('Click Incomplete Profile Link')
    // If user has feature flags enabled, has missing suitability profile fields and is already accredited, show the questions modal
    // otherwise, redirect to profile page to complete the profile
    const hasMissingFields = missingProfileFields?.length === 3 && missingProfileFields.includes('investmentHorizon') && missingProfileFields.includes('riskTolerance') && missingProfileFields.includes('investmentExperience')
    if (suitabilityQuestions && RequireBrokerDealerSuitabilityQuestions && hasMissingFields && isAccredited) {
      setShowQuestionsModal(true)
    } else {
      history.push('/profile?redirect=place-order')
    }
  }
  if (isProfileComplete || isSoldOut) return null
  return (
    <div className='place-order-copy'>
      To place an order please complete your investor profile.{' '}
      <Button className='inline-text-link' onClick={goToProfile} data-testid='completeProfileLink'>Complete Profile</Button></div>)
}

export default InCompleteProfileSection

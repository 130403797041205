import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCoreUser, loginUnconfirmedUser } from 'slices/userSlice'
import { useDispatch } from 'react-redux'
import { Mixpanel, Fullstory } from 'utils'
import { PageLoading } from 'components'
import { useQuery } from 'hooks/useQuery'

const Redirect = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const userClaimId = query.get('userClaimId')
  const code = query.get('code')
  const redirectTo = query.get('redirectTo')
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    const loginUser = async () => {
      const { meta, payload } = await dispatch(loginUnconfirmedUser({ userClaimId, code }))
      if (meta?.requestStatus === 'fulfilled') {
        setPageLoading(false)
        await dispatch(getCoreUser())
        history.push(redirectTo)
      }
      if (meta?.requestStatus === 'rejected' && payload === 'UNCONFIRMED_USER_ALREADY_CONFIRMED') {
        history.push('/signin')
      }
    }
    loginUser()
    Mixpanel.track('View Redirect Page', { userClaimId })
    Fullstory.track('Page View', {
      page_name: 'View Redirect Page',
      user_claim_id: userClaimId
    })
  }, [])
  if (pageLoading) {
    return (
      <PageLoading />
    )
  }
  return (
    <div className='page-container'></div>
  )
}

export default Redirect

import { useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { ScrollLocky } from 'react-scroll-locky'
import Loader from './Loader'
import { images } from 'assets'
import Button from 'components/Global/Button'
import { Mixpanel, Braze, getDevice } from 'utils'
import { logEvent, uploadSelfie } from 'slices/kycSlice'
import { useDispatch, useSelector } from 'react-redux'

const videoConstraints = {
  width: 500,
  height: 500,
  aspectRatio: 1,
  facingMode: 'user'
}
const Selfie = ({ handleVerifySuccess, handleRestart }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.kyc)

  const webcamRef = useRef(null)
  const [capturedImg, setCaptureImg] = useState(null)
  const [progress, setProgress] = useState(0)
  const [message, setMessage] = useState('Take a self-photo. Make sure your face is visible in the frame.')
  const [verifyStatus, setVerifyStatus] = useState('init')

  const capture = () => {
    Mixpanel.track('Onboarding Take Selfie')
    const imageSrc = webcamRef.current.getScreenshot()
    window.fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'Selfie.jpg', { type: 'image/jpg', path: 'Selfie.jpg' })
        const url = URL.createObjectURL(file)
        setCaptureImg(url)
        setMessage('Verifying...')
        setVerifyStatus('sent')
        dispatch(uploadSelfie({ file, func: loadProgress }))
          .then(({ meta, payload }) => {
            setProgress(0)
            if (meta.requestStatus === 'fulfilled') {
              setVerifyStatus('success')
              setMessage('Success!')
              handleVerifySuccess()
            } else if (meta.requestStatus === 'rejected') {
              if (payload === 'FACES_DID_NOT_MATCH') {
                setMessage('Auto verification failed. Your self-photo does not match your ID. Make sure your face is visible in the frame.')
                Mixpanel.track('Onboarding Error', { 'Error Type': 'Selfies don\'t match' })
                Braze.track('Onboarding Error', { 'Error Type': 'Selfies don\'t match', Device: getDevice() })
                setVerifyStatus('failed')
              } else if (payload === 'FACE_NOT_FOUND') {
                setMessage('Failed to find your face in the self-photo. Please try again.')
                Mixpanel.track('Onboarding Error', { 'Error Type': 'Face is not found' })
                Braze.track('Onboarding Error', { 'Error Type': 'Face is not found', Device: getDevice() })
                setVerifyStatus('failed')
              } else if (payload === 'error') {
                setMessage('Something went wrong. Please try again or restart the progress.')
                Mixpanel.track('Onboarding Error', { 'Error Type': 'Selfie error' })
                Braze.track('Onboarding Error', { 'Error Type': 'Selfie error', Device: getDevice() })
                setVerifyStatus('failed')
              }
            }
          })
      })
  }

  const loadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    setProgress(`${percentCompleted}%`)
  }

  const reset = () => {
    setCaptureImg(null)
    setVerifyStatus('init')
    setMessage('Take a self-photo. Make sure your face is visible in the frame.')
    dispatch(logEvent({ path: '/identityVerification/step4/retake' }))
  }

  return (
    <div>
      <ScrollLocky>
        <div className='webcam'>
          {verifyStatus === 'init' && <Webcam
            videoConstraints={videoConstraints}
            audio={false}
            ref={webcamRef}
            screenshotQuality={1}
            forceScreenshotSourceSize
            screenshotFormat='image/jpeg'
            mirrored
            className='webcam-video'
          />}
          {verifyStatus !== 'init' && <img alt='Captured' src={capturedImg} className='captured-img' />}
          <div className='camera-btn-group'>
            {message && <div className='selfie-message'><p>{message}{progress.length > 0 && progress}</p></div>}
            <div className='btn-group centered two'>
              {loading && <Loader className='loader-component selfie-loader' />}
              {!loading && verifyStatus === 'init' &&
                <img alt='Capture Button' src={images['capture-btn']} onClick={!loading && capture} className={`capture-btn loading-${loading}`} />}
              {verifyStatus === 'failed' &&
                <>
                  <Button mode='secondary' onClick={() => reset()}>Retake</Button>
                  <Button onClick={() => handleRestart()}>Restart</Button>
                </>
              }
            </div>
          </div>
        </div>
      </ScrollLocky>
    </div>
  )
}

export default Selfie

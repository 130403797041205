import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'

const initialState = {
  purchasers: [],
  documents: [],
  initialValues: {},
  accountName: '',
  bankName: '',
  company: {},
  entityId: 0,
  frequency: '',
  fundingSource: '',
  investmentAmount: 0,
  nextRecurringInvestmentDate: '',
  recurringInvestmentHistory: [],
  startDate: '',
  userId: 0
}

/**
 * Accepts an integer recurringEventId
 * Calls /recurringInvestment API with recurringEventId to get the current recurring investment and other form dropdowns data
 * used for both edit and create, recurringEventId = 0 if we are creating new one
 * @function getRecurringInvestment
 * @param {int} recurringEventId
 * @returns {obj}
 */

export const getRecurringInvestment = createAsyncThunk('getRecurringInvestment', async (recurringEventId, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.get(`/page/recurringInvestment/${recurringEventId}`)
    return fulfillWithValue({ ...response.data, initialValues: { ...response.data?.recurringEvent, entityId: response.data?.recurringEvent?.entityId || 'individual' } })
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * Accepts formValues object
 * Calls /recurringInvestment API with formValues objct to create or edit recurring investment event
 * used for both edit and create, recurringEventId = 0 if we are creating new one
 * @function getRecurringInvestment
 * @param {obj} formValues
 * @returns {obj}
 */

export const updateRecurringInvestment = createAsyncThunk('updateRecurringInvestment', async (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
  if (formValues.entityId === 'individual') {
    delete formValues.entityId
  }
  delete formValues.confirmCheckbox
  try {
    const response = await api.post('/page/recurringInvestment', { ...formValues })
    return fulfillWithValue(response.data)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * Accepts recurringEventId int
 * Calls /recurringInvestmentDetail API with recurringEventId to get recurring event data
 * @function getRecurringInvestment
 * @param {int} recurringEventId
 * @returns {obj}
 */

export const getRecurringInvestmentDetail = createAsyncThunk('getOrderConfirmedPage', async ({ recurringEventId }, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await api.get(`/page/recurringInvestmentDetail/${recurringEventId}`)
    return fulfillWithValue(response.data)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * Accepts an integer recurringEventId
 * Calls /recurringInvestment API with recurringEventId to delete the scheduled recurring investment
 * @function cancelRecurringInvestment
 * @param {int} recurringEventId
 */
export const cancelRecurringInvestment = createAsyncThunk('cancelRecurringInvestment', async (recurringEventId, { dispatch, rejectWithValue }) => {
  try {
    await api.delete(`/page/recurringInvestment/${recurringEventId}`)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})
export const placeOrderSlice = createSlice({
  name: 'placeOrderSlice',
  initialState,
  reducers: {
    updateUpholdRequired: (state) => {
      state.isUpholdOtpRequired = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecurringInvestmentDetail.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
      .addCase(getRecurringInvestment.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
      .addCase(updateRecurringInvestment.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
  }
})

export const { updateUpholdRequired } = placeOrderSlice.actions

export default placeOrderSlice.reducer

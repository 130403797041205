import * as yup from 'yup'

export const schema = yup.object().shape({
  confirmCheckbox: yup.bool().oneOf([true], 'Please confirm the recurring investment.'),
  entityId: yup
    .string()
    .required('Please select purchase as.'),
  companyId: yup
    .string()
    .required('Please select company.')
    .ensure()
    .when('$companyHasInvestment', (companyHasInvestment, schema) => {
      return schema.test(
        'company Has Investment',
        'This company already has an existing recurring investment.',
        () => !companyHasInvestment
      )
    }),
  maxAmount: yup
    .string()
    .required('Please select investment amount.'),
  cashExternalAccountId: yup
    .string()
    .required('Please select funding source.'),
  startDate: yup
    .string()
    .required('Please select start date.'),
  frequency: yup
    .string()
    .required('Please select frequency.')
})

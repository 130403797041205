import userSlice from './userSlice'
import securitySlice from './securitySlice'
import documentsSlice from './documentsSlice'
import placeOrderSlice from './placeOrderSlice'
import investorStatusSlice from './investorStatusSlice'
import portfolioSlice from './portfolioSlice'
import walletSlice from './walletSlice'
import profileSlice from './profileSlice'
import commonSlice from './commonSlice'
import sellOrderSlice from './sellOrderSlice'
import rewardsSlice from './rewardsSlice'
import kycSlice from './kycSlice'
import contactUsSlice from './contactUsSlice'
import originationSlice from './originationSlice'
import riaSlice from './riaSlice'
import investSlice from './investSlice'
import orderSlice from './orderSlice'
import financialAdvisorSlice from './financialAdvisorSlice'
import unicornsSlice from './unicornsSlice'
import brokerSlice from './brokerSlice'
import purchaseCreditsSlice from './purchaseCreditsSlice'
import bundleOrderSlice from './bundleOrderSlice'
import exitSlice from './exitSlice'
import recurringInvestmentSlice from './recurringInvestmentSlice'

export default {
  userSlice: userSlice,
  placeOrderSlice: placeOrderSlice,
  securitySlice: securitySlice,
  documents: documentsSlice,
  investorStatusSlice: investorStatusSlice,
  portfolioSlice,
  walletSlice,
  profile: profileSlice,
  commonSlice: commonSlice,
  sellOrder: sellOrderSlice,
  rewards: rewardsSlice,
  kyc: kycSlice,
  origination: originationSlice,
  contactUs: contactUsSlice,
  ria: riaSlice,
  investSlice: investSlice,
  order: orderSlice,
  financialAdvisor: financialAdvisorSlice,
  unicornsSlice: unicornsSlice,
  broker: brokerSlice,
  purchaseCredits: purchaseCreditsSlice,
  bundleOrder: bundleOrderSlice,
  exit: exitSlice,
  recurringInvestment: recurringInvestmentSlice
}

import Checkbox from 'components/Global/Inputs/Checkbox'

const RememberMeCheckbox = () => {
  return (
    <Checkbox
      label='Remember Me'
      name='rememberMe'
      ariaLabel='rememberMe'
      checkboxClass='remember-me-checkbox'
    />
  )
}

export default RememberMeCheckbox

import { formatDecimal } from 'utils'
import { images } from 'assets'
import { Button } from 'components'

const OrderModalAccountItem = ({ account, onSelect, disabled = false, customClass = '' }) => {
  return (
    <Button
      className={`order-modal-account-item min-w-full ${customClass} ${disabled && 'disabled'} ${account.label === 'Wire Transfer' ? 'wire-account' : ''} hover:text-gray5`}
      onClick={() => !disabled && onSelect && onSelect(account)}
      ariaLabel={`Select ${account.label} account`}
    >
      <div className='b_18_semibold'>
        <img alt={account.label} src={account.iconUrl} className='account-icon mr-2' />
        {account.label}
      </div>
      <div className='b_18_semibold'>
        {account.balance !== null && formatDecimal(account.balance)}
        {!account?.balance && account?.usdBalance !== null && formatDecimal(account.usdBalance)}
        {account?.description && <span className='text-left b_18_regular order-modal-account-desc'>{account.description}</span>}
        <img src={images['right-arrow']} alt={account.description} className='account-arrow' />
      </div>
    </Button>
  )
}

export default OrderModalAccountItem

import * as yup from 'yup'
import { checkIfNotNumber } from 'utils'

export const schema = yup.object().shape({
  optionsStrikePrice: yup
    .string()
    .required('Please enter a number.')
    .test(
      'ChechNumber',
      'Please enter a number.',
      (val) => !checkIfNotNumber(val)
    )
})

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseOriginationSearch from '../actions/parsing/parseOriginationSearch'
import axios from 'axios'
import FormData from 'form-data'

const initialState = {
  companies: [],
  company: {},
  offer: {},
  originationId: null,
  offerConfirmed: {},
  isOriginationAvailable: true,
  hasOpenOrigination: false,
  pageLoading: false,
  submitLoading: false,
  interestedInBuying: false
}

export const searchOrigination = createAsyncThunk(
  'searchOrigination',
  (query = '', { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/sellYourShares/search?searchTerm=${query}`)
      .then((res) => {
        return fulfillWithValue(parseOriginationSearch(res.data.companies))
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getOriginationDetail = createAsyncThunk(
  'getOriginationDetail',
  (companyUrlName, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/sellYourShares/detail/${companyUrlName}`)
      .then((res) => {
        return fulfillWithValue({
          company: res.data,
          isOriginationAvailable: res.data.isOriginationAvailable,
          hasOpenOrigination: res.data.hasOpenOrigination
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getOriginationOffer = createAsyncThunk(
  'getOriginationOffer',
  (
    { companyUrlName, shareClass, shares, optionsStrikePrice },
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    return api
      .get(
        `/page/sellYourShares/confirm/${companyUrlName}?companyUrlName=${companyUrlName}&shareClass=${shareClass}&shares=${shares}${
          optionsStrikePrice ? `&optionsStrikePrice=${optionsStrikePrice}` : ''
        }`
      )
      .then((res) => {
        return fulfillWithValue({
          offer: res.data,
          interestedInBuying: true
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const submitOriginationOffer = createAsyncThunk(
  'submitOriginationOffer',
  (
    { urlName, shareClass, shares, optionsStrikePrice, fileUploaded },
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    const data = new FormData()
    data.append('file', fileUploaded[0])

    const config = {
      method: 'post',
      url: `${
        import.meta.env.VITE_APP_API_URL
      }/page/sellYourShares/confirm/${urlName}?shares=${shares}&shareClass=${shareClass}${
        optionsStrikePrice ? `&optionsStrikePrice=${optionsStrikePrice}` : ''
      }`,
      data: data,
      headers: { hostname: window.location.hostname }
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage?.getItem('linqto_token') }
    }
    return axios(config)
      .then((res) => {
        return fulfillWithValue({
          originationId: res.data.originationId
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getOriginationConfirmedOffer = createAsyncThunk(
  'getOriginationConfirmedOffer',
  (originationId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/sellYourShares/confirmed/${originationId}`)
      .then((res) => {
        return fulfillWithValue({
          offerConfirmed: res.data
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const originationSlice = createSlice({
  name: 'originationSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(searchOrigination.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(searchOrigination.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(searchOrigination.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getOriginationDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getOriginationDetail.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getOriginationDetail.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getOriginationConfirmedOffer.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getOriginationConfirmedOffer.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getOriginationConfirmedOffer.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getOriginationOffer.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getOriginationOffer.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getOriginationOffer.rejected, (state) => {
        return {
          pageLoading: false,
          interestedInBuying: false
        }
      })
      .addCase(submitOriginationOffer.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(submitOriginationOffer.fulfilled, (state, { payload }) => {
        return {
          submitLoading: false,
          interestedInBuying: true,
          ...payload
        }
      })
      .addCase(submitOriginationOffer.rejected, (state) => {
        state.submitLoading = false
      })
  }
})

export default originationSlice.reducer

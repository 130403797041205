import { images } from 'assets'
import React, { useEffect, useState } from 'react'

const StackableCarousel = ({ handleClick, current, setCurrent, wide, data }) => {
  const currentItem = data?.[current - 1] || {}
  return (
    <div className='flex-column'>
      <div className={`linqto-carousel-container ${wide ? 'wide' : null}`}>
        <img height={17} width={17} className='mx-auto pointer' src={images['blue-arrow-left']} alt='Blue Arrow Left' onClick={() => handleClick('minus')} role='button' />
        <div className={`card-container ${wide ? 'wide' : null} ${data.length === 3 ? 'three-elements' : 'five-elements'}`}>{data.map(({ id }) => <input key={id} type='radio' name='slider' id={`s${id}`} checked={current === id} />)}
          <div className='cards-x'>
            {data.map(({ id, imageUrl }) => (
              <label key={id} htmlFor={`s${id}`} id={`slide${id}`}>
                <div className='card-x'>
                  <img className={`card-x-img ${wide ? 'wide' : null}`} src={images[imageUrl]} alt='Close Card' />
                </div>
              </label>
            ))}
          </div>
        </div>
        <img height={17} width={17} className='mx-auto pointer' src={images['blue-arrow-right']} alt='Blue Arrow Right' onClick={() => handleClick('plus')} role='button' />
      </div>
      {!wide && <div className='platform-text b_18_semibold'>{currentItem?.title}<br /><span className='b_18_regular'>{currentItem?.description}</span></div>}
      <CarouselButtons setCurrent={setCurrent} current={current} data={data} />
    </div>
  )
}

const FlatCarousel = ({ handleClick, current, setCurrent, data }) => (
  <div>
    {data.map(({ id, description, imageUrl, name, copy }) => (
      <div key={id} className='flex-justify-center'>
        {current === id && (
          <div className='flat-carousel-container'>
            <div className='description b_18_regular'>
              <img className='pointer' src={images['blue-arrow-left']} alt='Blue Arrow Left' onClick={() => handleClick('minus')} role='button' />
              <span className='text'>{description}</span>
              <img className='pointer' src={images['blue-arrow-right']} alt='Blue Arrow Right' onClick={() => handleClick('plus')} role='button'/>
            </div>
            <div className='image-container'>
              <img className='image' src={images[imageUrl]} alt={name} />
              <div className='name'>
                <p className='b_18_semibold'>{name}</p>
                <span>{copy}</span>
              </div>
            </div>
          </div>)}
      </div>))}
    <CarouselButtons setCurrent={setCurrent} current={current} data={data} />
  </div>
)

const CarouselButtons = ({ setCurrent, current, data }) => (
  <div className='carousel-bottom-navigation'>
    {data.map(({ id }) => (
      <div key={id} onClick={() => setCurrent(id)} role='button'>
        <div className={`item ${current === id ? 'active' : null}`} />
      </div>
    ))}
  </div>
)

const LinqtoCarousel = ({ stackable = false, wide = false, data }) => {
  const [current, setCurrent] = useState(1)
  const [clicked, setClicked] = useState(false)

  // set automatic interval to 7 seconds unless user manually clicked on arrows
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!clicked) {
        handleClick('plus', false)
      }
    }, 7000)
    return () => clearInterval(intervalId)
  }, [current, clicked])

  /**
   * handle carousel left/right arrow events
   * @param {string} operation (plus/minus)
   * @param {boolean} didClick (true/false) - if clicked, should stop automatic carousel interval
   */
  const handleClick = (operation, didClick = true) => {
    setCurrent(operation === 'plus' ? current === data?.length ? 1 : current + 1 : current === 1 ? data?.length : current - 1)
    if (didClick) {
      setClicked(true)
    }
  }

  let carouselType = null

  if (stackable) {
    carouselType = <StackableCarousel handleClick={handleClick} current={current} setCurrent={setCurrent} data={data} wide={wide} />
  } else {
    carouselType = <FlatCarousel handleClick={handleClick} current={current} setCurrent={setCurrent} data={data} />
  }

  return carouselType
}

export default LinqtoCarousel

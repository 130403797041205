import { formatDate, formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import useWindowSize from 'hooks/useWindowSize'

const ReasonMessage = {
  SIGNUP: 'Referral - Sign Up',
  SIGNUP_CANCELED: 'Referral - Sign Up - Canceled',
  REFEREE_INITIAL_PURCHASE: 'Referral - First Purchase',
  REFEREE_INITIAL_PURCHASE_CANCELED: 'Referral - First Purchase - Canceled',
  SIGNIFICANT_INVESTOR_PURCHASE: 'Significant Investor Purchase',
  SIGNIFICANT_INVESTOR_PURCHASE_CANCELED: 'Significant Investor Purchase - Canceled',
  PURCHASE: 'Purchase',
  PURCHASE_CANCELED: 'Purchase - Canceled',
  SUPPORT: 'Support'
}

const RewardHistoryItem = ({ transaction }) => {
  const { width } = useWindowSize()

  let rowComponent = null

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div className='nonclickable-grid-container__row rewards_history'>
        <span>{formatDate(transaction.createdAt)}</span>
        <span>{ReasonMessage[transaction.reason] || ReasonMessage.SUPPORT}</span>
        <span className='align-right'>
          {transaction.amount < 0 && '-'}{formatDecimal(Math.abs(transaction.amount))}
        </span>
        <span>{transaction.note}</span>
      </div>
    )
  } else {
    rowComponent = (
      <div className='nonclickable-grid-container__row rewards_history'>
        <div className='history-grid-container__row__full-flex' >
          <span>{formatDate(transaction.createdAt)}</span>
          <span> {transaction.amount < 0 && '-'}{formatDecimal(Math.abs(transaction.amount))}</span>
        </div>
        <div className='history-grid-container__row__full-flex'>
          <span>{ReasonMessage[transaction.reason] || ReasonMessage.SUPPORT}</span>
        </div>
        <div className='history-grid-container__row__full-flex'>
          <span>{transaction.note}</span>
        </div>
      </div>
    )
  }

  return rowComponent
}

export default RewardHistoryItem


const countries = {
  USA: 'United States',
  CAN: 'Canada',
  AUS: 'Australia',
  UK: 'United Kingdom and Ireland',
  EU: 'European Union',
  INTL: 'International'
}

const bankCodes = {
  'United States': 'aba',
  'Canada': 'ca_cpa',
  'Australia': 'au_bsb',
  'United Kingdom and Ireland': 'gb_sort_code',
  'European Union': 'swift',
  'International': 'swift'
}
export {
  countries,
  bankCodes
}

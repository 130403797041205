const parseOrderConfirmed = (data) => {
  return {
    order: {
      orderId: data?.order?.orderId,
      sellOrderId: data?.order?.sellOrderId,
      amountToCashAccount: data?.order?.amountToCashAccount,
      amount: data?.order?.amount,
      shares: data?.order?.shares,
      company: {
        iconUrl: data?.order?.company?.iconUrl,
        name: data?.order?.company?.name,
        companyId: data?.order?.company?.companyId,
        urlName: data?.order?.company?.urlName
      }
    }
  }
}

export default parseOrderConfirmed

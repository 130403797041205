import * as yup from 'yup'
import { isWhiteSpace } from 'utils'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

export const schema = yup.object({
  opt: yup.string().when('$formContext', (formContext, schema) => {
    if (
      formContext.type === 'totp' ||
      (formContext.type === 'sms' && formContext.steps === 2) ||
      formContext.type === 'email'
    ) {
      return schema
        .required('The one-time passcode should be 6 digits.')
        .test(
          'WhiteSpace',
          'The one-time passcode should be 6 digits.',
          (val) => !isWhiteSpace(val)
        )
        .test(
          'len',
          'The one-time passcode should be 6 digits.',
          (val) => val?.length === 6
        )
    }
  }),
  phoneNumber: yup.string().when('$formContext', (formContext, schema) => {
    if (formContext.type === 'sms' && formContext.steps === 1) {
      return schema
        .nullable(true)
        .required('Please enter a phone number.')
        .test('null', 'Please enter a phone number.', (val) => val !== null)
        .test(
          'validPhone',
          'Please enter a valid phone number.',
          (val) => {
            if (val) {
              if (!parsePhoneNumber(val)) {
                return true
              } else {
                return isValidPhoneNumber(val)
              }
            }
          }
        )
    }
  })
})

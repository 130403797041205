import { useState, useEffect, useCallback } from 'react'
import BlinkIdComponent from './BlinkIdComponent'
import AnalyticsService from '../services/analytics'
import Loading from '../../pending'
import { Mixpanel, Braze, getDevice } from 'utils'
import { logEvent, saveIdScanData } from 'slices/kycSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
const MicroBlink = ({ type, handleFinishScan }) => {
  const [error, setError] = useState(null)
  const [blinkIdRecognizers, setBlinkIdRecognizers] = useState(['BlinkIdRecognizer'])
  const [resultsData, setResultsData] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const { microblinkLicenseKey, loading } = useSelector((state) => state.kyc)
  // Services
  const analytics = new AnalyticsService()
  // Product selection according to location effect
  useEffect(() => {
    if (type === 'Passport') {
      setBlinkIdRecognizers(['BlinkIdRecognizer'])
    } else {
      setBlinkIdRecognizers(['BlinkIdCombinedRecognizer'])
    }
  }, [type])

  const cleanUp = useCallback(data => {
    const faceImageRaw = data.faceImage.rawImage
    const faceImage64 = getDataUrl(faceImageRaw)
    data.faceImage.encodedImage = faceImage64
    data.faceImage.rawImage = null
    const barCodeRawBytesInUnit8 = data.barcode.barcodeData.rawBytes
    const barCodeRawBytesInBase64 = Buffer.from(barCodeRawBytesInUnit8).toString('base64')
    data.barcode.barcodeData.rawBytes = barCodeRawBytesInBase64
    const signatureImageRaw = data.signatureImage.rawImage
    const signatureImage64 = getDataUrl(signatureImageRaw)
    data.signatureImage.encodedImage = signatureImage64
    data.signatureImage.rawImage = null
    if (data.fullDocumentFrontImage) {
      const fullDocumentFrontImageRaw = data.fullDocumentFrontImage.rawImage
      const fullDocumentFrontImage64 = getDataUrl(fullDocumentFrontImageRaw)
      data.fullDocumentFrontImage.encodedImage = fullDocumentFrontImage64
      data.fullDocumentFrontImage.rawImage = null
    }
    if (data.fullDocumentBackImage) {
      const fullDocumentBackImageRaw = data.fullDocumentBackImage.rawImage
      const fullDocumentBackImage64 = getDataUrl(fullDocumentBackImageRaw)
      data.fullDocumentBackImage.encodedImage = fullDocumentBackImage64
      data.fullDocumentBackImage.rawImage = null
    }
    if (data.digitalSignature) {
      const digitalSignatureRaw = data.digitalSignature.signature
      const digitalSignature64 = Buffer.from(digitalSignatureRaw).toString('base64')
      data.digitalSignature.signature = digitalSignature64
    }
    if (data.fullDocumentImage) {
      const fullDocumentImageRaw = data.fullDocumentImage.rawImage
      const fullDocumentImage64 = getDataUrl(fullDocumentImageRaw)
      data.fullDocumentImage.encodedImage = fullDocumentImage64
      data.fullDocumentImage.rawImage = null
    }
    return data
  }, [])

  useEffect(() => {
    if (resultsData) {
      const newData = cleanUp(resultsData)
      dispatch(saveIdScanData(newData))
        .then(({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            handleFinishScan()
          } else if (meta.requestStatus === 'rejected') {
            if (payload === 'UNDER_AGE_INVESTOR') {
              history.push('/identity/fail-submission')
              Mixpanel.track('Onboarding Error', { 'Error Type': 'User is underage.' })
              Braze.track('Onboarding Error', { 'Error Type': 'User is underage.', Device: getDevice() })
            } else {
              setError('The data is damage or invalid. Please try again.')
              Mixpanel.track('Onboarding Error', { 'Error Type': 'Scan error, the data is damage or invalid' })
              Braze.track('Onboarding Error', { 'Error Type': 'Scan error, the data is damage or invalid', Device: getDevice() })
            }
            dispatch(logEvent({ path: '/identityVerification/step3/microblinkError', body: { error: payload } }))
          }
        })
    }
  }, [resultsData])

  const getDataUrl = (img) => {
    if (img === null) {
      return ''
    }
    const faceImageEl = document.createElement('canvas')
    faceImageEl.width = img.width
    faceImageEl.height = img.height

    const ctx = faceImageEl.getContext('2d')
    let base64 = ''
    if (img) {
      ctx.putImageData(img, 0, 0)
      base64 = faceImageEl.toDataURL().replace('data:image/png;base64,', '')
    }
    return base64
  }

  const handleMicroBlinkError = (error) => {
    setError('<div>Something went wrong and we couldn\'t complete this action. Our team can help you quickly get verified another way.</div>')
    dispatch(logEvent({ path: '/identityVerification/step3/microblinkError', body: error }))
    Mixpanel.track('Onboarding Error', { 'Error Type': error })
    Braze.track('Onboarding Error', { 'Error Type': error, Device: getDevice() })
  }

  if (loading) {
    return <div style={{ textAlign: 'center' }}><Loading /></div>
  }

  if (error) {
    history.push('/identity/fail-submission')
  }

  return (
    <div id='showcase-container' data-testid='microblink'>
      <div id='showcase-start' >
        <BlinkIdComponent
          onResultReady={(results) => setResultsData(results.recognizer)}
          analytics={analytics}
          recognizers={blinkIdRecognizers}
          onErrorModal={(error) => handleMicroBlinkError(error)}
          microblinkLicenseKey={microblinkLicenseKey}
        />
      </div>
    </div>
  )
}

export default MicroBlink

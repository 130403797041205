import {
  formatDecimal,
  toTitleCase,
  checkIfNotNumber,
  Mixpanel
} from 'utils'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import Select from 'components/Global/Inputs/Select'
import NumberInput from 'components/Global/Inputs/NumberInput'
import LinqtoSlider from 'components/Global/LinqtoSlider'

const shareClassMixpanelTypes = {
  COMMON_SHARES: 'Common Shares',
  PREFERRED_SHARES: 'Preferred Shares',
  OPTIONS_FOR_COMMON_SHARES: 'Options for Common Shares',
  OPTIONS_FOR_PREFERRED_SHARES: 'Options for Preferred Shares'
}

const OriginationDetailCompanyOffer = ({
  amount,
  setAmount,
  shares,
  setShares,
  setShareClass,
  optionsStrikePrice,
  setOptionsStrikePrice,
  checkIfOption,
  onSubmit
}) => {
  const {
    company
  } = useSelector((state) => state.origination)

  const [sliderIndex, setSliderIndex] = useState(company.minShares)
  const increment = company.sharesIncrement
  const minPurchase = company.minShares * company.sharePrice
  const minShares = company.minShares
  const maxPurchase = company.maxShares * company.sharePrice
  const maxShares = company.maxShares

  const { handleSubmit, control, setValue } = useFormContext()

  const updateSliderIndex = (index, type) => {
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type })
    }
    if (index < 0 || index > maxShares) {
      return
    }
    let share = index
    let amount = company.sharePrice * share

    if (amount > maxPurchase) {
      amount = maxPurchase
      share = maxShares
    }

    if (amount < minPurchase) {
      amount = minPurchase
      share = minShares
    }
    setAmount(amount)
    setSliderIndex(index)
    setShares(share)
  }

  const handleSetShareClass = (value) => {
    setShareClass(value)
    Mixpanel.track('Change Originations Share Type', { 'Share Type': shareClassMixpanelTypes[value] })
  }

  const plusBtnDisabled = sliderIndex >= maxShares
  const minusBtnDisabled = sliderIndex <= minShares

  return (
    <div className='section-nar origination-detail-offer'>
      {!company.isLoggedIn && (
        <div className='box orginiation-order-box not-logged-in'>
          <div className='b_18_semibold offer'>LINQTO OFFER</div>
          <div className='share'>Price Per Share:</div>
          <div className='price'>{formatDecimal(company.sharePrice)}</div>
        </div>
      )}
      {company.isLoggedIn && (
        <div className='box orginiation-order-box orginiation-order-slider-box'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='b_18_semibold offer'>LINQTO OFFER</div>
            <div className='share'>
              Price Per Share: {formatDecimal(company.sharePrice)}
            </div>
            <div className='box-row slider-row'>
              <LinqtoSlider
                min={minShares}
                max={maxShares}
                step={1}
                defaultValue={minShares}
                onChange={(val) => updateSliderIndex(val)}
                onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider' })}
                value={sliderIndex}
                onMinusBtnClick = {() => updateSliderIndex(sliderIndex - increment, 'Minus')}
                onPlusBtnClick = {() => updateSliderIndex(sliderIndex + increment, 'Plus')}
                disabled={false}
                plusBtnDisabled={plusBtnDisabled}
                minusBtnDisabled={minusBtnDisabled}
              />
            </div>
            <div className='box-row shares-row'>
              <span>{formatDecimal(minShares, false, 0)}</span>
              <span>{formatDecimal(maxShares, false, 0)}</span>
            </div>
            <div className='box-row selling-row'>
              <span>Selling </span>
              <span>{formatDecimal(shares, false, 0)} Shares</span>
            </div>
            <div className='box-row total-row'>
              <span className='total-proceeds'>Total Proceeds: </span>
              <span className='heading_7'>
                {formatDecimal(amount, true, 0)}
              </span>
            </div>
            <div className='box-row select-row'>
              <Controller
                control={control}
                name='shareClasses'
                render={({ field }) => (
                  <Select
                    field={field}
                    name='shareClasses'
                    options={company.shareClasses}
                    ref={null}
                    ariaLabel='share-classes-input'
                    textFormat={toTitleCase}
                    handleChange={handleSetShareClass}
                  />
                )}
              />
            </div>
            {checkIfOption() && (
              <div className='box-row select-row'>
                <NumberInput
                  name='optionsStrikePrice'
                  ariaLabel='strike-price-input'
                  className='strike-price-input'
                  placeholder='0.00'
                  label='Strike price:'
                  onChange={(e) => {
                    setOptionsStrikePrice(e.target.value)
                  }}
                  onBlur={(e) => {
                    if (!checkIfNotNumber(e.target.value)) {
                      setOptionsStrikePrice(Number(e.target.value).toFixed(2))
                      setValue('optionsStrikePrice', Number(e.target.value).toFixed(2))
                    }
                  }}
                  handleKeyPress={(e) => {
                    if (e.key === '-' || e.key === 'Enter') {
                      e.preventDefault()
                    }
                  }}
                  maxLength={100}
                />
                <span className={`input-currency ${optionsStrikePrice?.length === 0 ? 'inactive' : ''}`}>$</span>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  )
}

export default OriginationDetailCompanyOffer
